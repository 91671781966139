import { Suspense } from 'react';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { PageLayout } from '@/components/ui-parts/layout/PageLayout';
import { TALENT_PAGE_TITLE } from '@/constants/pageTitle';
import { UserSearchForm } from './header';
import { TalentTable } from './table';

export const TalentList = () => {
  return (
    <PageLayout title={TALENT_PAGE_TITLE}>
      <FieldContainer>
        <div className="flex w-full flex-col gap-4">
          <UserSearchForm />

          <div>
            {/* <div className="mb-6 flex items-center justify-between">
              <div>1〜10件を表示（全{usersData.length}件）</div>
              <Button size="small">
                <MdOutlineDownload />
                CSVダウンロード
              </Button>
            </div> */}

            <Suspense>
              <TalentTable />
            </Suspense>
          </div>
        </div>
      </FieldContainer>
    </PageLayout>
  );
};
