import { memo, Suspense, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { MdSend } from 'react-icons/md';
import { Button, Chip, Text, TextArea, useSnackbar } from '@4design/for-ui';
import { useAuth } from '@/components/firebase';
import { SelectOrganizationUsers } from '@/components/select/SelectOrganizationUsers';
import { FormSendMessageInput } from '@/features/message/repository/message.model';
import { useMessage } from '@/features/message/repository/useMessage';

type Props = {
  messageId: string;
  organizationUserId: string;
};

export const MessageForm = memo((props: Props) => {
  const { openSnackbar } = useSnackbar();
  const actions = useMessage();
  const methods = useForm<FormSendMessageInput>({
    defaultValues: {
      organizationUserId: props.organizationUserId,
      message: '',
    },
  });

  const onSubmit = async (data: FormSendMessageInput) => {
    await actions.onSend(props.messageId, data).then(() => {
      openSnackbar({ message: 'メッセージを送信しました' });
    });
    methods.reset();
  };

  /*
  const files = useWatch({ name: 'files', control: methods.control });

  const currentFiles = () => {
    if (Array.isArray(files)) {
      return files;
    }
    if (files) {
      return [files];
    }
    return [];
  };

  const handleRemoveFile = (file: File) => {
    const newFiles = currentFiles().filter((f) => f.name !== file.name);
    methods.setValue('files', newFiles);
  };
  */

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-shade-white-default rounded-bl-2 flex w-full flex-col items-start gap-2 overflow-y-auto p-2"
      >
        {/* {!!files && files.length > 0 && (
          <div className="flex w-full flex-row flex-wrap gap-1">
            {currentFiles().map((file) => {
              return (
                <Chip
                  key={file.name}
                  label={file.name}
                  className="w-fit"
                  clickableArea="limited"
                  onClick={() => {
                    handleRemoveFile(file);
                  }}
                />
              );
            })}
          </div>
        )} */}
        <div className="flex w-full flex-row gap-2">
          {/* <FileButton
            multiple
            type="button"
            name="files"
            accepts={[
              'jpg',
              'png',
              'application/pdf',
              'jpeg',
              'doc',
              'docx',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]}
          /> */}
          <TextArea
            {...methods.register('message')}
            wrap="soft"
            size="medium"
            maxRows={5}
            className="h-auto w-full"
            placeholder="メッセージを入力"
          />
        </div>

        <div className="flex w-full flex-row items-center justify-end gap-2">
          <Text weight="bold" size="s">
            送信者
          </Text>

          <Suspense>
            <SelectOrganizationUsers name="organizationUserId" placeholder="送信者" />
          </Suspense>

          <Text>として</Text>
          <Button type="submit" variant="filled" intention="primary" size="medium">
            <MdSend className="h-4 w-4" />
            送信
          </Button>
        </div>
      </form>
    </FormProvider>
  );
});
