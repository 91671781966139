import { Text } from '@4design/for-ui';

export const Required = () => {
  return (
    <div className="border-negative-dark-default rounded-1 flex items-center justify-center border px-2">
      <Text className="text-negative-dark-default" size="xs">
        必須
      </Text>
    </div>
  );
};
