import { useQuery } from 'urql';
import { Table } from '@4design/for-ui';
import { ColumnDef } from '@4design/for-ui';
import { AccountMenu } from '@/features/account/components/list/table/AccountMenu';
import { graphql } from '@/gql';
import { OrganizationUser } from '@/gql/graphql';
import { useAccount } from '@/hooks/useAccount';
import { usePagination } from '@/hooks/usePagination';
import { TableCell } from '@mui/material';

const AccountTable_Query = graphql(/* GraphQL */ `
  query AccountTable_Query {
    organizationUsers {
      nodes {
        id
        createdAt
        updatedAt

        name
        email
      }

      pageInfo {
        offset
        total
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

export const columns = (curentAccountId: string): ColumnDef<OrganizationUser, unknown>[] => [
  {
    header: 'メールアドレス',
    accessorKey: 'email',
    cell: (cell) => {
      const account = cell.row.original;
      // const status = account.status ? account.status : undefined;
      return (
        <TableCell>
          <div className="flex flex-row items-center gap-2">
            <p>{account.email}</p>
            {/* TODO: {account.status ? (
              <Badge
                variant={status && statusMap[status] === '招待期限切れ' ? 'text' : 'outlined'}
                intention={status && statusMap[status] === '招待期限切れ' ? 'negative' : 'shade'}
                label={status ? statusMap[status] : ''}
              />
            ) : (
              ''
            )} */}
          </div>
        </TableCell>
      );
    },
  },
  {
    header: 'アカウント名',
    accessorKey: 'name',
    cell: (cell) => {
      const account = cell.row.original;
      return (
        <TableCell>
          <div>{account.name}</div>
        </TableCell>
      );
    },
  },
  // {
  //   header: '最終ログイン',
  //   accessorKey: 'loginAt',
  //   cell: (cell) => {
  //     const account = cell.row.original;
  //     return (
  //       <TableCell>
  //         <div>{account.loginAt}</div>
  //       </TableCell>
  //     );
  //   },
  // },
  {
    header: '操作',
    accessorKey: 'action',
    cell: (cell) => {
      return (
        <TableCell>
          <div>
            <AccountMenu organizationUser={cell.row.original} currentAccountId={curentAccountId} />
          </div>
        </TableCell>
      );
    },
  },
];

export const AccountTable = () => {
  const { page, limit, actions: paginationActions } = usePagination();
  const { account } = useAccount();
  const [result] = useQuery({
    query: AccountTable_Query,
  });

  if (!result.data) {
    throw new Error('data is undefined');
  }

  const data = result.data.organizationUsers.nodes ?? [];

  return (
    <Table<OrganizationUser>
      columns={columns(account?.me.id || '')}
      data={data as OrganizationUser[]}
      className="w-full"
      getRowId={(row) => row.id.toString()}
      defaultSortColumn={{
        id: 'email',
        desc: true,
      }}
      page={page}
      pageCount={10}
      pageSize={limit}
      defaultPage={1}
      onChangePage={paginationActions.onChange}
    />
  );
};
