import { FC } from 'react';
import { Text } from '@4design/for-ui';

type CollectionCounterProps = {
  current?: {
    from: number;
    to: number;
  };
  total: number;
};

export const CollectionCounter: FC<CollectionCounterProps> = ({ current, total }) => (
  <Text className="flex items-center gap-1 py-1">
    {current && (
      <Text aria-current className="flex gap-1">
        {current?.from}
        <Text className="sr-only">件</Text>
        <Text aria-label="から">-</Text>
        {current?.to}
        <Text className="sr-only">件を表示中</Text>
      </Text>
    )}
    {current && (
      <Text className="text-shade-light-default" aria-hidden>
        /
      </Text>
    )}
    <Text>全{total}件</Text>
  </Text>
);
