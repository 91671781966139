import { useQuery } from 'urql';
import { graphql } from '@/gql';

const Header_Query = graphql(/* GraphQL */ `
  query Header_Query {
    me {
      id
      createdAt
      updatedAt

      name
      email

      organization {
        id
        name
      }
    }
  }
`);

export const useAccount = () => {
  const [{ data, error, fetching }] = useQuery({
    query: Header_Query,
  });

  return { account: data, error, fetching };
};
