import { useMutation } from 'urql';
import { FormAccountInviteInput, FromAccountUpdateInput } from '@/features/account/repository/account.model';
import { graphql } from '@/gql';

const OnInviteOrganizationUser_Mutation = graphql(/* GraphQL */ `
  mutation OnInviteOrganizationUser_Mutation($input: InviteOrganizationUserInput!) {
    inviteOrganizationUser(input: $input) {
      data {
        __typename
        id
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);
const OnDeleteOrganizationUser_Mutation = graphql(/* GraphQL */ `
  mutation OnDeleteOrganizationUser_Mutation($input: DeleteOrganizationUserInput!) {
    deleteOrganizationUser(input: $input) {
      data {
        __typename
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);
const OnUpdateOrganizationUser_Mutation = graphql(/* GraphQL */ `
  mutation OnUpdateOrganizationUser_Mutation($input: UpdateOrganizationUserInput!) {
    updateOrganizationUser(input: $input) {
      data {
        __typename
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);
const OnCreateFirebaseCustomToken_Mutation = graphql(/* GraphQL */ `
  mutation OnCreateFirebaseCustomToken_Mutation($input: CreateFirebaseCustomTokenInput!) {
    createFirebaseCustomToken(input: $input) {
      customToken
      userErrors {
        __typename
        message
      }
    }
  }
`);

export const useOrganizationUser = () => {
  const [, onInviteOrganizationUser_Mutation] = useMutation(OnInviteOrganizationUser_Mutation);
  const onInvite = async (input: FormAccountInviteInput) => {
    const res = await onInviteOrganizationUser_Mutation({
      input,
    });

    if (res.error) {
      throw res.error;
    }

    if (res.data?.inviteOrganizationUser?.userErrors && res.data?.inviteOrganizationUser.userErrors.length > 0) {
      throw new Error(res.data?.inviteOrganizationUser.userErrors[0].message || '');
    }

    return;
  };

  const [, onDeleteOrganizationUser] = useMutation(OnDeleteOrganizationUser_Mutation);
  const onDelete = async (id: string) => {
    const res = await onDeleteOrganizationUser({
      input: {
        id,
      },
    });

    if (res.error) {
      throw res.error;
    }

    return;
  };

  const [, onUpdateOrganizationUser] = useMutation(OnUpdateOrganizationUser_Mutation);
  const onUpdate = async (input: FromAccountUpdateInput) => {
    const res = await onUpdateOrganizationUser({
      input,
    });

    if (res.error) {
      throw res.error;
    }

    return;
  };

  const [, onCreateFirebaseCustomToken] = useMutation(OnCreateFirebaseCustomToken_Mutation);
  const onCreateCustomToken = async (uid: string) => {
    const res = await onCreateFirebaseCustomToken({
      input: {
        uid,
      },
    });

    if (res.error) {
      throw res.error;
    }

    return res.data?.createFirebaseCustomToken?.customToken;
  };

  return { onInvite, onDelete, onUpdate, onCreateCustomToken };
};
