import type { PropsWithChildren } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { cacheExchange, createClient, mapExchange, Provider } from 'urql';
import { config } from '@/config';
import { auth } from '@/lib/firebase';
import { useAuth } from '@/providers/AuthProvider';
import { yogaExchange } from '@graphql-yoga/urql-exchange';
import { authExchange } from '@urql/exchange-auth';

export const UrqlProvider = ({ children }: PropsWithChildren) => {
  const [idToken, setIdToken] = useState<string | null>(null);
  const { user } = useAuth();
  const getIdToken = useCallback(
    async (forceRefresh = false): Promise<string | null> => {
      if (!user) {
        return null;
      }
      const idTokenResult = await user.getIdTokenResult(forceRefresh);
      const expiresIn = new Date(idTokenResult.expirationTime).getTime() - Date.now();
      const verificationTime = 5 * 60 * 1000;

      if (expiresIn < verificationTime) {
        const newIdToken = await user.getIdToken(true);
        setIdToken(newIdToken);
        return newIdToken;
      }

      setIdToken(idTokenResult.token);
      return idTokenResult.token;
    },
    [user],
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await getIdToken();
      }
    });

    return () => unsubscribe();
  }, [getIdToken]);

  const urqlClient = useMemo(() => {
    return createClient({
      url: config.endpoint,
      requestPolicy: 'cache-and-network',
      suspense: true,
      exchanges: [
        mapExchange({
          onError(error, _operation) {
            console.error(error);
          },
        }),
        authExchange(async (utils) => {
          const token = idToken;
          return {
            addAuthToOperation(operation) {
              if (!token) return operation;
              return utils.appendHeaders(operation, {
                Authorization: `Bearer ${token}`,
              });
            },
            willAuthError: () => {
              return false;
            },
            didAuthError: (error) => {
              return error.graphQLErrors.some(({ message }) => /requires authentication/i.test(message));
            },
            refreshAuth: async () => {
              await getIdToken(true);
              return;
            },
          };
        }),
        cacheExchange,
        yogaExchange(),
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken]);

  return <Provider value={urqlClient}>{children}</Provider>;
};
