import { useMutation } from 'urql';
import { FormCreateAgreementInput } from '@/features/agreement/repository/agreement.model';
import { graphql } from '@/gql';

const OnCreateAgreement_Mutation = graphql(/* GraphQL */ `
  mutation OnCreateAgreement_Mutation($input: CreateAgreementInput!) {
    createAgreement(input: $input) {
      data {
        __typename
        id

        scoutMessage {
          __typename
          id
        }
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);

export const useAgreement = () => {
  const [, onCreateAgreement] = useMutation(OnCreateAgreement_Mutation);
  const onCreate = async (projectId: string, talentId: string, scoutId: string, input: FormCreateAgreementInput) => {
    const res = await onCreateAgreement({
      input: {
        ...input,
        projectId,
        talentId,
        scoutId,
      },
    });

    if (res.error) {
      throw res.error;
    }

    return;
  };

  return { onCreate };
};
