import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { requiredMessage } from '@/constants/validation';
import { useResultHandler } from '@/hooks/useResultHandler';
import { zodResolver } from '@hookform/resolvers/zod';
import { useScout } from '../repository/useScout';

const schema: z.ZodSchema = z.object({
  project: z
    .object({
      inputValue: z.string(),
      label: z.string(),
    })
    .nullable()
    .refine((val) => val !== null, {
      message: requiredMessage('案件'),
      path: ['project'],
    }),
});

type ScoutFormValues = {
  talentId: string;
  project: {
    inputValue: string;
    label: string;
  } | null;
};

const defaultValues: ScoutFormValues = {
  talentId: '',
  project: null,
};

export const useScoutForm = (defaultValue = defaultValues) => {
  const methods = useForm<ScoutFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: defaultValue,
  });

  const errors = methods.formState.errors;

  const actions = useScout();
  const { onError, onSuccess } = useResultHandler();

  const onSubmit = async (data: ScoutFormValues) => {
    try {
      await actions.onCreate(defaultValue.talentId, data.project?.inputValue ?? '');
      onSuccess('スカウトを送信しました');
    } catch (e) {
      onError(e, 'スカウトに失敗しました');
    }
  };

  return {
    methods,
    errors,
    onSubmit,
  };
};
