import React from 'react';
import { useAuth } from '@/components/firebase';
import { PageHeader } from './PageHeader';

type Props = {
  title: string;
  hiddenBreadcrumb?: boolean;
  children: React.ReactNode;
};

export const PageLayout = React.memo(({ title, hiddenBreadcrumb, children }: Props) => {
  return (
    <div className="flex w-full flex-col gap-4 overflow-auto p-4">
      <PageHeader title={title} hiddenBreadcrumb={hiddenBreadcrumb} />
      <div className="sp:flex-row flex w-full flex-col items-start gap-4">{children}</div>
    </div>
  );
});
