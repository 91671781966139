import { updateEmail, User } from 'firebase/auth';
import { useQuery } from 'urql';
import { Button, Text, TextField, useSnackbar } from '@4design/for-ui';
import { useAuth } from '@/components/firebase';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { useAccountEditForm } from '@/features/account/hooks/useAccountEditForm';
import { useOrganizationUser } from '@/features/account/repository/useOrganizationUser';
import { graphql } from '@/gql';
import { signInWithFirebaseCustomToken } from '@/lib/firebase';

const Account_Setting_Query = graphql(/* GraphQL */ `
  query Account_Setting_Query {
    me {
      id
      createdAt
      updatedAt

      name
      email
    }
  }
`);

export const AccountEditForm = () => {
  const [{ data }] = useQuery({
    query: Account_Setting_Query,
  });
  const auth = useAuth();

  const { onUpdate, onCreateCustomToken } = useOrganizationUser();
  const { openSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useAccountEditForm({
    name: data?.me.name || '',
    email: data?.me.email || '',
  });

  const onSubmit = handleSubmit(async (value) => {
    const userId = data?.me.id;
    const user = auth.user;
    const uid = user?.uid;
    const email = data?.me.email;
    if (!userId || !user || !uid) {
      return openSnackbar({
        message: '再度ログインしてください',
      });
    }
    try {
      const customToken = await onCreateCustomToken(uid);
      if (customToken) {
        const signInResult = await signInWithFirebaseCustomToken(customToken);
        await updateEmail(signInResult.user as User, value.email);
      } else {
        return openSnackbar({
          message: 'アカウントの更新に失敗しました',
        });
      }
    } catch (err) {
      return openSnackbar({
        message: 'アカウントの更新に失敗しました',
      });
    }

    try {
      await onUpdate({
        id: userId,
        name: value.name,
        email: value.email,
      });
    } catch (err) {
      if (email) {
        try {
          await updateEmail(auth.user as User, email);
        } catch (error) {
          return openSnackbar({
            message: 'メールアドレスの復元に失敗しました。サポートに連絡してください。',
          });
        }
      }
      return openSnackbar({
        message: 'アカウントの更新に失敗しました',
      });
    }
  });

  return (
    <FieldContainer>
      <Text size="xr" weight="bold">
        基本情報
      </Text>
      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <TextField
          size="medium"
          {...register('name')}
          label="名前"
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          size="medium"
          {...register('email')}
          label="メールアドレス"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <div className="flex justify-end">
          <Button size="medium" variant="filled" intention="primary" type="submit" loading={isSubmitting}>
            保存
          </Button>
        </div>
      </form>
    </FieldContainer>
  );
};
