import { Text } from '@4design/for-ui';
import { RelanceCommLogo } from '../logo';

export const Maintenance = () => {
  return (
    <div className="bg-shade-dark-default text-shade-white-default flex h-screen w-full flex-col justify-center">
      <div className="flex justify-center">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center gap-2">
            <RelanceCommLogo size={40} />
            <Text size="l" weight="bold">
              Relance Comm
            </Text>
          </div>
          <Text size="l" weight="bold">
            現在メンテナンス中です
          </Text>
          <Text size="r">メンテナンス情報</Text>
        </div>
      </div>
    </div>
  );
};
