import { Suspense } from 'react';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { PageLayout } from '@/components/ui-parts/layout/PageLayout';
import { ACCOUNT_PAGE_TITLE } from '@/constants/pageTitle';
import { AccountSearchForm } from './form/AccountSearchForm';
import { AccountTable } from './table';

export const AccountList = () => {
  return (
    <PageLayout title={ACCOUNT_PAGE_TITLE}>
      <FieldContainer>
        <AccountSearchForm />

        <Suspense>
          <AccountTable />
        </Suspense>
      </FieldContainer>
    </PageLayout>
  );
};
