import { useMemo } from 'react';
import { match } from 'path-to-regexp';
import { useLocation } from 'react-router-dom';

export const useActivePath = () => {
  const location = useLocation();
  const isActive = useMemo(
    () => (path: string) => {
      return !!match(`${path}(.*)`, {
        decode: decodeURIComponent,
      })(location.pathname);
    },
    [location.pathname],
  );

  return {
    location,
    isActive,
  };
};
