import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Text } from '@4design/for-ui';

type Props = {
  to: string;
  isActive?: boolean;
  IconComponent?: React.ElementType;
  label: string;
};

export const HeaderLink = ({ to, isActive = false, IconComponent, label }: Props) => {
  return (
    <Link
      aria-current={isActive ? 'page' : undefined}
      to={to}
      className={clsx(
        `hover:bg-shade-white-hover aria-[current]:fill-primary-dark-default aria-[current]:text-primary-dark-default fill-shade-medium-default text-shade-medium-default aria-[current]:after:border-primary-dark-default relative flex min-w-fit flex-row items-center gap-2 px-4 py-2 after:absolute after:bottom-0 after:left-0 after:block after:h-2 after:w-full after:border-b-2 after:border-transparent after:content-[""] aria-[current]:font-bold [&_svg]:fill-inherit`,
      )}
    >
      {IconComponent && <IconComponent size={20} aria-hidden role="img" />}
      <Text size="r">{label}</Text>
    </Link>
  );
};
