import { debounce } from 'radash';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useSearchParamsObject } from '@/hooks/useSearchParamsObject';
import { zodResolver } from '@hookform/resolvers/zod';

type ProjectSearchFormValues = {
  text: string;
  publish: boolean;
};

const schema = z.object({
  text: z.string().optional(),
  publish: z.boolean().optional(),
});

const defaultValues: ProjectSearchFormValues = {
  text: '',
  publish: false,
};

export const useProjectSearchForm = () => {
  const { setSearchParamsObject } = useSearchParamsObject();
  const methods = useForm<ProjectSearchFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues,
  });

  const errors = methods.formState.errors;

  const debouncedSetSearchParamsObject = debounce({ delay: 500 }, (values: Partial<ProjectSearchFormValues>) =>
    setSearchParamsObject({
      name: values?.text ?? defaultValues.text,
      publish: values.publish === true ? 'true' : 'false',
    }),
  ); // 500ミリ秒の遅延時間

  const onSubmit = (data: ProjectSearchFormValues) => {
    console.info(data);
    setSearchParamsObject({
      name: data.text,
      publish: data.publish ? 'true' : '',
    });
  };

  methods.watch((values, { type }) => {
    if (type === 'change') {
      debouncedSetSearchParamsObject(values);
    }
  });

  return {
    methods,
    errors,
    onSubmit,
  };
};
