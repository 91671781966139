import { Fragment, memo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { Pagination, Text } from '@4design/for-ui';
import { graphql } from '@/gql';
import { InputMaybe, Scout, ScoutsFilter } from '@/gql/graphql';
import { usePagination } from '@/hooks/usePagination';
import { useSearchMessageForm } from '../../hooks/useSearchMessageForm';
import { CollectionItem } from './CollectionItem';

export const MessageList_Query = graphql(/* GraphQL */ `
  query MessageList_Query($filter: ScoutsFilter!, $offset: Int!, $limit: Int!) {
    scouts(filter: $filter, offset: $offset, limit: $limit) {
      nodes {
        id
        createdAt
        updatedAt

        unreadOrganizationCount

        latestMessage {
          id
          createdAt

          message
          messageType
        }

        organization {
          id
          name
        }

        project {
          id
          name
        }

        talent {
          id
          name
        }
      }

      pageInfo {
        offset
        total
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

export const MessageList = memo(() => {
  const { messageId } = useParams<{
    messageId: string;
  }>();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const { searchParamsObject } = useSearchMessageForm();

  const buildScoutsFilter = (): ScoutsFilter => {
    const filter: InputMaybe<ScoutsFilter> = {};

    if (searchParamsObject?.project) {
      filter.projectId = searchParamsObject.project;
    }
    if (searchParamsObject?.isUnRead) {
      filter.unReadOnly = searchParamsObject.isUnRead === 'true';
    }
    if (searchParamsObject?.isUnReply) {
      filter.unReplyOnly = searchParamsObject.isUnReply === 'true';
    }
    if (searchParamsObject?.sort) {
      filter.sort = searchParamsObject.sort;
    }
    if (searchParamsObject?.search) {
      filter.message = searchParamsObject.search;
    }

    return Object.keys(filter).length > 0 ? filter : {};
  };
  const scoutsFilter = buildScoutsFilter();

  const { limit, offset, actions } = usePagination();

  const [result] = useQuery({
    query: MessageList_Query,
    variables: {
      filter: scoutsFilter,
      limit,
      offset,
    },
  });

  if (!result.data) {
    throw new Error('data is undefined');
  }

  const messageCount = result.data.scouts.pageInfo.total;
  const data = result.data.scouts.nodes;
  const pageCount = Math.ceil(data.length / limit);

  return (
    <Fragment>
      {messageCount > 0 ? (
        <>
          <div className="border-shade-light-default grow overflow-auto border-b">
            {data.map((item) => {
              return (
                <CollectionItem
                  key={item.id}
                  scout={item as Scout}
                  currentScoutId={messageId}
                  currentQueryParams={currentQueryParams}
                />
              );
            })}
          </div>

          <div className="flex justify-center px-4 py-2">
            <Pagination onChangePage={actions.onChange} total={pageCount} defaultPage={1} />
          </div>
        </>
      ) : (
        <div className="bg-shade-white-default flex h-full w-full flex-col items-center justify-center">
          <Text size="r" className="text-shade-dark-default">
            メッセージがありません
          </Text>
        </div>
      )}
    </Fragment>
  );
});
