import { Layout } from '../layout';
import { NotFound as UINotFound } from '../ui-parts/error/NotFound';

export const NotFound = () => {
  return (
    <Layout>
      <UINotFound />
    </Layout>
  );
};
