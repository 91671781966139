import { useFormContext } from 'react-hook-form';
import { Button } from '@4design/for-ui';

// input type="file"のacceptのファイルの種類全ての型を指定する
type FileType =
  | 'jpg'
  | 'jpeg'
  | 'png'
  | 'gif'
  | 'doc'
  | 'docx'
  | 'xls'
  | 'xlsx'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/pdf'
  | 'application/zip'
  | 'application/x-zip-compressed'
  | 'multipart/x-zip'
  | 'application/x-rar-compressed'
  | 'application/octet-stream'
  | 'application/x-compress'
  | 'application/x-compressed'
  | 'application/x-zip'
  | 'application/x-tar'
  | 'application/x-gzip'
  | 'application/gzip'
  | 'application/x-gtar';

type Props = {
  name: string;
  accepts?: FileType[];
  multiple?: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const FileButton = ({ name, accepts, multiple, children }: Props) => {
  const { register, setValue, setError, watch } = useFormContext();

  const onChangeSingleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setValue(name, file);
    }
  };

  const onChangeMultipleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files.length > 10) {
        setError(name, { message: '10個以上のファイルはアップロードできません' });
        e.target.value = '';
        return;
      }
      const fileList = [] as File[];
      for (let i = 0; i < e.target.files.length; i++) {
        fileList.push(e.target.files[i]);
      }
      setValue(name, fileList);
    }
  };

  const file = watch(name);

  return (
    <div>
      <Button component="label" size="medium" variant="outlined">
        <>
          {children}

          {/* <MdAttachFile size={24} /> */}
          <input
            {...register(name)}
            className="hidden"
            type="file"
            accept={accepts?.map((type) => `.${type}`).join(',') || undefined}
            multiple={multiple}
            onChange={multiple ? onChangeMultipleFiles : onChangeSingleFile}
          />
        </>
      </Button>
      {file && file.name}
    </div>
  );
};
