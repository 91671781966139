import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

type AccountSearchFormValues = {
  text: string;
};

const schema = z.object({
  text: z.string().optional(),
});

const defaultValues: AccountSearchFormValues = {
  text: '',
};

export const useAccountSearchForm = () => {
  const methods = useForm<AccountSearchFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues,
  });

  const errors = methods.formState.errors;

  const onSubmit = (data: AccountSearchFormValues) => {
    console.info(data);
  };

  return {
    methods,
    errors,
    onSubmit,
  };
};
