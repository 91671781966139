import { Context, createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { Loading } from '@/components/ui-parts/Loading';
import { authClient } from '@/lib/firebase';

export type AuthContextProps = {
  user: User | null | undefined;
  loading: boolean;
};

export const AuthContext: Context<AuthContextProps> = createContext<AuthContextProps>({ user: null, loading: true });

export const AuthProvider = (props: PropsWithChildren) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [_, setError] = useState<Error | undefined>(undefined);

  const errorFn = (error: Error) => {
    setError(error);
    setLoading(false);
  };

  useEffect(() => {
    const auth = authClient();
    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        setUser(user);
        setLoading(false);
      },
      errorFn,
    );
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return <AuthContext.Provider value={{ user, loading }}>{props.children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => {
  const { user, loading } = useContext(AuthContext);
  return { user, loading };
};
