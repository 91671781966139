import { Controller } from 'react-hook-form';
import { useQuery } from 'urql';
import { Button, Modal, ModalContent, ModalFooter, ModalHeader, Select, Text } from '@4design/for-ui';
import { useModal } from '@/components/hooks/modal/useModal';
import { HyperLink } from '@/components/ui-parts/Link';
import { useAgentScoutForm } from '@/features/talent/hooks/useAgentScoutForm';
import { graphql } from '@/gql';
import { ProjectStatusEnum, Talent } from '@/gql/graphql';

type Props = {
  talent: Talent;
};

export const ModalScout_Query = graphql(/* GraphQL */ `
  query ModalScout_Query($filter: ProjectsFilter, $limit: Int, $offset: Int) {
    projects(filter: $filter, limit: $limit, offset: $offset) {
      nodes {
        id
        createdAt
        updatedAt

        name
        status
      }
    }
  }
`);

export const AgentScoutModal = (props: Props) => {
  const [openModal, openModalActions] = useModal();

  return (
    <>
      <Button size="medium" onClick={openModalActions.open}>
        代理スカウト
      </Button>

      <ModalField openModal={openModal} openModalActions={openModalActions} talent={props.talent} />
    </>
  );
};

type ModalContentProps = {
  openModal: boolean;
  openModalActions: {
    open: () => void;
    close: () => void;
  };
} & Props;

const ModalField = ({ talent, openModal, openModalActions }: ModalContentProps) => {
  const [result] = useQuery({
    query: ModalScout_Query,
    variables: {
      filter: {
        status: ProjectStatusEnum.Open,
      },
      limit: 100,
      offset: 0,
    },
  });

  const projectsOptions =
    result.data?.projects.nodes
      .filter((project) => {
        const scoutedProjectIds = talent.scoutAgents.map((item) => item.project.id);
        return !scoutedProjectIds.includes(project.id);
      })
      .map((project) => ({
        inputValue: project.id,
        label: project.name,
      })) || [];

  const { methods, errors, onSubmit } = useAgentScoutForm({
    talentId: talent.id,
    project: projectsOptions[0],
  });
  return (
    <Modal open={openModal} onClose={openModalActions.close}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalHeader>代理スカウト</ModalHeader>
        <ModalContent>
          <div className="flex w-[528px] flex-col gap-4">
            <Text className="text-shade-dark-default" size="r">
              代理スカウトすると、
              <HyperLink
                label="株式会社スリーシェイクの提供する案件紹介サービス「Relance」"
                underline
                to="/"
                size="r"
              />
              を通じて、候補者様への案件のご提案を弊社で引き受けさせていただきます
              (成約の確約をできるものではありません) 。
              2・3営業日以内に株式会社スリーシェイクの担当より御社ご担当者様までご連絡いたします。
            </Text>
            <Controller
              control={methods.control}
              name="project"
              render={({ field: { onChange, ...fields } }) => {
                return (
                  <Select
                    label={
                      <div className="flex flex-row gap-1">
                        <Text size="s" weight="bold">
                          提案する案件
                        </Text>
                      </div>
                    }
                    size="medium"
                    error={!!errors.project}
                    helperText={errors.project?.message}
                    name={fields.name}
                    options={projectsOptions}
                    onChange={(_, data) => onChange(data)}
                  />
                );
              }}
            />
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="flex flex-row justify-end gap-4">
            <Button type="button" onClick={openModalActions.close}>
              キャンセル
            </Button>
            <Button type="submit" variant="filled" intention="primary" loading={methods.formState.isSubmitting}>
              スカウト
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};
