import { EngagementIntent } from '@/gql/graphql';

export const ENGAGEMENT_INTENT_LABEL = {
  [EngagementIntent.ImmediateSearch]: '直ぐに案件を探している',
  [EngagementIntent.ActivelySearching]: '案件を探している',
  [EngagementIntent.ConsideringIfGood]: 'いい案件があったら検討したい',
  [EngagementIntent.ExploringOptions]: '情報収集や案件を探すきっかけとして面談したい',
  [EngagementIntent.StatusQuoPreferred]: '現状維持が濃厚',
} as const;

export const ENGAGEMENT_INTENT_OPTIONS = Object.entries(ENGAGEMENT_INTENT_LABEL).map(([value, label]) => ({
  value,
  label,
})) as { value: EngagementIntent; label: string }[];
