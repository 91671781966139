import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const schema = z.object({
  name: z.string().min(1, '名前を入力してください'),
  email: z.string().min(1, 'メールアドレスを入力してください').email({
    message: 'メールアドレスが正しくありません',
  }),
});

type AccountEditFormValues = z.infer<typeof schema>;

const defaultValues: AccountEditFormValues = {
  name: '',
  email: '',
};

export const useAccountEditForm = (defaultValue = defaultValues) => {
  return useForm<AccountEditFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: defaultValue,
  });
};
