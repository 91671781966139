import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'urql';
import { Select, SelectOption } from '@4design/for-ui';
import { graphql } from '@/gql';

type Props = {
  name: string;
  placeholder?: string;
};

export const SelectOrganizationUsers_Query = graphql(/* GraphQL */ `
  query SelectOrganizationUsers_Query {
    organizationUsers {
      nodes {
        id
        createdAt
        updatedAt

        name
      }

      pageInfo {
        offset
        total
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

export const SelectOrganizationUsers = (props: Props) => {
  const methods = useFormContext();

  const [result] = useQuery({
    query: SelectOrganizationUsers_Query,
  });

  if (!result.data) {
    throw new Error('data is undefined');
  }

  const options: SelectOption[] = result.data.organizationUsers.nodes.map((organizationUser) => ({
    label: organizationUser.name,
    inputValue: organizationUser.id,
  }));

  return (
    <Controller
      name={props.name}
      control={methods.control}
      render={({ field: { name, value, onChange, ...rest } }) => {
        return (
          <Select
            value={options.find((v) => v.inputValue === value?.toString())?.label ?? '選択してください'}
            name={name}
            className="w-40"
            placeholder={props.placeholder}
            size="medium"
            options={options}
            onChange={(_, option) => {
              onChange((option as SelectOption)?.inputValue);
            }}
            {...rest}
          />
        );
      }}
    />
  );
};
