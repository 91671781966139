import { updatePassword, User } from 'firebase/auth';
import { MdLogout } from 'react-icons/md';
import { Button, Text, TextField, useSnackbar } from '@4design/for-ui';
import { useAuth } from '@/components/firebase';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { useAccountUpdatePasswordForm } from '@/features/account/hooks/useAccountUpdatePasswordForm';
import { useOrganizationUser } from '@/features/account/repository/useOrganizationUser';
import { logout, signInWithFirebaseCustomToken } from '@/lib/firebase';

export const UpdatePasswordForm = () => {
  const auth = useAuth();
  const { openSnackbar } = useSnackbar();

  const { onCreateCustomToken } = useOrganizationUser();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useAccountUpdatePasswordForm();

  const onSubmit = handleSubmit(async (value) => {
    const uid = auth.user?.uid;
    if (!uid) {
      return openSnackbar({
        message: '再度ログインしてください',
      });
    }

    try {
      const customToken = await onCreateCustomToken(uid);
      if (customToken) {
        const signInResult = await signInWithFirebaseCustomToken(customToken);
        // reauthenticateWithCredentialなどで事前に再認証をさせる必要がありそう
        // https://firebase.google.com/docs/auth/web/manage-users?hl=ja#web-modular-api_12
        await updatePassword(signInResult.user as User, value.newPassword);
      }
      openSnackbar({
        message: 'パスワードを変更しました',
      });
    } catch (error) {
      openSnackbar({
        message: 'パスワードの変更に失敗しました',
      });
    }
  });

  return (
    <FieldContainer>
      <Text size="xr" weight="bold">
        パスワード
      </Text>
      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <TextField
          size="medium"
          type="password"
          {...register('password')}
          label="現在のパスワード"
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <TextField
          size="medium"
          type="password"
          {...register('passwordConfirmation')}
          label="新しいパスワード"
          error={!!errors.passwordConfirmation}
          helperText={errors.passwordConfirmation?.message}
        />
        <TextField
          size="medium"
          type="password"
          {...register('newPassword')}
          label="新しいパスワードを再入力"
          error={!!errors.newPassword || !!errors.root?.message}
          helperText={errors.newPassword?.message || errors.root?.message}
        />
        <div className="flex justify-end">
          <Button size="medium" variant="filled" intention="primary" type="submit" loading={isSubmitting}>
            パスワードを変更
          </Button>
        </div>
      </form>
      <Button size="medium" variant="outlined" intention="negative" onClick={logout}>
        <MdLogout width={16} height={16} />
        ログアウト
      </Button>
    </FieldContainer>
  );
};
