import { useMemo } from 'react';
import { debounce } from 'radash';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { SelectOption } from '@4design/for-ui';
import { ScoutSortEnum } from '@/gql/graphql';
import { useSearchParamsObject } from '@/hooks/useSearchParamsObject';
import { zodResolver } from '@hookform/resolvers/zod';

const schema = z.object({
  search: z.string().optional(),
  isUnReply: z.boolean().optional(),
  isUnRead: z.boolean().optional(),
  project: z
    .object({
      inputValue: z.string(),
      label: z.string(),
    })
    .optional()
    .nullable(),
  sort: z
    .object({
      inputValue: z.string(),
      label: z.string(),
    })
    .optional()
    .nullable(),
});

export const ScoutSortKeysLabel: Record<ScoutSortEnum, string> = {
  [ScoutSortEnum.CreatedAtDesc]: '作成順',
  [ScoutSortEnum.ReadAtDesc]: '既読順',
};

export type SearchMessageFormValues = z.infer<typeof schema>;
export type SearchMessageFormValuesParams = Omit<
  SearchMessageFormValues,
  'project' | 'sort' | 'isUnReply' | 'isUnRead'
> & {
  project: string;
  sort: keyof typeof ScoutSortKeysLabel;
  isUnReply: string;
  isUnRead: string;
};

export const ScoutSortKeysOptions: SelectOption[] = Object.entries(ScoutSortKeysLabel).map(([key, label]) => ({
  inputValue: key,
  label,
}));

const defaultValues: SearchMessageFormValues = {
  search: '',
  isUnReply: false,
  isUnRead: false,
  project: null,
  sort: ScoutSortKeysOptions[0],
};

export const useSearchMessageForm = () => {
  const methods = useForm<SearchMessageFormValues>({
    resolver: zodResolver(schema),
    defaultValues,
  });
  const { setSearchParamsObject, createSearchParamsObject } = useSearchParamsObject();

  const searchParamsObject = useMemo(() => {
    return createSearchParamsObject<Partial<SearchMessageFormValuesParams>>();
  }, [createSearchParamsObject]);

  const setScoutSearchParamsObject = (values: SearchMessageFormValues) => {
    setSearchParamsObject({
      ...values,
      sort: values?.sort?.inputValue ?? '',
      project: values?.project?.inputValue ?? '',
      isUnReply: values.isUnReply ? 'true' : 'false',
      isUnRead: values.isUnRead ? 'true' : 'false',
    });
  };

  const debouncedSetSearchParamsObject = debounce({ delay: 500 }, (values: SearchMessageFormValues) =>
    setScoutSearchParamsObject(values),
  ); // 500ミリ秒の遅延時間

  methods.watch((values, { type }) => {
    if (type === 'change') {
      debouncedSetSearchParamsObject(values as SearchMessageFormValues);
    }
  });

  const onSubmit = (values: SearchMessageFormValues) => {
    setScoutSearchParamsObject(values);
  };

  const errors = methods.formState.errors;

  return {
    methods,
    onSubmit,
    searchParamsObject,
    errors,
  };
};
