import { MdChevronLeft } from 'react-icons/md';
import { Button, Text } from '@4design/for-ui';
import { FieldContainer } from '../FieldContainer';
import { PageLayout } from '../layout/PageLayout';
import { PageTitle } from '../layout/PageTitle';
import { HyperLink } from '../Link';

export const NotFound = () => (
  <PageLayout hiddenBreadcrumb title="存在しないページ">
    <FieldContainer>
      <PageTitle title="ページが見つかりません" />
      <Text size="r">
        このURLにページは見つかりませんでした。
        <br />
        削除されたか、移動した可能性があります。
        <br />
        より詳細な情報が必要な場合は、お手数ですが
        <HyperLink label="運営までお問い合わせ" underline to="/" size="r" />
        ください。
      </Text>
      <Button size="medium">
        <MdChevronLeft />
        戻る
      </Button>
    </FieldContainer>
  </PageLayout>
);
