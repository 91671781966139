import { useCallback, useEffect, useRef } from 'react';

export type UseInputFileProps = {
  accept?: string;
  multiple?: boolean;
  onChange?: (files?: FileList) => void;
};

export const useInputFile = ({
  accept,
  multiple,
  onChange,
}: UseInputFileProps): {
  openFileBrowser: () => void;
} => {
  const input = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    input.current = document.createElement('input');
    input.current.setAttribute('type', 'file');
    input.current.setAttribute('accept', accept || '');
    multiple && input.current.setAttribute('multiple', '');
    input.current.addEventListener('change', () => {
      onChange?.(input.current?.files || undefined);
    });
    return () => {
      input.current?.removeEventListener('change', () => {
        onChange?.(input.current?.files || undefined);
      });
      input.current?.remove();
    };
  }, [accept, multiple, onChange]);
  return {
    openFileBrowser: useCallback(() => {
      if (input.current) {
        input.current.value = '';
        input.current.click();
      }
    }, []),
  };
};
