import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const schema = z
  .object({
    password: z.string().min(1, 'パスワードは必須です'),
    passwordConfirmation: z.string().min(1, 'パスワードは必須です'),
    newPassword: z.string().min(1, 'パスワードは必須です'),
  })
  .refine((data) => data.newPassword === data.passwordConfirmation, {
    message: '新しいパスワードと一致しません',
    path: ['newPassword'],
  });

type AccountUpdatePasswordFormValues = z.infer<typeof schema>;

const defaultValues: AccountUpdatePasswordFormValues = {
  password: '',
  passwordConfirmation: '',
  newPassword: '',
};

export const useAccountUpdatePasswordForm = (defaultValue = defaultValues) => {
  return useForm<AccountUpdatePasswordFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: defaultValue,
  });
};
