import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { MdOutlineClose, MdOutlineFilterList, MdOutlineSearch } from 'react-icons/md';
import { Button, Checkbox, Select, Text, TextField } from '@4design/for-ui';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import {
  configurationManagementTools,
  experienceJobType,
  frameworks,
  infrastructures,
  languages,
  middleware,
  monitoringTools,
} from '@/features/project/repository/data';
import { useUserSearchForm } from '@/features/talent/hooks/useUserSearchForm';

export const UserSearchForm = () => {
  const { methods, onSubmit, errors, isLoading } = useUserSearchForm();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="flex flex-col gap-2">
      <Button onClick={handleToggle} size="medium">
        <MdOutlineFilterList />
        フィルター
      </Button>

      {isOpen && (
        <FieldContainer>
          <form className="flex flex-col gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-row gap-4">
              <Controller
                name="experienceJobType"
                control={methods.control}
                render={({ field: { name, onChange } }) => {
                  return (
                    <Select
                      label="経験職種"
                      name={name}
                      error={!!errors.experienceJobType?.message}
                      helperText={errors.experienceJobType?.message}
                      size="medium"
                      placeholder="選択"
                      options={experienceJobType}
                      multiple
                      onChange={(_, option) => {
                        onChange(option);
                      }}
                    />
                  );
                }}
              />
              <Controller
                name="languageFramework"
                control={methods.control}
                render={({ field: { name, onChange } }) => {
                  return (
                    <Select
                      label="言語/フレームワーク"
                      name={name}
                      error={!!errors.languageFramework?.message}
                      helperText={errors.languageFramework?.message}
                      size="medium"
                      placeholder="選択"
                      options={[...languages, ...frameworks]}
                      multiple
                      onChange={(_, option) => {
                        onChange(option);
                      }}
                    />
                  );
                }}
              />
              <Controller
                name="middleware"
                control={methods.control}
                render={({ field: { name, onChange } }) => {
                  return (
                    <Select
                      label="ミドルウェア"
                      name={name}
                      error={!!errors.middleware?.message}
                      helperText={errors.middleware?.message}
                      size="medium"
                      placeholder="選択"
                      options={middleware}
                      multiple
                      onChange={(_, option) => {
                        onChange(option);
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="flex flex-row gap-4">
              <Controller
                name="infra"
                control={methods.control}
                render={({ field: { name, onChange } }) => {
                  return (
                    <Select
                      label="インフラ"
                      name={name}
                      error={!!errors.infra?.message}
                      helperText={errors.infra?.message}
                      size="medium"
                      placeholder="選択"
                      options={infrastructures}
                      multiple
                      onChange={(_, option) => {
                        onChange(option);
                      }}
                    />
                  );
                }}
              />
              <Controller
                name="monitoringTool"
                control={methods.control}
                render={({ field: { name, onChange } }) => {
                  return (
                    <Select
                      label="監視ツール"
                      name={name}
                      error={!!errors.monitoringTool?.message}
                      helperText={errors.monitoringTool?.message}
                      size="medium"
                      placeholder="選択"
                      options={monitoringTools}
                      multiple
                      onChange={(_, option) => {
                        onChange(option);
                      }}
                    />
                  );
                }}
              />
              <Controller
                name="configurationManagementTool"
                control={methods.control}
                render={({ field: { name, onChange } }) => {
                  return (
                    <Select
                      label="構成管理ツール"
                      name={name}
                      error={!!errors.configurationManagementTool?.message}
                      helperText={errors.configurationManagementTool?.message}
                      size="medium"
                      placeholder="選択"
                      options={configurationManagementTools}
                      multiple
                      onChange={(_, option) => {
                        onChange(option);
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="flex flex-row items-end gap-4">
              <TextField
                size="medium"
                {...methods.register('hourlyWage.hourlyWageLowerLimit', {
                  valueAsNumber: true,
                })}
                label="時給(下限)"
                suffix="円"
                placeholder="3,000"
                error={!!errors.hourlyWage?.hourlyWageLowerLimit?.message || !!errors.hourlyWage?.message}
                helperText={errors.hourlyWage?.hourlyWageLowerLimit?.message || errors.hourlyWage?.message}
              />
              <Text>〜</Text>
              <TextField
                size="medium"
                {...methods.register('hourlyWage.hourlyWageUpperLimit', {
                  valueAsNumber: true,
                })}
                label="時給(上限)"
                suffix="円"
                placeholder="10,000"
                error={!!errors.hourlyWage?.hourlyWageUpperLimit?.message || !!errors.hourlyWage?.message}
                helperText={errors.hourlyWage?.hourlyWageUpperLimit?.message || errors.hourlyWage?.message}
              />
            </div>
            <Checkbox {...methods.register('isConsidering')} label="検討中のユーザーのみ" />

            <div className="flex flex-row justify-between">
              <Button size="medium" type="button" onClick={handleClose}>
                <MdOutlineClose />
                閉じる
              </Button>
              <Button size="medium" type="submit" intention="primary" variant="filled" loading={isLoading}>
                <MdOutlineSearch />
                検索
              </Button>
            </div>
          </form>
        </FieldContainer>
      )}
    </div>
  );
};
