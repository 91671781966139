import { useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Button, Switch, TextField } from '@4design/for-ui';
import { NEW_PROJECT_PATH } from '@/constants/routes';
import { useProjectSearchForm } from '@/features/project/hooks/useProjectSearchForm';

export const ProjectSearchForm = () => {
  const { methods, onSubmit } = useProjectSearchForm();
  const navigate = useNavigate();

  return (
    <form className="flex w-full items-center justify-between" onSubmit={methods.handleSubmit(onSubmit)}>
      <div className="flex w-full flex-row items-center gap-4">
        <TextField {...methods.register('text')} placeholder="検索" size="medium" type="search" className="w-60" />
        <Switch {...methods.register('publish')} label="募集中のみ表示" />
      </div>

      <Button
        size="medium"
        type="button"
        intention="primary"
        variant="filled"
        onClick={() => {
          navigate(NEW_PROJECT_PATH);
        }}
      >
        <MdAdd />
        案件を登録
      </Button>
    </form>
  );
};
