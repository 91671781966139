import { useCallback } from 'react';
import { URLSearchParamsInit, useLocation, useSearchParams } from 'react-router-dom';

export const useSearchParamsObject = () => {
  const location = useLocation();
  const [_, setParams] = useSearchParams();

  const createSearchParamsObject = <T>() => {
    return Object.fromEntries(new URLSearchParams(location.search)) as T;
  };

  const setSearchParamsObject = useCallback(
    <T extends URLSearchParamsInit>(params: T) => {
      setParams(params);
    },
    [setParams],
  );

  return {
    setSearchParamsObject,
    createSearchParamsObject,
  };
};
