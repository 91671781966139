import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { SelectOption } from '@4design/for-ui';
import { notMatchMessage, rangeMessage, requiredMessage } from '@/constants/validation';
import { zodResolver } from '@hookform/resolvers/zod';

export type ProjectFormValues = {
  baseInfo: {
    // 案件名
    name: string;
    // 公開用案件名
    nameForPublic: string;
  };
  // 単価
  unitPrice: {
    // 税
    tax: SelectOption;
    // 単価(月)
    monthly: {
      from: string;
      to: string;
    };
    // 単価(時)
    hourly: {
      from: string;
      to: string;
    };
    // 対象年齢
    age: {
      from: string;
      to: string;
    };
  };
  // 稼働日数・期間
  workingDays: {
    // 開始時期
    start: string;
    // 想定稼働月数
    months: string;
    // 希望日数
    days: {
      from: string;
      to: string;
    };
  };
  // 契約
  contract: {
    // 精算幅（月）
    settlementWidth: {
      from: string;
      to: string;
    };
    // 支払日
    paymentDate: string;
  };
  // 業務内容
  businessContent: {
    // 職務内容
    jobDescription: string;
    // 必須スキル
    requiredSkills: string;
    // 歓迎スキル
    welcomeSkills: string;
    // チーム編成
    teamComposition: string;
  };
  // 開発環境
  developmentEnvironment: {
    // 言語
    languages: SelectOption[];
    // フレームワーク
    frameworks: SelectOption[];
    // ミドルウェア
    middleware: SelectOption[];
    // インフラ
    infrastructures: SelectOption[];
    // 開発手法
    developmentMethod: SelectOption[];
    // 構成管理ツール
    configurationManagementTools: SelectOption[];
    // 監視ツール
    monitoringTools: SelectOption[];
  };
};

const NAME = {
  label: '案件名',
  name: 'name',
} as const;

const NAME_FOR_PUBLIC = {
  label: '公開用案件名',
  name: 'nameForPublic',
} as const;

const TAX = {
  label: '税',
  name: 'tax',
} as const;

const MONTHLY = {
  label: '単価(月)',
  name: 'monthly',
} as const;

const FROM = {
  label: 'from',
  name: 'from',
} as const;

const TO = {
  label: 'to',
  name: 'to',
} as const;

const HOURLY = {
  label: '単価(時)',
  name: 'hourly',
} as const;

const AGE = {
  label: '対象年齢',
  name: 'age',
} as const;

const START = {
  label: '開始時期',
  name: 'start',
} as const;

const MONTHS = {
  label: '想定稼働月数',
  name: 'months',
} as const;

const DAYS = {
  label: '希望日数',
  name: 'days',
} as const;

const SETTLEMENT_WIDTH = {
  label: '精算幅（月）',
  name: 'settlementWidth',
} as const;

const PAYMENT_DATE = {
  label: '支払日',
  name: 'paymentDate',
} as const;

const JOB_DESCRIPTION = {
  label: '職務内容',
  name: 'jobDescription',
} as const;

const REQUIRED_SKILLS = {
  label: '必須スキル',
  name: 'requiredSkills',
} as const;

const WELCOME_SKILLS = {
  label: '歓迎スキル',
  name: 'welcomeSkills',
} as const;

const TEAM_COMPOSITION = {
  label: 'チーム編成',
  name: 'teamComposition',
} as const;

const LANGUAGES = {
  label: '言語',
  name: 'languages',
} as const;

const FRAMEWORKS = {
  label: 'フレームワーク',
  name: 'frameworks',
} as const;

const MIDDLEWARE = {
  label: 'ミドルウェア',
  name: 'middleware',
} as const;

const INFRASTRUCTURES = {
  label: 'インフラ',
  name: 'infrastructures',
} as const;

const DEVELOPMENT_METHOD = {
  label: '開発手法',
  name: 'developmentMethod',
} as const;

const CONFIGURATION_MANAGEMENT_TOOLS = {
  label: '構成管理ツール',
  name: 'configurationManagementTools',
} as const;

const MONITORING_TOOLS = {
  label: '監視ツール',
  name: 'monitoringTools',
} as const;

export const PROJECT_FORM_VALUES_MAP = {
  BASE_INFO: {
    NAME,
    NAME_FOR_PUBLIC,
  },
  UNIT_PRICE: {
    TAX,
    MONTHLY: {
      ...MONTHLY,
      FROM,
      TO,
    },
    HOURLY: {
      ...HOURLY,
      FROM,
      TO,
    },
    AGE: {
      ...AGE,
      FROM,
      TO,
    },
  },
  WORKING_DAYS: {
    START,
    MONTHS,
    DAYS: {
      ...DAYS,
      FROM,
      TO,
    },
  },
  CONTRACT: {
    SETTLEMENT_WIDTH: {
      ...SETTLEMENT_WIDTH,
      FROM,
      TO,
    },
    PAYMENT_DATE,
  },
  BUSINESS_CONTENT: {
    JOB_DESCRIPTION,
    REQUIRED_SKILLS,
    WELCOME_SKILLS,
    TEAM_COMPOSITION,
  },
  DEVELOP_ENVIRONMENT: {
    LANGUAGES,
    FRAMEWORKS,
    MIDDLEWARE,
    INFRASTRUCTURES,
    DEVELOPMENT_METHOD,
    CONFIGURATION_MANAGEMENT_TOOLS,
    MONITORING_TOOLS,
  },
} as const;

const optionSchema = z.object({
  inputValue: z.string(),
  label: z.string(),
});

const rangeSchema = z.object({
  // 正の半角数字のみ
  from: z.string().regex(/^\d+$/, {
    message: notMatchMessage(FROM.label),
  }),
  to: z.string().regex(/^\d+$/, {
    message: notMatchMessage(TO.label),
  }),
});

const schema = z.object({
  baseInfo: z.object({
    [NAME.name]: z.string().nonempty({
      message: requiredMessage(NAME.label),
    }),
    [NAME_FOR_PUBLIC.name]: z.string(),
  }),
  unitPrice: z.object({
    [TAX.name]: optionSchema,
    [MONTHLY.name]: rangeSchema.refine((v) => Number(v.from) <= Number(v.to), {
      message: rangeMessage(MONTHLY.label),
    }),
    [HOURLY.name]: rangeSchema.refine((v) => Number(v.from) <= Number(v.to), {
      message: rangeMessage(HOURLY.label),
    }),
    [AGE.name]: rangeSchema.refine((v) => Number(v.from) <= Number(v.to), {
      message: rangeMessage(AGE.label),
    }),
  }),
  workingDays: z.object({
    [START.name]: z.string(),
    [MONTHS.name]: z.string().regex(/^\d+$/, {
      message: notMatchMessage(MONTHS.label),
    }),
    [DAYS.name]: rangeSchema.refine((v) => Number(v.from) <= Number(v.to), {
      message: rangeMessage(DAYS.label),
    }),
  }),
  contract: z.object({
    [SETTLEMENT_WIDTH.name]: rangeSchema.refine((v) => Number(v.from) <= Number(v.to), {
      message: rangeMessage(SETTLEMENT_WIDTH.label),
    }),
    [PAYMENT_DATE.name]: z.string(),
  }),
  businessContent: z.object({
    [JOB_DESCRIPTION.name]: z.string(),
    [REQUIRED_SKILLS.name]: z.string(),
    [WELCOME_SKILLS.name]: z.string(),
    [TEAM_COMPOSITION.name]: z.string(),
  }),
  developmentEnvironment: z.object({
    [LANGUAGES.name]: z.array(optionSchema),
    [FRAMEWORKS.name]: z.array(optionSchema),
    [MIDDLEWARE.name]: z.array(optionSchema),
    [INFRASTRUCTURES.name]: z.array(optionSchema),
    [DEVELOPMENT_METHOD.name]: z.array(optionSchema),
    [CONFIGURATION_MANAGEMENT_TOOLS.name]: z.array(optionSchema),
    [MONITORING_TOOLS.name]: z.array(optionSchema),
  }),
});

export const options = [
  {
    label: '選択肢1',
    inputValue: '選択肢1',
  },
  {
    label: '選択肢2',
    inputValue: '選択肢2',
  },
  {
    label: '選択肢3',
    inputValue: '選択肢3',
  },
];

const defaultOptionValue = {
  inputValue: '',
  label: '',
};

const defaultRangeValue = {
  [FROM.name]: '0',
  [TO.name]: '0',
};

const defaultProjectValues: ProjectFormValues = {
  baseInfo: {
    [NAME.name]: '',
    [NAME_FOR_PUBLIC.name]: '',
  },
  unitPrice: {
    [TAX.name]: defaultOptionValue,
    [MONTHLY.name]: defaultRangeValue,
    [HOURLY.name]: defaultRangeValue,
    [AGE.name]: defaultRangeValue,
  },
  workingDays: {
    [START.name]: '',
    [MONTHS.name]: '0',
    [DAYS.name]: defaultRangeValue,
  },
  contract: {
    [SETTLEMENT_WIDTH.name]: defaultRangeValue,
    [PAYMENT_DATE.name]: '',
  },
  businessContent: {
    [JOB_DESCRIPTION.name]: '',
    [REQUIRED_SKILLS.name]: '',
    [WELCOME_SKILLS.name]: '',
    [TEAM_COMPOSITION.name]: '',
  },
  developmentEnvironment: {
    [LANGUAGES.name]: [],
    [FRAMEWORKS.name]: [],
    [MIDDLEWARE.name]: [],
    [INFRASTRUCTURES.name]: [],
    [DEVELOPMENT_METHOD.name]: [],
    [CONFIGURATION_MANAGEMENT_TOOLS.name]: [],
    [MONITORING_TOOLS.name]: [],
  },
};

export const useProjectForm = (defaultValues = defaultProjectValues) => {
  const methods = useForm<ProjectFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(schema),
    defaultValues,
  });

  const onSubmit = (data: ProjectFormValues) => {
    // eslint-disable-next-line no-console
    console.log(data);
  };

  const errors = methods.formState.errors;

  return {
    methods,
    onSubmit,
    errors,
  };
};
