import { HeaderBreadcrumb } from './breadcrumb/HeaderBreadcrumb';
import { PageTitle } from './PageTitle';

type Props = {
  title: string;
  hiddenBreadcrumb?: boolean;
};

export const PageHeader = ({ title, hiddenBreadcrumb }: Props) => {
  return (
    <div className="flex flex-col gap-0.5">
      {!hiddenBreadcrumb && <HeaderBreadcrumb />}
      <PageTitle title={title} />
    </div>
  );
};
