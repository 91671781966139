import { Suspense, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Select, SelectOption, Text, TextArea, TextField, useSnackbar } from '@4design/for-ui';
import { SelectEngineerPositions } from '@/components/select/SelectEngineerPositions';
import { SelectEngineerSkillFrameworks } from '@/components/select/SelectEngineerSkillFrameworks';
import { SelectEngineerSkillInfras } from '@/components/select/SelectEngineerSkillInfras';
import { SelectEngineerSkillLanguages } from '@/components/select/SelectEngineerSkillLanguages';
import { SelectEngineerSkillMiddlewares } from '@/components/select/SelectEngineerSkillMiddlewares';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { Required } from '@/components/ui-parts/required';
import { PROJECT_PATH } from '@/constants/routes';
import { PROJECT_FORM_VALUES_MAP } from '@/features/project/hooks/useProjectForm';
import { FormCreateProjectInput, schema } from '@/features/project/repository/project.model';
import { useProject } from '@/features/project/repository/useProject';
import { taxTypeOptions } from '@/features/talent/repository/scout.model';
import { Maybe, Project } from '@/gql/graphql';
import { useInputFile } from '@/hooks/useInputFile';
import { formatDate } from '@/lib/dateformat';
import { zodResolver } from '@hookform/resolvers/zod';
import { HeroImage } from '../hero-image';

type Props = {
  project?: Project;
};

export const ProjectForm = ({ project }: Props) => {
  const isEdit = !!project;
  const actions = useProject();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const [heroImageFile, setHeroImageFile] = useState<File | string | null | undefined>(project?.heroImageSrc);
  const [attachmentFileError, setAttachmentFileError] = useState<string | null>(null);
  const { openFileBrowser } = useInputFile({
    accept: '.png, .jpg, .jpeg',
    multiple: false,
    onChange: (files) => {
      if (files && files.length > 0) {
        setAttachmentFileError(null);
        if (!files[0].type.includes('image')) {
          setAttachmentFileError('画像ファイルを選択してください');
          return;
        }
        if (files[0].size > 2 * 1024 * 1024) {
          setAttachmentFileError('2MB以下の画像ファイルを選択してください');
          return;
        }
        setHeroImageFile(files[0]);
      }
    },
  });

  const parseNumberToString = (value: Maybe<number> | undefined) => {
    if (value == null) return null;
    return value.toString();
  };
  const processSkillsByCategory = (categoryId: string) => {
    return (
      project?.projectMasterEngineerSkills
        .filter((skill) => skill.category.id === categoryId)
        .map((skill) => ({
          label: skill.name,
          inputValue: skill.id,
        })) || []
    );
  };

  const methods = useForm<FormCreateProjectInput>({
    mode: 'onBlur',
    resolver: zodResolver(schema),
    defaultValues: isEdit
      ? {
          name: project.name,
          priceTaxType: project.priceTaxType,
          priceMonthly: {
            min: parseNumberToString(project.priceMonthlyMin),
            max: parseNumberToString(project.priceMonthlyMax),
          },
          priceHourly: {
            min: parseNumberToString(project.priceHourlyMin),
            max: parseNumberToString(project.priceHourlyMax),
          },
          targetAge: {
            min: parseNumberToString(project.targetAgeMin),
            max: parseNumberToString(project.targetAgeMax),
          },
          details: project.details,
          requiredSkill: project.requiredSkill,
          welcomeSkill: project.welcomeSkill,
          teamComposition: project.teamComposition,
          startDate: formatDate(project.startDate),
          expectedDuration: parseNumberToString(project.expectedDuration),
          weeklyWorkingDay: {
            min: parseNumberToString(project.weeklyWorkingDayMin),
            max: parseNumberToString(project.weeklyWorkingDayMax),
          },
          paymentDate: formatDate(project.paymentDate),
          priceSettlementHour: {
            from: parseNumberToString(project.priceSettlementHourFrom),
            to: parseNumberToString(project.priceSettlementHourTo),
          },
          engineerPositionIds:
            project.projectMasterEngineerPositions.map((e) => {
              return {
                label: e.name,
                inputValue: e.id,
              };
            }) || [],
          engineerSkillLanguageIds: processSkillsByCategory('language'),
          engineerSkillFrameworkIds: processSkillsByCategory('framework'),
          engineerSkillMiddlewareIds: processSkillsByCategory('middleware'),
          engineerSkillInfraIds: processSkillsByCategory('infra'),
        }
      : {},
  });

  const {
    formState: { errors },
  } = methods;

  const uploadHeroImage = async (projectId: string, file: File) => {
    try {
      await actions.onUploadHeroImage(projectId, file);
      openSnackbar({
        message: `画像をアップロードしました`,
        autoHide: true,
        autoHideDuration: 3000,
      });
    } catch (e) {
      console.error(e);
      openSnackbar({
        message: '画像のアップロードに失敗しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
    }
  };

  const tryProjectCreate = async (data: FormCreateProjectInput) => {
    try {
      const res = await actions.onCreate(data);
      if (heroImageFile && res?.createProject.data.id && heroImageFile instanceof File) {
        await uploadHeroImage(res.createProject.data.id, heroImageFile);
      }
      openSnackbar({
        message: `案件を作成しました`,
        autoHide: true,
        autoHideDuration: 3000,
      });
      navigate(PROJECT_PATH);
    } catch (e) {
      console.error(e);
      openSnackbar({
        message: '案件作成に失敗しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
    }
  };

  const tryProjectUpdate = async (data: FormCreateProjectInput, projectId: string) => {
    try {
      const res = await actions.onUpdate(data, projectId);
      if (heroImageFile && res?.updateProject.data.id && heroImageFile instanceof File) {
        await uploadHeroImage(res.updateProject.data.id, heroImageFile);
      }
      openSnackbar({
        message: `案件を更新しました`,
        autoHide: true,
        autoHideDuration: 3000,
      });
      navigate(PROJECT_PATH);
    } catch (e) {
      console.error(e);
      openSnackbar({
        message: '案件更新に失敗しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
    }
  };

  const onSubmit = async (data: FormCreateProjectInput) => {
    if (isEdit) {
      await tryProjectUpdate(data, project.id);
    } else {
      await tryProjectCreate(data);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full">
        <FieldContainer>
          <div className="flex flex-col">
            <div className="border-shade-light-default flex w-full flex-row gap-1 border-b py-4">
              <Text size="s" weight="bold" className="w-[120px]">
                カバー画像
              </Text>
              <div className="flex w-full flex-col gap-4">
                <Button type="button" intention="primary" variant="outlined" onClick={openFileBrowser}>
                  画像を選択
                </Button>
                {attachmentFileError && (
                  <Text size="s" weight="bold" className="text-negative-dark-default">
                    {attachmentFileError}
                  </Text>
                )}
                {heroImageFile && <HeroImage src={heroImageFile} />}
              </div>
            </div>
            <div className="border-shade-light-default flex w-full flex-row gap-1 border-b py-4">
              <Text size="s" weight="bold" className="w-[120px]">
                基本情報
              </Text>

              <div className="flex w-full flex-col gap-4">
                <TextField
                  label={
                    <div className="flex flex-row items-center gap-1">
                      <Text size="s" weight="bold">
                        案件名
                      </Text>
                      <Required />
                    </div>
                  }
                  size="medium"
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                  {...methods.register('name')}
                />
              </div>
            </div>
            <div className="border-shade-light-default flex w-full flex-row gap-1 border-b py-4">
              <Text size="s" weight="bold" className="w-[120px]">
                単価
              </Text>

              <div className="flex w-full flex-col gap-4">
                <div className="flex w-full flex-row gap-4">
                  <div className="flex w-1/4 flex-col gap-1">
                    <Controller
                      name="priceTaxType"
                      control={methods.control}
                      render={({ field: { name, onChange } }) => {
                        return (
                          <Select
                            label={
                              <div className="flex flex-row gap-1">
                                <Text size="s" weight="bold">
                                  税
                                </Text>
                              </div>
                            }
                            name={name}
                            error={!!errors.priceTaxType?.message}
                            helperText={errors.priceTaxType?.message}
                            size="medium"
                            placeholder="選択"
                            options={taxTypeOptions}
                            onChange={(_, option) => {
                              const target = option as SelectOption;
                              onChange(target.inputValue);
                            }}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="flex w-1/4 flex-row gap-2">
                    <div className="flex w-full flex-col gap-1 whitespace-nowrap">
                      <div className="flex flex-row gap-1">
                        <Text size="s" weight="bold">
                          単価(月)
                        </Text>
                      </div>
                      <div className="flex flex-row gap-2 ">
                        <TextField
                          size="medium"
                          error={!!errors.priceMonthly?.min?.message}
                          helperText={errors.priceMonthly?.min?.message}
                          suffix="円 / 月"
                          placeholder="3000"
                          {...methods.register('priceMonthly.min')}
                        />
                        <Text size="r">〜</Text>
                        <TextField
                          size="medium"
                          error={!!errors.priceMonthly?.max?.message}
                          helperText={errors.priceMonthly?.max?.message}
                          suffix="円 / 月"
                          placeholder="3000"
                          {...methods.register('priceMonthly.max')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex w-1/4 flex-row gap-2">
                    <div className="flex w-full flex-col gap-1 whitespace-nowrap">
                      <div className="flex flex-row gap-1">
                        <Text size="s" weight="bold">
                          単価(時)
                        </Text>
                      </div>
                      <div className="flex flex-row gap-2 ">
                        <TextField
                          size="medium"
                          error={!!errors.priceHourly?.message}
                          helperText={errors.priceHourly?.message}
                          suffix="円 / 時間"
                          placeholder="3000"
                          {...methods.register(`priceHourly.min`)}
                        />
                        <Text size="r">〜</Text>
                        <TextField
                          size="medium"
                          error={!!errors.priceHourly?.message}
                          helperText={errors.priceHourly?.message}
                          suffix="円 / 時間"
                          placeholder="3000"
                          {...methods.register(`priceHourly.max`)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex w-1/4 flex-row gap-2">
                    <div className="flex w-full flex-col gap-1 whitespace-nowrap">
                      <div className="flex flex-row gap-1">
                        <Text size="s" weight="bold">
                          対象年齢
                        </Text>
                      </div>
                      <div className="flex flex-row gap-2 ">
                        <TextField
                          size="medium"
                          error={!!errors.targetAge?.message}
                          helperText={errors.targetAge?.message}
                          suffix="歳"
                          placeholder="0"
                          {...methods.register(`targetAge.min`)}
                        />
                        <Text size="r">〜</Text>
                        <TextField
                          size="medium"
                          error={!!errors.targetAge?.message}
                          helperText={errors.targetAge?.message}
                          suffix="歳"
                          placeholder="100"
                          {...methods.register(`targetAge.max`)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="border-shade-light-default flex w-full flex-row gap-1 border-b py-4">
              <Text size="s" weight="bold" className="w-[120px]">
                職務内容
              </Text>
              <div className="flex w-full flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <TextArea
                    label={
                      <div className="flex flex-row gap-1">
                        <Text size="s" weight="bold">
                          職務内容
                        </Text>
                      </div>
                    }
                    size="medium"
                    error={!!errors.details?.message}
                    helperText={errors.details?.message}
                    placeholder="内容を入力"
                    rows={5}
                    {...methods.register(`details`)}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <TextArea
                    label={
                      <div className="flex flex-row gap-1">
                        <Text size="s" weight="bold">
                          必須スキル
                        </Text>
                      </div>
                    }
                    size="medium"
                    error={!!errors.requiredSkill?.message}
                    helperText={errors.requiredSkill?.message}
                    placeholder="内容を入力"
                    rows={5}
                    {...methods.register(`requiredSkill`)}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <TextArea
                    label={
                      <div className="flex flex-row gap-1">
                        <Text size="s" weight="bold">
                          歓迎スキル
                        </Text>
                      </div>
                    }
                    size="medium"
                    error={!!errors.welcomeSkill?.message}
                    helperText={errors.welcomeSkill?.message}
                    placeholder="内容を入力"
                    rows={5}
                    {...methods.register(`welcomeSkill`)}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <TextArea
                    label={
                      <div className="flex flex-row gap-1">
                        <Text size="s" weight="bold">
                          チーム編成
                        </Text>
                      </div>
                    }
                    size="medium"
                    error={!!errors.teamComposition?.message}
                    helperText={errors.teamComposition?.message}
                    placeholder="内容を入力"
                    rows={5}
                    {...methods.register(`teamComposition`)}
                  />
                </div>
              </div>
            </div>
            <div className="border-shade-light-default flex w-full flex-row gap-1 border-b py-4">
              <Text size="s" weight="bold" className="w-[120px]">
                稼働日数・期間
              </Text>
              <div className="flex w-full flex-col gap-4">
                <div className="flex w-full flex-row gap-4">
                  <div className="flex w-1/3 flex-col gap-1">
                    <TextField
                      label={
                        <div className="flex flex-row gap-1">
                          <Text size="s" weight="bold">
                            {PROJECT_FORM_VALUES_MAP.WORKING_DAYS.START.label}
                          </Text>
                        </div>
                      }
                      size="medium"
                      error={!!errors.startDate?.message}
                      helperText={errors.startDate?.message?.toString()}
                      placeholder="2023/07/13"
                      type="date"
                      {...methods.register(`startDate`)}
                    />
                  </div>
                  <div className="flex w-1/3 flex-col gap-1">
                    <TextField
                      label={
                        <div className="flex flex-row gap-1">
                          <Text size="s" weight="bold">
                            想定稼働月数
                          </Text>
                        </div>
                      }
                      size="medium"
                      error={!!errors.expectedDuration?.message}
                      helperText={errors.expectedDuration?.message}
                      placeholder="1"
                      suffix="ヶ月"
                      {...methods.register(`expectedDuration`)}
                    />
                  </div>
                  <div className="flex w-1/3 flex-row gap-2">
                    <div className="flex w-full flex-col gap-1 whitespace-nowrap">
                      <div className="flex flex-row gap-1">
                        <Text size="s" weight="bold">
                          希望日数
                        </Text>
                      </div>
                      <div className="flex flex-row gap-2 ">
                        <TextField
                          size="medium"
                          error={!!errors.weeklyWorkingDay?.message}
                          helperText={errors.weeklyWorkingDay?.message}
                          suffix="日"
                          placeholder="10"
                          {...methods.register(`weeklyWorkingDay.min`)}
                        />
                        <Text size="r">〜</Text>
                        <TextField
                          size="medium"
                          error={!!errors.weeklyWorkingDay?.message}
                          helperText={errors.weeklyWorkingDay?.message}
                          suffix="日"
                          placeholder="20"
                          {...methods.register(`weeklyWorkingDay.max`)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-shade-light-default flex w-full flex-row gap-1 border-b py-4">
              <Text size="s" weight="bold" className="w-[120px]">
                契約
              </Text>
              <div className="flex w-full flex-col gap-4">
                <div className="flex w-full flex-row gap-4">
                  <div className="flex w-1/2 flex-col gap-1">
                    <TextField
                      label={
                        <div className="flex flex-row gap-1">
                          <Text size="s" weight="bold">
                            支払日
                          </Text>
                        </div>
                      }
                      size="medium"
                      error={!!errors.paymentDate?.message}
                      helperText={errors.paymentDate?.message?.toString()}
                      placeholder="2023/07/13"
                      type="date"
                      {...methods.register(`paymentDate`)}
                    />
                  </div>
                  <div className="flex w-1/2 flex-row gap-2">
                    <div className="flex w-full flex-col gap-1 whitespace-nowrap">
                      <div className="flex flex-row gap-1">
                        <Text size="s" weight="bold">
                          {PROJECT_FORM_VALUES_MAP.CONTRACT.SETTLEMENT_WIDTH.label}
                        </Text>
                      </div>
                      <div className="flex flex-row gap-2 ">
                        <TextField
                          size="medium"
                          error={!!errors.priceSettlementHour?.message}
                          helperText={errors.priceSettlementHour?.message}
                          suffix="時間"
                          placeholder="10"
                          {...methods.register(`priceSettlementHour.from`)}
                        />
                        <Text size="r">〜</Text>
                        <TextField
                          size="medium"
                          error={!!errors.priceSettlementHour?.message}
                          helperText={errors.priceSettlementHour?.message}
                          suffix="時間"
                          placeholder="20"
                          {...methods.register(`priceSettlementHour.to`)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-shade-light-default flex w-full flex-row gap-1 border-b py-4">
              <Text size="s" weight="bold" className="w-[120px]">
                開発環境
              </Text>
              <div className="flex w-full flex-col gap-4">
                <Suspense>
                  <SelectEngineerPositions name="engineerPositionIds" />
                  <SelectEngineerSkillLanguages name="engineerSkillLanguageIds" />
                  <SelectEngineerSkillFrameworks name="engineerSkillFrameworkIds" />
                  <SelectEngineerSkillMiddlewares name="engineerSkillMiddlewareIds" />
                  <SelectEngineerSkillInfras name="engineerSkillInfraIds" />
                </Suspense>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-end">
            <Button intention="primary" variant="filled" type="submit">
              保存
            </Button>
          </div>
        </FieldContainer>
      </form>
    </FormProvider>
  );
};
