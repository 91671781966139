import { Fragment, useState } from 'react';
import { MdClose, MdMoreVert, MdOutlineContentCopy, MdOutlineEdit } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';
import {
  Badge,
  Button,
  ColumnDef,
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableRow,
  Text,
  useSnackbar,
} from '@4design/for-ui';
import { CollectionCounter } from '@/components/ui-parts/collectionManager';
import { PROJECT_DETAIL_PATH, PROJECT_EDIT_PATH } from '@/constants/routes';
import { useProject } from '@/features/project/repository/useProject';
import { graphql } from '@/gql';
import { InputMaybe, Project, ProjectsFilter, ProjectStatusEnum } from '@/gql/graphql';
import { usePagination } from '@/hooks/usePagination';
import { useSearchParamsObject } from '@/hooks/useSearchParamsObject';
import { formatDate } from '@/lib/dateformat';
import { flexRender } from '@tanstack/react-table';

export const ProjectTable_Query = graphql(/* GraphQL */ `
  query ProjectTable_Query($filter: ProjectsFilter, $offset: Int!, $limit: Int!) {
    projects(filter: $filter, offset: $offset, limit: $limit) {
      nodes {
        __typename
        id
        createdAt
        updatedAt

        organizationId
        name
        status

        details
        requiredSkill
        welcomeSkill
        teamComposition
        targetAgeMax
        targetAgeMin
        startDate
        expectedDuration
        weeklyWorkingDayMin
        weeklyWorkingDayMax
        interviewCount
        priceTaxType
        priceHourlyMax
        priceHourlyMin
        priceMonthlyMax
        priceMonthlyMin
        priceSettlementHourFrom
        priceSettlementHourTo
      }

      pageInfo {
        offset
        total
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

export const ProjectTable = () => {
  const { openSnackbar } = useSnackbar();
  const { page, limit, offset, actions: paginationActions, totalPageCount } = usePagination();
  const { createSearchParamsObject } = useSearchParamsObject();
  const queryParams = createSearchParamsObject<{
    name?: string;
    publish?: string;
  }>();

  const navigate = useNavigate();
  const buildProjectFilter = (): InputMaybe<ProjectsFilter> | undefined => {
    const filter: InputMaybe<ProjectsFilter> = {};

    if (queryParams.name) {
      filter.name = queryParams.name;
    }

    if (queryParams.publish) {
      filter.status = queryParams.publish === 'true' ? ProjectStatusEnum.Open : undefined;
    }

    if (Object.keys(filter).length > 0) {
      return filter;
    }

    return undefined;
  };

  const projectFilter = buildProjectFilter();
  const [result] = useQuery({
    query: ProjectTable_Query,
    variables: {
      filter: projectFilter,
      limit,
      offset,
    },
  });

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const data = (result.data?.projects.nodes as Project[]) ?? [];
  const currentDataCount = data.length;
  const totalCount = result.data?.projects.pageInfo.total ?? 0;
  const totalPage = totalPageCount(totalCount ?? 0);

  const actions = useProject();

  const onBatchClose = async () => {
    await actions.onClose(selectedRowIds).then(() => {
      openSnackbar({ message: '募集を停止しました' });
    });
  };

  const onClose = async (id: string) => {
    await actions.onClose([id]).then(() => {
      openSnackbar({ message: '募集を停止しました' });
    });
  };

  const onDuplicate = async (id: string) => {
    await actions.onDuplicate(id).then(() => {
      openSnackbar({ message: '募集を複製しました' });
    });
  };

  const columns: ColumnDef<Project, unknown>[] = [
    {
      header: '案件名',
      accessorKey: 'title',
      cell: (cell) => {
        const name = cell.row.original.name;
        const id = cell.row.original.id;
        return (
          <TableCell as="th" scope="row">
            <div>
              <Link
                className="text-informative-dark-default underline"
                to={PROJECT_DETAIL_PATH.replace(':projectId', id)}
              >
                <Text weight="regular">{name}</Text>
              </Link>
            </div>
          </TableCell>
        );
      },
    },
    {
      header: 'ステータス',
      accessorKey: 'status',
      cell: (cell) => {
        const status = cell.row.original.status;
        return (
          <TableCell>
            <Badge
              variant="outlined"
              intention={
                (
                  {
                    [ProjectStatusEnum.Open]: 'positive',
                    [ProjectStatusEnum.Close]: 'negative',
                    [ProjectStatusEnum.Draft]: 'shade',
                  } as const
                )[status]
              }
              label={
                (
                  {
                    [ProjectStatusEnum.Open]: '募集中',
                    [ProjectStatusEnum.Close]: '募集停止',
                    [ProjectStatusEnum.Draft]: '未公開',
                  } as const
                )[status]
              }
            />
          </TableCell>
        );
      },
    },
    {
      header: '作成日',
      accessorKey: 'createdAt',
      cell: (cell) => {
        const createdAt = cell.row.original.createdAt;
        return (
          <TableCell>
            <div>
              <Text>{formatDate(createdAt)}</Text>
            </div>
          </TableCell>
        );
      },
    },
    // {
    //   header: 'いいね数',
    //   accessorKey: 'favorites',
    //   cell: (cell) => {
    //     const favorites = cell.row.original.favorites;
    //     return (
    //       <TableCell>
    //         <div>
    //           <Text>{favorites}</Text>
    //         </div>
    //       </TableCell>
    //     );
    //   },
    // },
    {
      header: '最終更新日',
      accessorKey: 'updatedAt',
      cell: (cell) => {
        const updatedAt = cell.row.original.updatedAt;
        return (
          <TableCell>
            <div>
              <Text>{formatDate(updatedAt)}</Text>
            </div>
          </TableCell>
        );
      },
    },
    {
      header: '操作',
      accessorKey: 'menu',
      enableSorting: false,
      cell: (cell) => {
        const id = cell.row.original.id;
        return (
          <TableCell className="flex justify-end">
            <Menu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              TriggerComponent={
                <Button size="small" variant="text" type="button">
                  <MdMoreVert />
                </Button>
              }
            >
              <MenuItem
                onClick={() => {
                  navigate(PROJECT_EDIT_PATH.replace(':projectId', id));
                }}
              >
                <div className="flex items-center gap-2">
                  <MdOutlineEdit size={16} />
                  編集
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onDuplicate(id);
                }}
              >
                <div className="flex items-center gap-2">
                  <MdOutlineContentCopy size={16} />
                  複製
                </div>
              </MenuItem>
              {cell.row.original.status === ProjectStatusEnum.Open && (
                <MenuItem onClick={() => onClose(id)}>
                  <div className="text-negative-dark-default flex items-center gap-2">
                    <MdClose size={16} />
                    募集を停止
                  </div>
                </MenuItem>
              )}
            </Menu>
          </TableCell>
        );
      },
    },
  ];

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-row items-center gap-4">
          <CollectionCounter
            current={{
              from: offset * totalCount + 1,
              to: currentDataCount,
            }}
            total={totalCount}
          />
          {selectedRowIds.length > 0 && (
            <Fragment>
              <div className="bg-shade-light-default h-full w-[1px]" />
              <Badge label={`${selectedRowIds.length.toLocaleString()}件を選択中`} />
              <Button size="medium" intention="negative" onClick={onBatchClose}>
                <MdClose />
                募集を停止
              </Button>
            </Fragment>
          )}
        </div>
      </div>
      <Table<Project>
        columns={columns}
        rowRenderer={({ row }) => (
          <TableRow>
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
            ))}
          </TableRow>
        )}
        data={data}
        getRowId={(row) => row.id.toString()}
        page={page}
        pageCount={totalPage}
        pageSize={limit}
        defaultPage={1}
        defaultSortColumn={{ id: 'title', desc: true }}
        onSelectRows={(ids) => {
          setSelectedRowIds(ids);
        }}
        onChangePage={paginationActions.onChange}
      />
    </div>
  );
};
