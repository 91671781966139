import { z } from 'zod';
import { notMatchMessage } from '@/constants/validation';
import { InviteOrganizationUserInput, UpdateOrganizationUserInput } from '@/gql/graphql';

export type FormAccountInviteInput = InviteOrganizationUserInput;
export const inviteSchema: z.ZodSchema<FormAccountInviteInput> = z.object({
  name: z.string().min(1, '名前を入力してください'),
  email: z
    .string()
    .min(1, 'メールアドレスを入力してください')
    .email({
      message: notMatchMessage('メールアドレス'),
    }),
});

export type FromAccountUpdateInput = UpdateOrganizationUserInput;
