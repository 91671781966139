const _config: { [key: string]: Record<string, string> } = {
  dev: {
    endpoint: 'http://localhost:8888/graphql',
  },
  stg: {
    endpoint: 'https://comm-stg-enterprise.api.relance.jp/graphql',
  },
  prd: {
    endpoint: 'https://comm-enterprise.api.relance.jp/graphql',
  },
};

export const config = _config[(import.meta.env.VITE_ENV as string) || 'dev'];
