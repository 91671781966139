import { Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Text } from '@4design/for-ui';
import { MessageList } from './MessageList';
import { SearchMessageForm } from './SearchMessageForm';

export const Message = () => {
  const { messageId } = useParams<{ messageId: string }>();

  return (
    <div className="bg-shade-light-default h-screen w-full p-4">
      <div className="flex w-full flex-row items-start gap-4">
        <div className="bg-shade-white-default rounded-2 flex h-[calc(100vh-56px-32px)] w-1/3 flex-col">
          <Suspense>
            <div className="border-shade-light-default w-full border-b">
              <SearchMessageForm />
            </div>
          </Suspense>

          <Suspense>
            <MessageList />
          </Suspense>
        </div>

        {messageId ? (
          <Outlet />
        ) : (
          <div className="bg-shade-white-default rounded-2 border-shade-light-default shadow-attractive flex h-[calc(100vh-56px-32px)] w-2/3 flex-col items-center justify-center border">
            <Text size="r" className="text-shade-dark-default">
              メッセージを選択してください
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
