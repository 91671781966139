import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { HttpError } from '@/components/error/ErrorBoundary';
import { PageLayout } from '@/components/ui-parts/layout/PageLayout';
import { PROJECT_EDIT_PAGE_TITLE } from '@/constants/pageTitle';
import { graphql } from '@/gql';
import { Project } from '@/gql/graphql';
import { ProjectDetail_Query } from '../detail';
import { ProjectForm } from '../form';

export const ProjectEdit = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [result] = useQuery({
    query: ProjectDetail_Query,
    variables: { id: projectId as string },
  });
  const data = result.data?.project;

  if (!data) {
    throw new HttpError(404);
  }
  return (
    <PageLayout title={data.name || PROJECT_EDIT_PAGE_TITLE}>
      <ProjectForm project={data as Project} />
    </PageLayout>
  );
};
