import { Text } from '@4design/for-ui';

type Props = {
  title: string;
};

export const PageTitle = ({ title }: Props) => {
  return (
    <div className="flex flex-col items-start gap-1">
      <Text weight="bold" size="l">
        {title}
      </Text>
    </div>
  );
};
