import { z } from 'zod';
import { PasswordResetOrganizationUserInput } from '../../../gql/graphql';

export type FormPasswordResetOrganizationUserInput = PasswordResetOrganizationUserInput;
export const schema: z.ZodSchema<FormPasswordResetOrganizationUserInput> = z.object({
  email: z
    .string({
      required_error: 'メールアドレスを入力してください',
    })
    .email({
      message: 'メールアドレスの形式が正しくありません',
    }),
});

export type FormPasswordResetNewPasswordInput = {
  newPassword: string;
};
export const newPasswordSchema: z.ZodSchema<FormPasswordResetNewPasswordInput> = z.object({
  newPassword: z.string().min(1, 'パスワードを入力してください'),
});
