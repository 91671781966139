import React from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarProvider } from '@4design/for-ui';
import { UrqlProvider } from '@/components/urql';
import { App } from './App';
import { AuthProvider } from './providers/AuthProvider';
import './styles/index.css';
import './styles/theme.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <SnackbarProvider>
      <AuthProvider>
        <UrqlProvider>
          <App />
        </UrqlProvider>
      </AuthProvider>
    </SnackbarProvider>
  </React.StrictMode>,
);
