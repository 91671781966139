import LoadingCircle from '@/public/loading_circle.svg';

export const Loading = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4">
      <div>
        <img src={LoadingCircle} alt="loading" className="animate-spin" />
      </div>

      <p>読み込み中</p>
    </div>
  );
};
