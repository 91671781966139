import { z } from 'zod';

// 20MB
const MAX_FILE_SIZE = 20000000;
export const ACCEPTED_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/zip',
  'application/x-zip-compressed',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const ACCEPTED_CSV_TYPES = ['text/csv'];

export const ACCEPTED_PDF_TYPES = ['application/pdf'];

export const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export const zodFiles = z
  .array(z.instanceof(File))
  .refine((files) => {
    return (files?.[0]?.size || 0) <= MAX_FILE_SIZE;
  }, `ファイルサイズが大きすぎます。20MB以下にしてください`)
  .refine((files) => {
    if (!files?.[0]?.type) return true;

    return ACCEPTED_FILE_TYPES.includes(files?.[0]?.type);
  }, 'ファイルは.png、.jpeg、.jpg、.pdf、.csv、.xlsx、.xls、.doc、.docx、.zipのみアップロード可能です.');

export const imageFiles = z
  .array(z.instanceof(File))
  .refine((files) => {
    const filesSize = files.reduce((acc, curr) => {
      return acc + curr.size;
    }, 0);
    return (filesSize || 0) <= MAX_FILE_SIZE;
  }, `ファイルサイズが大きすぎます。20MB以下にしてください`)
  .refine((files) => {
    if (!files?.[0]?.type) return true;

    return ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type);
  }, 'ファイルは.png、.jpeg、.jpgのみアップロード可能です.');

export const csvZodFiles = z
  .array(z.instanceof(File))
  .refine((files) => {
    return (files?.[0]?.size || 0) <= MAX_FILE_SIZE;
  }, `ファイルサイズが大きすぎます。20MB以下にしてください`)
  .refine((files) => {
    if (!files?.[0]?.type) return true;

    return ACCEPTED_CSV_TYPES.includes(files?.[0]?.type);
  }, 'ファイルは、.csvのみアップロード可能です.');

export const pdfFile = z
  .any()
  .refine((files) => {
    return (files?.size || 0) <= MAX_FILE_SIZE;
  }, `ファイルサイズが大きすぎます。20MB以下にしてください`)
  .refine((files) => {
    if (!files?.type) return true;
    return ACCEPTED_PDF_TYPES.includes(files?.type);
  }, 'ファイルは.pdfのみアップロード可能です.');

export const imageFile = z
  .any()
  .refine((files) => {
    return (files?.size || 0) <= MAX_FILE_SIZE;
  }, `ファイルサイズが大きすぎます。20MB以下にしてください`)
  .refine((files) => {
    if (!files?.type) return true;
    return ACCEPTED_IMAGE_TYPES.includes(files?.type);
  }, 'ファイルは.jpeg,.jpg,.pngのみアップロード可能です.');
