import clsx from 'clsx';
import { format } from 'date-fns';
// import { ja } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { Badge, Text } from '@4design/for-ui';
import { MESSAGE_DETAIL_PATH } from '@/constants/routes';
import { Scout } from '@/gql/graphql';
import { formatDateTime } from '@/lib/dateformat';

type Props = {
  scout: Scout;
  currentScoutId?: string;
  currentQueryParams: URLSearchParams;
};

export const CollectionItem = (props: Props) => {
  const messageUrl = `${MESSAGE_DETAIL_PATH.replace(
    ':messageId',
    props.scout.id,
  )}?${props.currentQueryParams.toString()}`;
  const message = props.scout.latestMessage;
  const talent = props.scout.talent;
  const isActive = props.currentScoutId === props.scout.id;

  const createdAtFormat = formatDateTime(message?.createdAt);
  const isUnReply = false;

  return (
    <Link className="border-shade-light-default flex border-b" to={messageUrl}>
      <div
        className={clsx([
          'hover:bg-shade-white-hover bg-shade-white-default flex w-full flex-col gap-2 overflow-hidden px-4 py-2',
          isActive && 'bg-shade-white-hover border-primary-dark-default border-r-4',
        ])}
      >
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-row items-center gap-1">
                {/* TODO: {talent.avatar ? (
                  <img
                    className="min-h-6 min-w-6 border-shade-light-default bg-shade-white-default h-6 w-6 rounded-full border"
                    src={talent.avatar}
                    alt={talent.name}
                  />
                ) : (
                  <div className="min-h-6 min-w-6 border-shade-light-default bg-shade-white-default flex h-6 w-6 flex-col items-center justify-center rounded-full border">
                    <Text className="m-auto" size="xs">
                      {messageId}
                    </Text>
                  </div>
                )} */}

                <Text size="r" weight="bold">
                  {talent.name}
                </Text>
              </div>
              <Text size="s" className="text-shade-medium-default" weight="inherit">
                {createdAtFormat}
              </Text>
            </div>
            <div className="flex flex-row gap-2">
              {props.scout.unreadOrganizationCount > 0 ? (
                <Badge intention="primary" variant="outlined" label={`未読${props.scout.unreadOrganizationCount}件`} />
              ) : isUnReply ? (
                <Badge intention="notice" variant="outlined" label="未返信" />
              ) : null}
            </div>
          </div>
          <Text
            size="r"
            className={clsx(['truncate', isActive ? 'text-shade-dark-default' : 'text-shade-medium-default'])}
            weight={isActive ? 'bold' : 'inherit'}
          >
            {message?.message}
          </Text>
        </div>
      </div>
    </Link>
  );
};
