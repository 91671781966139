import { Suspense } from 'react';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { PageLayout } from '@/components/ui-parts/layout/PageLayout';
import { PROJECT_LIST_PAGE_TITLE } from '@/constants/pageTitle';
import { ProjectSearchForm } from './header';
import { ProjectTable } from './table';

export const Projects = () => {
  return (
    <PageLayout title={PROJECT_LIST_PAGE_TITLE}>
      <FieldContainer>
        <div className="flex w-full flex-col gap-2">
          <ProjectSearchForm />

          <Suspense>
            <ProjectTable />
          </Suspense>
        </div>
      </FieldContainer>
    </PageLayout>
  );
};
