import { MdArchive, MdMoreVert } from 'react-icons/md';
import { Button, Menu, Text } from '@4design/for-ui';
import { AgreementFormModal } from '@/features/agreement/components/form';
import { Scout } from '@/gql/graphql';

type Props = {
  scout: Scout;
};

export const MessageHeader = (props: Props) => {
  const talent = props.scout.talent;
  const project = props.scout.project;

  return (
    <div className="bg-shade-white-default border-shade-light-default rounded-tl-2 flex w-full items-center justify-between py-2 pl-4 pr-2">
      <Text className="text-shade-dark-default" size="xr" weight="bold">
        {talent.name}
      </Text>

      <div className="flex flex-row items-center gap-1">
        <AgreementFormModal projectId={project.id} talentId={talent.id} scoutId={props.scout.id} />
      </div>
    </div>
  );
};
