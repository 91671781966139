import { FC, PropsWithChildren, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout as UILayout } from '@/components/ui-parts/layout';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <UILayout>
      <Suspense>{children ? children : <Outlet />}</Suspense>
    </UILayout>
  );
};
