import { memo } from 'react';
import { PageLayout } from '@/components/ui-parts/layout/PageLayout';
import { PROJECT_NEW_PAGE_TITLE } from '@/constants/pageTitle';
import { ProjectForm } from '../form';

export const NewProject = memo(() => {
  return (
    <PageLayout title={PROJECT_NEW_PAGE_TITLE}>
      <ProjectForm />
    </PageLayout>
  );
});
