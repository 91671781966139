export const DASHBOARD_PAGE_TITLE = 'ダッシュボード' as const;
export const TEMPLATE_PAGE_TITLE = 'テンプレート管理' as const;
export const MESSAGE_PAGE_TITLE = 'メッセージ' as const;
export const TALENT_PAGE_TITLE = '候補者' as const;
export const TALENT_DETAIL_PAGE_TITLE = '候補者詳細' as const;
export const COMPANY_PAGE_TITLE = '企業情報' as const;
export const COMPANY_EDIT_PAGE_TITLE = '企業情報編集' as const;

export const PROJECT_PAGE_TITLE = '案件管理' as const;
export const PROJECT_LIST_PAGE_TITLE = '案件一覧' as const;
export const PROJECT_DETAIL_PAGE_TITLE = '案件詳細' as const;
export const PROJECT_NEW_PAGE_TITLE = '案件作成' as const;
export const PROJECT_EDIT_PAGE_TITLE = '案件編集' as const;

export const ACCOUNT_PAGE_TITLE = 'アカウント一覧' as const;
export const ACCOUNT_DERAIL_PAGE_TITLE = 'アカウント設定' as const;
