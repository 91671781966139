import { useForm } from 'react-hook-form';
import { MdOutlinePersonAdd, MdPersonAdd } from 'react-icons/md';
import { Button, Modal, ModalContent, ModalFooter, ModalHeader, Text, TextField, useSnackbar } from '@4design/for-ui';
import { useModal } from '@/components/hooks/modal/useModal';
import { FormAccountInviteInput, inviteSchema } from '@/features/account/repository/account.model';
import { useOrganizationUser } from '@/features/account/repository/useOrganizationUser';
import { zodResolver } from '@hookform/resolvers/zod';

export const InviteModal = () => {
  const { openSnackbar } = useSnackbar();
  const [open, modalActions] = useModal();
  const actions = useOrganizationUser();
  const methods = useForm<FormAccountInviteInput>({
    resolver: zodResolver(inviteSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormAccountInviteInput) => {
    await actions
      .onInvite(data)
      .then(() => {
        openSnackbar({ message: '招待が完了しました' });
        reset();
        modalActions.close();
      })
      .catch((e) => {
        openSnackbar({ message: e.message || '招待が失敗しました' });
        console.error(e);
      });
  };

  return (
    <>
      <Button type="button" onClick={modalActions.open} size="medium" variant="filled" intention="primary">
        <MdOutlinePersonAdd />
        招待
      </Button>

      <Modal open={open} onClose={modalActions.close}>
        <form className="w-140" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <Text size="xr" weight="bold">
              招待
            </Text>
          </ModalHeader>

          <ModalContent>
            <div className="flex w-full flex-col gap-4">
              <Text>メールアドレス宛に招待リンクが送られます</Text>
              <TextField
                label={
                  <Text size="s" weight="bold">
                    名前
                  </Text>
                }
                {...register('name')}
                className="w-full"
                placeholder="山田 太郎"
                size="medium"
                helperText={errors.email?.message}
                error={!!errors.email?.message}
              />

              <TextField
                label={
                  <Text size="s" weight="bold">
                    メールアドレス
                  </Text>
                }
                {...register('email')}
                className="w-full"
                placeholder="example@example.com"
                size="medium"
                helperText={errors.email?.message}
                error={!!errors.email?.message}
              />
            </div>
          </ModalContent>
          <ModalFooter>
            <Button
              size="large"
              type="submit"
              variant="filled"
              intention="primary"
              className="ml-4"
              loading={isSubmitting}
            >
              招待する
            </Button>

            <Button size="large" type="button" variant="outlined" onClick={modalActions.close}>
              キャンセル
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
