import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearchParamsObject } from './useSearchParamsObject';

export const usePagination = () => {
  // ページネーションする際に、フィルターなどのクエリパラメータを引き継ぐためのもの
  const { createSearchParamsObject } = useSearchParamsObject();
  const extendsQueryParams = createSearchParamsObject<{
    [key: string]: string;
  }>();

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultPage = Number(searchParams.get('page')) || 1;
  const defaultLimit = Number(searchParams.get('limit')) || 50;

  const [page, setPage] = React.useState(defaultPage);
  const [limit, setLimit] = React.useState(defaultLimit);

  const offset = (page - 1) * limit;
  const next = () => setPage(page + 1);
  const prev = () => setPage(page - 1);
  const onChange = React.useCallback(
    (p: number) => {
      if (p === page) return;

      setSearchParams({
        page: p.toString(),
        limit: limit.toString(),
        ...extendsQueryParams,
      });
      setPage(p);
    },
    [page, setSearchParams, limit, extendsQueryParams],
  );

  const onLimitChange = React.useCallback(
    (v: number) => {
      if (v === limit) return;

      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        limit: v.toString(),
        ...extendsQueryParams,
      });
      setLimit(v);
    },
    [limit, extendsQueryParams, searchParams, setSearchParams],
  );

  const totalPageCount = (totalCount = 0) => {
    return Math.ceil(totalCount / limit);
  };

  return {
    page,
    limit,
    offset,
    totalPageCount,
    actions: {
      next,
      prev,
      onChange,
      onLimitChange,
    },
  };
};
