import { ProjectFormValues } from '../../hooks/useProjectForm';

export type Project = ProjectFormValues & {
  id: string;
  status: 'open' | 'close' | 'draft' | 'stop';
  scoutStatus: 'restricted' | 'archived' | 'scouting' | 'agentScouting';
  favorites: number;
  updatedAt: Date;
  createdAt: Date;
  stopDate: Date;
  numberOfCandidates: number;
};

export const languages = [
  {
    label: 'C',
    inputValue: 'c',
  },
  {
    label: 'C++',
    inputValue: 'cpp',
  },
  {
    label: 'C#',
    inputValue: 'csharp',
  },
  {
    label: 'Dart',
    inputValue: 'dart',
  },
  {
    label: 'Go',
    inputValue: 'go',
  },
  {
    label: 'gRPC',
    inputValue: 'grpc',
  },
  {
    label: 'Java',
    inputValue: 'java',
  },
  {
    label: 'JavaScript',
    inputValue: 'javascript',
  },
  {
    label: 'Kotlin',
    inputValue: 'kotlin',
  },
  {
    label: 'Lua',
    inputValue: 'lua',
  },
  {
    label: 'MATLAB',
    inputValue: 'matlab',
  },
  {
    label: 'Objective-C',
    inputValue: 'objectivec',
  },
  {
    label: 'Perl',
    inputValue: 'perl',
  },
  {
    label: 'PHP',
    inputValue: 'php',
  },
  {
    label: 'Python',
    inputValue: 'python',
  },
  {
    label: 'R',
    inputValue: 'r',
  },
  {
    label: 'Ruby',
    inputValue: 'ruby',
  },
  {
    label: 'Rust',
    inputValue: 'rust',
  },
  {
    label: 'Scala',
    inputValue: 'scala',
  },
  {
    label: 'Scheme',
    inputValue: 'scheme',
  },
  {
    label: 'Swift',
    inputValue: 'swift',
  },
  {
    label: 'TypeScript',
    inputValue: 'typescript',
  },
  {
    label: 'VB.NET',
    inputValue: 'vbnet',
  },
];
export const frameworks = [
  {
    label: 'Angular',
    inputValue: 'angular',
  },
  {
    label: 'ASP.NET',
    inputValue: 'aspnet',
  },
  {
    label: 'CakePHP',
    inputValue: 'cakephp',
  },
  {
    label: 'CodeIgniter',
    inputValue: 'codeigniter',
  },
  {
    label: 'Django',
    inputValue: 'django',
  },
  {
    label: 'Ember.js',
    inputValue: 'ember',
  },
  {
    label: 'Express.js',
    inputValue: 'express',
  },
  {
    label: 'Flask',
    inputValue: 'flask',
  },
  {
    label: 'Flutter',
    inputValue: 'flutter',
  },
  {
    label: 'FuelPHP',
    inputValue: 'fuelphp',
  },
  {
    label: 'jQuery',
    inputValue: 'jquery',
  },
  {
    label: 'Laravel',
    inputValue: 'laravel',
  },
  {
    label: 'NestJS',
    inputValue: 'nestjs',
  },
  {
    label: 'Next.js',
    inputValue: 'nextJs',
  },
  {
    label: 'Nuxt.js',
    inputValue: 'nuxtJs',
  },
  {
    label: 'Padrino',
    inputValue: 'padrino',
  },
  {
    label: 'Play Framework',
    inputValue: 'playframework',
  },
  {
    label: 'pyTorch',
    inputValue: 'pytorch',
  },
  {
    label: 'React',
    inputValue: 'react',
  },
  {
    label: 'reactNative',
    inputValue: 'reactnative',
  },
  {
    label: 'Ruby on Rails',
    inputValue: 'rubyonrails',
  },
  {
    label: 'Sinatra',
    inputValue: 'sinatra',
  },
  {
    label: 'Spring',
    inputValue: 'spring',
  },
  {
    label: 'Svelte',
    inputValue: 'svelte',
  },
  {
    label: 'Symfony',
    inputValue: 'symfony',
  },
  {
    label: 'Tensorflow',
    inputValue: 'tensorflow',
  },
  {
    label: 'Unreal Engine',
    inputValue: 'unrealengine',
  },
  {
    label: 'Vue.js',
    inputValue: 'vue',
  },
];

export const middleware = [
  {
    label: 'Amazon Athena',
    inputValue: 'amazonathena',
  },
  {
    label: 'Amazon Aurora',
    inputValue: 'amazonaurora',
  },
  {
    label: 'Amazon DynamoDB',
    inputValue: 'amazondynamodb',
  },
  {
    label: 'Amazon Glue',
    inputValue: 'amazonglue',
  },
  {
    label: 'Amazon Redshift',
    inputValue: 'amazonredshift',
  },
  {
    label: 'Elasticsearch',
    inputValue: 'elasticsearch',
  },
  {
    label: 'Firebase',
    inputValue: 'firebase',
  },
  {
    label: 'Google BigQuery',
    inputValue: 'googlebigquery',
  },
  {
    label: 'Cloud Functions',
    inputValue: 'googlecloudfunctions',
  },
  {
    label: 'Cloud Dataflow',
    inputValue: 'googledataflow',
  },
];
export const infrastructures = [
  {
    label: 'AWS',
    inputValue: 'aws',
  },
  {
    label: 'Azure',
    inputValue: 'azure',
  },
  {
    label: 'Bitrise',
    inputValue: 'bitrise',
  },
  {
    label: 'Cloudflare',
    inputValue: 'cloudflare',
  },
  {
    label: 'Docker',
    inputValue: 'docker',
  },
  {
    label: 'Fargate',
    inputValue: 'fargate',
  },
  {
    label: 'Fastlane',
    inputValue: 'fastlane',
  },
  {
    label: 'Google Cloud Platform',
    inputValue: 'googlecloudplatform',
  },
  {
    label: 'Heroku',
    inputValue: 'heroku',
  },
  {
    label: 'Kubernetes',
    inputValue: 'kubernetes',
  },
  {
    label: 'Netlify',
    inputValue: 'netlify',
  },
  {
    label: 'Oracle',
    inputValue: 'oracle',
  },
  {
    label: 'Terraform',
    inputValue: 'terraform',
  },
  {
    label: 'Vercel',
    inputValue: 'vercel',
  },
  {
    label: 'WindowServer',
    inputValue: 'windowServer',
  },
];
export const developmentMethod = [
  {
    label: 'アジャイル',
    inputValue: 'agile',
  },
  {
    label: 'ウォーターフォール',
    inputValue: 'waterfall',
  },
  {
    label: 'スクラム',
    inputValue: 'scrum',
  },
  { label: 'XP', inputValue: 'XP' },
  { label: 'TDD', inputValue: 'TDD' },
  { label: 'BDD', inputValue: 'BDD' },
  { label: 'DevOps', inputValue: 'DevOps' },
  { label: 'DevSecOps', inputValue: 'DevSecOps' },
  { label: 'Kanban', inputValue: 'Kanban' },
  { label: 'リーン', inputValue: 'リーン' },

  { label: 'アジャイル開発', inputValue: 'アジャイル開発' },

  { label: 'ウォーターフォール開発', inputValue: 'ウォーターフォール開発' },

  { label: 'スクラム開発', inputValue: 'スクラム開発' },
];
export const configurationManagementTools = [
  {
    label: 'Git',
    inputValue: 'Git',
  },
  {
    label: 'Subversion',
    inputValue: 'Subversion',
  },
  {
    label: 'CVS',
    inputValue: 'CVS',
  },
  { label: 'Mercurial', inputValue: 'Mercurial' },
  { label: 'Perforce', inputValue: 'Perforce' },
  { label: 'Bazaar', inputValue: 'Bazaar' },
  { label: 'Fossil', inputValue: 'Fossil' },
  { label: 'Plastic SCM', inputValue: 'Plastic SCM' },
  { label: 'SourceGear Vault', inputValue: 'SourceGear Vault' },
  { label: 'Team Foundation Server', inputValue: 'Team Foundation Server' },
  { label: 'Visual SourceSafe', inputValue: 'Visual SourceSafe' },
  { label: 'BitKeeper', inputValue: 'BitKeeper' },
  { label: 'AccuRev SCM', inputValue: 'AccuRev SCM' },

  { label: 'GitLab', inputValue: 'GitLab' },
  { label: 'GitHub', inputValue: 'GitHub' },
  { label: 'Bitbucket', inputValue: 'Bitbucket' },
];
export const monitoringTools = [
  {
    label: 'Zabbix',
    inputValue: 'Zabbix',
  },
  {
    label: 'Nagios',
    inputValue: 'Nagios',
  },
  {
    label: 'Datadog',
    inputValue: 'Datadog',
  },
  { label: 'New Relic', inputValue: 'New Relic' },
  { label: 'Sensu', inputValue: 'Sensu' },
  { label: 'Prometheus', inputValue: 'Prometheus' },
  { label: 'Icinga', inputValue: 'Icinga' },
  { label: 'Grafana', inputValue: 'Grafana' },
  { label: 'Monit', inputValue: 'Monit' },
  { label: 'Opsview', inputValue: 'Opsview' },
  { label: 'Zenoss', inputValue: 'Zenoss' },
  { label: 'Cacti', inputValue: 'Cacti' },
  { label: 'Munin', inputValue: 'Munin' },
  { label: 'Ganglia', inputValue: 'Ganglia' },
  { label: 'Check_MK', inputValue: 'Check_MK' },
  { label: 'Icinga 2', inputValue: 'Icinga 2' },
  { label: 'PRTG', inputValue: 'PRTG' },
];

// 経験職種
export const experienceJobType = [
  {
    label: 'バックエンド',
    inputValue: 'BACKEND_ENGINEER',
  },
  {
    label: 'ブロックチェーン',
    inputValue: 'BLOCKCHAIN_ENGINEER',
  },
  {
    label: 'CTO/CIO',
    inputValue: 'CTO_CIO',
  },
  {
    label: 'データベース',
    inputValue: 'DATABASE_ENGINEER',
  },
  {
    label: 'データエンジニア',
    inputValue: 'DATA_ENGINEER',
  },
  {
    label: 'データサイエンティスト',
    inputValue: 'DATA_SCIENTIST',
  },
  {
    label: 'DXコンサルタント',
    inputValue: 'DX_CONSULTANT',
  },
  {
    label: '組み込み',
    inputValue: 'EMBEDDED_ENGINEER',
  },
  {
    label: 'エンジニアマネージャー',
    inputValue: 'ENGINEERING_MANAGER',
  },
  {
    label: 'フロントエンド',
    inputValue: 'FRONTEND_ENGINEER',
  },
  {
    label: 'フルスタックエ',
    inputValue: 'FULLSTACK_ENGINEER',
  },
  {
    label: 'ゲーム開発',
    inputValue: 'GAME_DEV_ENGINEER',
  },
  {
    label: 'ICTエンジニア',
    inputValue: 'ICT_ENGINEER',
  },
  {
    label: 'インフラ',
    inputValue: 'INFRA_ENGINEER',
  },
  {
    label: 'IOTエンジニア',
    inputValue: 'IOT_ENGINEER',
  },
  {
    label: 'ITコンサルタント',
    inputValue: 'IT_CONSULTANT',
  },
  {
    label: 'iOS/Android',
    inputValue: 'MOBILE_ENGINEER',
  },
  {
    label: 'ネットワーク',
    inputValue: 'NETWORK_ENGINEER',
  },
  {
    label: 'プロダクトマネージャー',
    inputValue: 'PRODUCT_MANAGER',
  },
  {
    label: 'プロジェクトリーダー',
    inputValue: 'PROJECT_LEADER',
  },
  {
    label: 'プロジェクトマネージャー',
    inputValue: 'PROJECT_MANAGER',
  },
  {
    label: 'QAエンジニア',
    inputValue: 'QA_ENGINEER',
  },
  {
    label: 'Salesforceエンジニア',
    inputValue: 'SALESFORCE_ENGINEER',
  },
  {
    label: 'スクラムマスター',
    inputValue: 'SCRUM_MASTER',
  },
  {
    label: 'SE',
    inputValue: 'SE',
  },
  {
    label: 'セキュリティ',
    inputValue: 'SECURITY_ENGINEER',
  },
  {
    label: 'SRE',
    inputValue: 'SRE_ENGINEER',
  },
  {
    label: 'サポートエンジニア',
    inputValue: 'SUPPORT_ENGINEER',
  },
  {
    label: 'テックリード',
    inputValue: 'TECH_LEAD',
  },
  {
    label: 'UXエンジニア',
    inputValue: 'UX_ENGINEER',
  },
  {
    label: 'VPOE',
    inputValue: 'VPOE',
  },
  {
    label: 'Web制作',
    inputValue: 'WEB_DEVELOPER',
  },
];

const arrayProjectsData = (count = 1000): Project[] => {
  const projectsData: Project[] = [];
  for (let i = 0; i < count; i++) {
    const randomTextData = (text: string) => {
      return Math.random() < 0.5 ? text : '';
    };
    const randomRangeData = (from: string, to: string) => {
      const range = {
        from: '',
        to: '',
      };
      if (Math.random() < 0.5) {
        range.from = from;
      }
      if (Math.random() < 0.5) {
        range.to = to;
      }
      return range;
    };
    const randomScoutState = (): Project['scoutStatus'] => {
      const states: Project['scoutStatus'][] = ['restricted', 'archived', 'scouting', 'agentScouting'];
      return states[Math.floor(Math.random() * states.length)];
    };

    projectsData.push({
      id: `jd00010${i}`,
      baseInfo: {
        name: `Sonar:【React/Typescript】セキュリティ診断プラットフォームの新規開発業務 ${i}`,
        nameForPublic: randomTextData(`Sonar:【React/Typescript】セキュリティ診断プラットフォームの新規開発業務 ${i}`),
      },
      unitPrice: {
        tax: {
          label: '',
          inputValue: '',
        },
        monthly: randomRangeData('300000', '400000'),
        hourly: randomRangeData('3000', '4000'),
        age: randomRangeData('20', '30'),
      },
      businessContent: {
        jobDescription: randomTextData(
          '職務内容がこちらに表示されます。職務内容がこちらに表示されます。職務内容がこちらに表示されます。職務内容がこちらに表示されます。職務内容がこちらに表示されます。職務内容がこちらに表示されます。職務内容がこちらに表示されます。',
        ),
        requiredSkills: randomTextData(
          '必須スキルがこちらに表示されます。必須スキルがこちらに表示されます。必須スキルがこちらに表示されます。必須スキルがこちらに表示されます。必須スキルがこちらに表示されます。必須スキルがこちらに表示されます。必須スキルがこちらに表示されます。',
        ),
        welcomeSkills: randomTextData(
          '歓迎スキルがこちらに表示されます。歓迎スキルがこちらに表示されます。歓迎スキルがこちらに表示されます。歓迎スキルがこちらに表示されます。歓迎スキルがこちらに表示されます。歓迎スキルがこちらに表示されます。歓迎スキルがこちらに表示されます。',
        ),
        teamComposition: randomTextData(
          'チーム編成がこちらに表示されます。チーム編成がこちらに表示されます。チーム編成がこちらに表示されます。チーム編成がこちらに表示されます。チーム編成がこちらに表示されます。チーム編成がこちらに表示されます。チーム編成がこちらに表示されます。',
        ),
      },
      workingDays: {
        start: randomTextData('2023-01-01'),
        months: randomTextData('1'),
        days: randomRangeData('10', '20'),
      },
      contract: {
        settlementWidth: randomRangeData('1', '2'),
        paymentDate: randomTextData('2023-02-28'),
      },
      developmentEnvironment: {
        languages: languages.slice(0, Math.floor(Math.random() * languages.length)),
        frameworks: frameworks.slice(0, Math.floor(Math.random() * frameworks.length)),
        middleware: middleware.slice(0, Math.floor(Math.random() * middleware.length)),
        infrastructures: infrastructures.slice(0, Math.floor(Math.random() * infrastructures.length)),
        developmentMethod: developmentMethod.slice(0, Math.floor(Math.random() * developmentMethod.length)),
        configurationManagementTools: configurationManagementTools.slice(
          0,
          Math.floor(Math.random() * configurationManagementTools.length),
        ),
        monitoringTools: monitoringTools.slice(0, Math.floor(Math.random() * monitoringTools.length)),
      },
      favorites: Math.floor(Math.random() * 1000),
      status: i % 4 === 0 ? 'open' : i % 5 === 0 ? 'close' : i % 3 === 0 ? 'draft' : 'stop',
      updatedAt: new Date(),
      createdAt: new Date(),
      stopDate: new Date(),
      numberOfCandidates: Math.floor(Math.random() * 100),
      scoutStatus: randomScoutState(),
    });
  }
  return projectsData;
};

export const projectsData = arrayProjectsData();
