import { useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Text, TextField, useSnackbar } from '@4design/for-ui';
import { useAuth } from '@/components/firebase';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { HyperLink } from '@/components/ui-parts/Link/HyperLink';
import { Required } from '@/components/ui-parts/required';
import { LOGIN_PATH, SIGN_UP_PATH } from '@/constants/routes';
import {
  FormPasswordResetNewPasswordInput,
  newPasswordSchema,
} from '@/features/password-reset/repository/password-reset.model';
import { useResetPassword } from '@/features/password-reset/repository/usePasswordReset';
import { authClient } from '@/lib/firebase';
import { zodResolver } from '@hookform/resolvers/zod';

export const PasswordResetVerify = () => {
  const actions = useResetPassword();
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, loading } = useAuth();

  const methods = useForm<FormPasswordResetNewPasswordInput>({
    resolver: zodResolver(newPasswordSchema),
  });

  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    if (user) {
      signOut(authClient());
    }
  }, [user]);

  useEffect(() => {
    if (!mode || !oobCode) {
      navigate(LOGIN_PATH);
      openSnackbar({ message: '無効なURLです' });
      return;
    }
  }, []);

  const {
    watch,
    setValue,
    trigger,
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormPasswordResetNewPasswordInput) => {
    try {
      await actions
        .onUpdatePassword(data, oobCode || '')
        .then(() => {
          openSnackbar({ message: 'パスワードリセットが完了しました' });
          navigate('/');
        })
        .catch((e) => {
          openSnackbar({ message: 'パスワードリセットに失敗しました' });
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="min-width[1400px] bg-shade-light-default h-screen w-screen">
      <div className="m-auto flex h-full w-[fit-content] flex-col items-center justify-center">
        <div className="w-140 border-shade-light-default flex min-h-[320px] flex-col justify-center gap-4">
          <Text size="l" weight="bold">
            パスワード再設定
          </Text>

          <FieldContainer>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <Text size="s" weight="bold">
                    新しいパスワード
                  </Text>
                  <Required />
                </div>
                <TextField
                  size="medium"
                  type="password"
                  {...register('newPassword')}
                  error={!!errors?.newPassword?.message}
                  helperText={errors?.newPassword?.message}
                />
              </div>
              <div className="flex w-full justify-end">
                <Button loading={isSubmitting} type="submit" intention="primary" variant="filled">
                  変更する
                </Button>
              </div>
            </form>
          </FieldContainer>

          <div className="flex items-center justify-center gap-4">
            <HyperLink size="r" underline label="Relance Commについて" to={SIGN_UP_PATH} />
            <HyperLink size="r" underline label="運営会社" to={SIGN_UP_PATH} />
            <HyperLink size="r" underline label="利用規約" to={SIGN_UP_PATH} />
            <HyperLink size="r" underline label="プライバシーポリシー" to={SIGN_UP_PATH} />
          </div>
        </div>
      </div>
    </div>
  );
};
