export const requiredMessage = (label: string) => {
  return `${label}は必須です`;
};

export const notMatchMessage = (label: string) => {
  return `${label}の形式が正しくありません`;
};

export const minOneMessage = (label: string) => {
  return `${label}は最低一つ以上は必要です`;
};

// 最大Nコマで
export const maxNMessage = (label: string, max: number) => {
  return `${label}は最大${max}個までです`;
};

export const rangeMessage = (label: string) => {
  return `${label}は左辺以上右辺以下で入力してください`;
};
