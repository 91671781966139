import { Context, createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { authClient } from '@/lib/firebase';

export type AuthContextProps = {
  user: User | null | undefined;
  loading: boolean;
};

export const AuthContext: Context<AuthContextProps> = createContext<AuthContextProps>({
  user: undefined,
  loading: true,
});

export const AuthProvider = (props: PropsWithChildren) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [_, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const errorFn = (error: Error) => {
    console.error(error);
    setError(error);
  };

  useEffect(() => {
    // firebaseでエラーが起きるので一旦スルーする
    const unsubscribe = onAuthStateChanged(
      authClient(),
      async (user) => {
        setUser(user);
        setLoading(false);
      },
      errorFn,
    );
    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={{ user, loading }}>{props.children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => {
  const { user, loading } = useContext(AuthContext);
  return { user, loading };
};
