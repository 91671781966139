import { Suspense } from 'react';
import { useQuery } from 'urql';
import { Bussiness } from '@/features/company/components/detail/Bussiness';
import { Fundamental } from '@/features/company/components/detail/Fundamental';
import { Website } from '@/features/company/components/detail/Website';
import { graphql } from '@/gql';

const CompanyDetail_Query = graphql(/* GraphQL */ `
  query CompanyDetail_Query {
    me {
      id
      createdAt
      updatedAt

      name
      email

      organization {
        id
        createdAt
        updatedAt

        name
        email
        logo
        address
        numberOfEmployees
        averageAge
        capital
        ceoName
        employeeCount
        revenue
        establishmentYear

        businessDetails
        whatWeDo
        ourTeam

        siteUrl
        github
        techblogUrl
      }
    }
  }
`);

export const CompanyDetail = () => {
  const [result] = useQuery({
    query: CompanyDetail_Query,
  });

  if (!result.data) {
    throw new Promise((resolve) => resolve(null));
  }

  return (
    <div className="flex w-full flex-1 flex-col gap-4">
      <Fundamental data={result.data.me.organization} />
      <Bussiness data={result.data.me.organization} />
      <Website data={result.data.me.organization} />
    </div>
  );
};
