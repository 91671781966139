import React from 'react';
import clsx from 'clsx';
import { Text } from '@4design/for-ui';
import { HyperLink } from '@/components/ui-parts/Link/HyperLink';
import { EmptyContent } from '../empty';

type Props = {
  label?: string;
  details: {
    label: string;
    content?: JSX.Element | string | null;
  }[];
  hyperLink?: {
    label: string;
    to: string;
  };
};

export const LabeledDList = ({ label, details, hyperLink }: Props) => {
  return (
    <div className="flex flex-col gap-1">
      {label && (
        <Text className="text-shade-dark-default" size="s" weight="bold">
          {label}
        </Text>
      )}
      <dl className="border-shade-light-default rounded-1 border text-left">
        {details.map((detail, index) => {
          const firstDtClass = index === 0 ? 'rounded-tl-1' : '';
          const lastDtClass = index === details.length - 1 ? 'rounded-bl-1' : '';
          const firstDdClass = index === 0 ? 'rounded-tr-1' : '';
          const lastDdClass = index === details.length - 1 ? 'rounded-br-1' : '';
          return (
            <div
              className="border-shade-light-default first-of-type:rounded-t-1 flex border-b last-of-type:border-none"
              key={detail.label}
            >
              <dt
                className={clsx([
                  firstDtClass,
                  lastDtClass,
                  'bg-shade-light-default border-shade-light-default min-w-40 table-cell w-40 border-r px-2 py-1',
                ])}
              >
                <Text size="r" weight="inherit">
                  {detail.label}
                </Text>
              </dt>
              <dd className={clsx([firstDdClass, lastDdClass, 'border-shade-light-default table-cell px-2 py-1'])}>
                {React.isValidElement(detail.content) ? (
                  detail.content
                ) : detail.content ? (
                  <Text>{detail.content}</Text>
                ) : (
                  <EmptyContent />
                )}
              </dd>
            </div>
          );
        })}
      </dl>
      {hyperLink && <HyperLink underline label={hyperLink.label} size="r" icon to={hyperLink.to} target="_blank" />}
    </div>
  );
};
