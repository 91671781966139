import { isEmpty } from 'radash';
import { useMutation } from 'urql';
import {
  FormUpdateOrganizationBusinessInput,
  FormUpdateOrganizationFundamentalInput,
  FormUpdateOrganizationWebsiteInput,
} from '@/features/company/repository/organization.model';
import { graphql } from '@/gql';

const OnUpdateOrganization_Mutation = graphql(/* GraphQL */ `
  mutation OnUpdateOrganization_Mutation($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      data {
        __typename
        id
      }

      userErrors {
        __typename
        message
      }
    }
  }
`);

export const useOrganization = () => {
  const [, onUpdateOrganization_Mutation] = useMutation(OnUpdateOrganization_Mutation);
  const onUpdateWebsite = async (input: FormUpdateOrganizationWebsiteInput) => {
    const res = await onUpdateOrganization_Mutation({
      input,
    });

    if (res.error) {
      throw res.error;
    }

    if (res.data?.updateOrganization?.userErrors && res.data?.updateOrganization.userErrors.length > 0) {
      throw new Error(res.data?.updateOrganization.userErrors[0].message);
    }

    return;
  };

  const onUpdateBusiness = async (input: FormUpdateOrganizationBusinessInput) => {
    const res = await onUpdateOrganization_Mutation({
      input,
    });

    if (res.error) {
      throw res.error;
    }

    if (res.data?.updateOrganization?.userErrors && res.data?.updateOrganization.userErrors.length > 0) {
      throw new Error(res.data?.updateOrganization.userErrors[0].message);
    }

    return;
  };

  const onUpdateFundamental = async (input: FormUpdateOrganizationFundamentalInput) => {
    const logoImage = !isEmpty(input.logoImage) ? input.logoImage : undefined;
    const res = await onUpdateOrganization_Mutation({
      input: {
        ...input,
        logoImage,
      },
    });

    if (res.error) {
      throw res.error;
    }

    if (res.data?.updateOrganization?.userErrors && res.data?.updateOrganization.userErrors.length > 0) {
      throw new Error(res.data?.updateOrganization.userErrors[0].message);
    }

    return;
  };

  return {
    onUpdateFundamental,
    onUpdateWebsite,
    onUpdateBusiness,
  };
};
