import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Text, TextArea, TextField, useSnackbar } from '@4design/for-ui';
import { FileButton } from '@/components/ui-parts/button';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { Required } from '@/components/ui-parts/required';
import {
  FormUpdateOrganizationFundamentalInput,
  formUpdateOrganizationFundamentalSchema,
} from '@/features/company/repository/organization.model';
import { useOrganization } from '@/features/company/repository/useOrganization';
import { Organization } from '@/gql/graphql';
import { zodResolver } from '@hookform/resolvers/zod';

type Props = {
  data: Organization;
};

export const Fundamental = (props: Props) => {
  const [edit, setEdit] = useState(false);
  const onClose = () => setEdit(false);

  return (
    <>
      {edit ? (
        <Edit data={props.data} onClose={onClose} />
      ) : (
        <FieldContainer>
          <div className="relative flex w-full flex-col items-start gap-0 self-stretch bg-transparent">
            <div className="relative flex w-full flex-col items-start gap-4 self-stretch overflow-hidden rounded-lg bg-white">
              <div className="relative flex w-full items-center gap-2 self-stretch bg-transparent">
                <title className="relative flex items-center gap-0 bg-transparent py-0.5">
                  <p className="text-base font-bold leading-7 text-[#081a1a]">基本情報</p>
                </title>
                <div className="relative flex w-full flex-1 items-center justify-end gap-4 bg-transparent">
                  <Button size="medium" variant="outlined" onClick={() => setEdit(true)}>
                    編集
                  </Button>
                </div>
              </div>
              <div className="relative flex items-center gap-4 bg-transparent">
                <div className="relative flex h-20 w-20 flex-col items-center justify-center gap-0 overflow-hidden rounded-[10000px] border border-[#e2e9e9] bg-transparent">
                  <img src={props.data.logo || '/images/util/no-image.png'} className="h-full w-full" alt="logo" />
                </div>
                <h5 className="text-lg leading-8 text-[#081a1a]">{props.data.name}</h5>
              </div>
              <div className="relative flex w-full flex-col items-start gap-0 self-stretch overflow-hidden rounded border border-[#e2e9e9] bg-white">
                <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
                  <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">住所</p>
                  </dt>
                  <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">{props.data.address || '-'}</p>
                  </dd>
                </div>
                {/* <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
                  <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">上場状況</p>
                  </dt>
                  <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">未上場</p>
                  </dd>
                </div> */}
                <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
                  <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">設立年</p>
                  </dt>
                  <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                    <div className="relative flex items-center gap-1 bg-transparent">
                      <p className="text-sm leading-6 text-[#081a1a]">{props.data.establishmentYear || '-'}</p>
                      <p className="text-sm leading-6 text-[#596c6c]">年</p>
                    </div>
                  </dd>
                </div>
                <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
                  <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">売上高 (年)</p>
                  </dt>
                  <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                    <div className="relative flex items-center gap-1 bg-transparent">
                      <p className="text-sm leading-6 text-[#081a1a]">{props.data.revenue?.toLocaleString() || '-'}</p>
                      <p className="text-sm leading-6 text-[#596c6c]">万円 / 年</p>
                    </div>
                  </dd>
                </div>
                <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
                  <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">資本金</p>
                  </dt>
                  <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                    <div className="relative flex items-center gap-1 bg-transparent">
                      <p className="text-sm leading-6 text-[#081a1a]">{props.data.capital?.toLocaleString() || '-'}</p>
                      <p className="text-sm leading-6 text-[#596c6c]">万円</p>
                    </div>
                  </dd>
                </div>
                <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
                  <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">創業者</p>
                  </dt>
                  <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">{props.data.ceoName || '-'}</p>
                  </dd>
                </div>
                <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
                  <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">従業員数</p>
                  </dt>
                  <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                    <div className="relative flex items-center gap-1 bg-transparent">
                      <p className="text-sm leading-6 text-[#081a1a]">{props.data.numberOfEmployees || '-'}</p>
                      <p className="text-sm leading-6 text-[#596c6c]">人</p>
                    </div>
                  </dd>
                </div>
                <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
                  <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                    <p className="text-sm leading-6 text-[#081a1a]">平均年齢</p>
                  </dt>
                  <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                    <div className="relative flex items-center gap-1 bg-transparent">
                      <p className="text-sm leading-6 text-[#081a1a]">{props.data.averageAge || '-'}</p>
                      <p className="text-sm leading-6 text-[#596c6c]">歳</p>
                    </div>
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </FieldContainer>
      )}
    </>
  );
};

type EditProps = Props & {
  onClose: () => void;
};

const Edit = (props: EditProps) => {
  const action = useOrganization();
  const { openSnackbar } = useSnackbar();
  const methods = useForm<FormUpdateOrganizationFundamentalInput>({
    resolver: zodResolver(formUpdateOrganizationFundamentalSchema),
    defaultValues: {
      address: props.data.address,
      techblogUrl: props.data.techblogUrl,
      github: props.data.github,
      name: props.data.name,
      numberOfEmployees: props.data.numberOfEmployees,
      averageAge: props.data.averageAge,
      capital: props.data.capital,
      ceoName: props.data.ceoName,
      employeeCount: props.data.employeeCount,
      revenue: props.data.revenue,
      establishmentYear: props.data.establishmentYear,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormUpdateOrganizationFundamentalInput) => {
    await action
      .onUpdateFundamental(data)
      .then(() => {
        openSnackbar({
          message: 'サイト情報を更新しました',
          autoHide: true,
          autoHideDuration: 3000,
        });
        props.onClose();
      })
      .catch((e) => {
        openSnackbar({
          message: 'サイト情報を失敗しました',
          autoHide: true,
          autoHideDuration: 3000,
        });
        console.error(e);
      });
  };

  return (
    <FieldContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative flex w-full flex-col items-start gap-4 self-stretch overflow-hidden rounded-lg bg-white">
            <div className="relative flex w-full items-center gap-2 self-stretch bg-transparent">
              <title className="relative flex items-center gap-0 bg-transparent py-0.5">
                <p className="text-base font-bold leading-7 text-[#081a1a]">基本情報</p>
              </title>
            </div>

            <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
              <Text className="text-shade-dark-default" size="s" weight="bold">
                企業名
              </Text>

              <Text className="text-shade-dark-default" size="l">
                {props.data.name}
              </Text>
            </div>

            <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
              <div className="flex flex-row items-center gap-1">
                <Text className="text-shade-dark-default" size="s" weight="bold">
                  ロゴ
                </Text>
                <Required />
              </div>

              <div className="relative flex items-center gap-4 bg-transparent">
                <div className="relative flex h-20 w-20 flex-col items-center justify-center gap-0 overflow-hidden rounded-[10000px] border border-[#e2e9e9] bg-transparent">
                  <img src={props.data.logo || '/images/util/no-image.png'} className="h-full w-full" alt="logo" />
                </div>
                <FileButton name="logoImage">ファイルを選択</FileButton>
              </div>
            </div>

            <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      住所
                    </Text>
                    <Required />
                  </div>
                }
                size="medium"
                placeholder="住所を入力"
                error={!!errors.address?.message}
                helperText={errors.address?.message?.toString()}
                {...register('address')}
              />
            </div>
            <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      設立年
                    </Text>
                  </div>
                }
                type="number"
                size="medium"
                placeholder="例: 2000"
                error={!!errors.establishmentYear?.message}
                helperText={errors.establishmentYear?.message?.toString()}
                {...register('establishmentYear')}
              />
            </div>
            <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      売上高 (年)
                    </Text>
                  </div>
                }
                type="number"
                placeholder="例: 2000"
                size="medium"
                error={!!errors.revenue?.message}
                helperText={errors.revenue?.message?.toString()}
                {...register('revenue')}
              />
            </div>

            <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      資本金
                    </Text>
                  </div>
                }
                type="number"
                placeholder="例: 2000"
                size="medium"
                error={!!errors.capital?.message}
                helperText={errors.capital?.message?.toString()}
                {...register('capital')}
              />
            </div>

            <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      創業者
                    </Text>
                  </div>
                }
                placeholder="例: 山田太郎"
                size="medium"
                error={!!errors.ceoName?.message}
                helperText={errors.ceoName?.message?.toString()}
                {...register('ceoName')}
              />
            </div>

            <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      従業員数
                    </Text>
                  </div>
                }
                type="number"
                placeholder="例: 100"
                size="medium"
                error={!!errors.employeeCount?.message}
                helperText={errors.employeeCount?.message?.toString()}
                {...register('employeeCount')}
              />
            </div>

            <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      平均年齢
                    </Text>
                  </div>
                }
                type="number"
                placeholder="例: 32"
                size="medium"
                error={!!errors.averageAge?.message}
                helperText={errors.averageAge?.message?.toString()}
                {...register('averageAge')}
              />
            </div>

            <div className="relative flex w-full items-center justify-end gap-4 self-stretch bg-transparent">
              <Button variant="outlined" onClick={props.onClose}>
                キャンセル
              </Button>

              <Button type="submit" variant="filled" intention="primary" disabled={isSubmitting}>
                保存
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </FieldContainer>
  );
};
