import { memo } from 'react';
import { Button, Checkbox, Text, TextField } from '@4design/for-ui';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { HyperLink } from '@/components/ui-parts/Link/HyperLink';
import { Required } from '@/components/ui-parts/required';
import { PASSWORD_RESET_PATH } from '@/constants/routes';
import { FORM_VALUES, useLogin } from '../hooks/useLogin';

export const Login = memo(() => {
  const { methods, errorState, emailRegister, passwordRegister, rememberMeRegister, onSubmit } = useLogin();

  return (
    <div className="min-width[1400px] bg-shade-light-default h-screen w-screen">
      <div className="m-auto flex h-full w-[fit-content] flex-col items-center justify-center">
        <div className="w-140 border-shade-light-default flex min-h-[320px] flex-col justify-center gap-4">
          <Text size="l" weight="bold">
            ログイン
          </Text>
          {/* <Text>
            アカウントをお持ちでない方は
            <HyperLink size="r" underline label="新規会員登録してください" to={PASSWORD_RESET_PATH} />
          </Text> */}

          <FieldContainer>
            <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <Text size="s" weight="bold">
                    メールアドレスまたはアカウント名
                  </Text>
                  <Required />
                </div>
                <TextField
                  size="medium"
                  type="email"
                  {...emailRegister}
                  placeholder="example@example.com"
                  error={!!errorState?.email}
                  helperText={errorState?.email?.message}
                />
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <Text size="s" weight="bold">
                    パスワード
                  </Text>
                  <Required />
                </div>
                <TextField
                  size="medium"
                  type="password"
                  {...passwordRegister}
                  placeholder="********"
                  error={!!errorState?.password}
                  helperText={errorState?.password?.message}
                />
              </div>
              <HyperLink
                size="r"
                underline
                label="パスワードをお忘れの場合はここから再設定できます"
                to={PASSWORD_RESET_PATH}
              />
              <Checkbox {...rememberMeRegister} label={FORM_VALUES.rememberMe.label} />
              <div className="flex w-full justify-end">
                <Button loading={methods.formState.isSubmitting} type="submit" intention="primary" variant="filled">
                  ログインする
                </Button>
              </div>
            </form>
          </FieldContainer>
          <div className="flex items-center justify-center gap-4">
            <HyperLink size="r" underline label="Relance Commについて" to={PASSWORD_RESET_PATH} />
            <HyperLink size="r" underline label="運営会社" to={PASSWORD_RESET_PATH} />
            <HyperLink size="r" underline label="利用規約" to={PASSWORD_RESET_PATH} />
            <HyperLink size="r" underline label="プライバシーポリシー" to={PASSWORD_RESET_PATH} />
          </div>
        </div>
      </div>
    </div>
  );
});
