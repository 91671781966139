import { Button, Modal, ModalContent, ModalFooter, ModalHeader, Text } from '@4design/for-ui';
import { LabeledDList } from '@/components/ui-parts/dllist';
import { useOrganizationUser } from '@/features/account/repository/useOrganizationUser';
import { OrganizationUser } from '@/gql/graphql';
import { CustomModalProps } from './props';

type Props = {
  organizationUser: OrganizationUser;
};

export const DeleteModal = ({ open, onClose, organizationUser }: CustomModalProps & Props) => {
  const actions = useOrganizationUser();

  const onDelete = async () => {
    await actions.onDelete(organizationUser.id);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-140">
        <ModalHeader>アカウントの削除</ModalHeader>
        <ModalContent>
          <div className="flex flex-col gap-4 p-4">
            <Text>下記アカウントを削除します。よろしいですか？この操作は取り消せません。</Text>
            <LabeledDList
              details={[
                {
                  label: 'メールアドレス',
                  content: <Text>{organizationUser.email}</Text>,
                },
                {
                  label: 'アカウント名',
                  content: <Text>{organizationUser.name}</Text>,
                },
              ]}
            />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button onClick={onDelete} variant="filled" intention="negative" className="ml-4">
            アカウント削除
          </Button>

          <Button type="button" variant="outlined" onClick={onClose}>
            キャンセル
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};
