import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, signInWithEmailAndPassword, signOut } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  dev: {
    apiKey: 'AIzaSyALJVgeWBWXgjHg9z5N-njc1D_psDcuRiQ',
    authDomain: 'relance-comm-dev.firebaseapp.com',
    projectId: 'relance-comm-dev',
    storageBucket: 'relance-comm-dev.appspot.com',
    messagingSenderId: '655475897117',
    appId: '1:655475897117:web:21f23538ea8448e32b67f4',
    tenantId: 'enterprise-uga8f',
  },
  stg: {
    apiKey: 'AIzaSyC0wugxYu_y-zmAasmpcepf9V441rrJV0w',
    authDomain: 'relance-comm-stg.firebaseapp.com',
    projectId: 'relance-comm-stg',
    storageBucket: 'relance-comm-stg.appspot.com',
    messagingSenderId: '325244328271',
    appId: '1:325244328271:web:815f6fee097a4b1f000d3a',
    tenantId: 'enterprise-f8egr',
  },
  prd: {
    apiKey: 'AIzaSyCKuhTwazl4v3kRoS9JeH3QQpRlHUz1XmY',
    authDomain: 'relance-comm-prd.firebaseapp.com',
    projectId: 'relance-comm-prd',
    storageBucket: 'relance-comm-prd.appspot.com',
    messagingSenderId: '949990111252',
    appId: '1:949990111252:web:8a85025e10e7bf4fa1cf01',
    tenantId: 'enterprise-s71vt',
  },
};

const mode = import.meta.env.DEV ? 'dev' : import.meta.env.VITE_ENV === 'stg' ? 'stg' : 'prd';

// Initialize Firebase
const config = firebaseConfig[mode];
export const app = initializeApp(config);
export const auth = getAuth(app);
auth.tenantId = config.tenantId;

export const authClient = () => {
  const auth = getAuth(app);
  auth.tenantId = config.tenantId;
  return auth;
};

export const logout = async () => {
  await signOut(authClient());
};

export const signInWithFirebaseCustomToken = async (token: string) => {
  return signInWithCustomToken(authClient(), token);
};

export const signInWithFirebaseEmailAndPassword = async (email: string, password: string) => {
  return signInWithEmailAndPassword(authClient(), email, password);
};
