import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '@/components/firebase';
import { LOGIN_PATH } from '@/constants/routes';

export const PrivateRoute = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      navigate('/login');
    }
  }, [user, navigate]);
  // if (!user) return <Navigate to="/login" />;
  console.info('PrivateRoute', user);

  if (loading) return null;

  return user === null ? <Navigate to={LOGIN_PATH} /> : <Outlet />;
};
