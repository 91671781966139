import { z } from 'zod';
import { UpdateOrganizationInput } from '@/gql/graphql';
import { imageFile } from '@/lib/zod/file';

export type FormUpdateOrganizationWebsiteInput = Pick<UpdateOrganizationInput, 'siteUrl' | 'techblogUrl' | 'github'>;
export const formUpdateOrganizationWebsiteSchema: z.ZodSchema<FormUpdateOrganizationWebsiteInput> = z.object({
  siteUrl: z
    .string({
      required_error: 'サイトURLを入力してください',
    })
    .min(1, 'サイトURLを入力してください'),

  github: z.string({
    required_error: 'Githubを入力してください',
  }),

  techblogUrl: z.string({
    required_error: '技術ブログを入力してください',
  }),
});

export type FormUpdateOrganizationFundamentalInput = UpdateOrganizationInput;
export const formUpdateOrganizationFundamentalSchema: z.ZodSchema<FormUpdateOrganizationFundamentalInput> = z.object({
  address: z
    .string({
      required_error: '住所を入力してください',
    })
    .min(1, '住所を入力してください'),

  logoImage: imageFile,
  numberOfEmployees: z.coerce.number({
    required_error: '従業員数を入力してください',
  }),

  averageAge: z.coerce.number({
    required_error: '平均年齢を入力してください',
  }),

  capital: z.coerce.number({
    required_error: '資本金を入力してください',
  }),

  ceoName: z
    .string({
      required_error: '代表名を入力してください',
    })
    .nullable(),

  employeeCount: z.coerce.number({
    required_error: '従業員数を入力してください',
  }),

  revenue: z.coerce.number({
    required_error: '売上高を入力してください',
  }),

  establishmentYear: z.coerce.number({
    required_error: '設立年を入力してください',
  }),
});

export type FormUpdateOrganizationBusinessInput = Pick<
  UpdateOrganizationInput,
  'businessDetails' | 'whatWeDo' | 'ourTeam'
>;
export const formUpdateOrganizationBusinessSchema: z.ZodSchema<FormUpdateOrganizationBusinessInput> = z.object({
  businessDetails: z
    .string({
      required_error: '事業の内容を入力してください',
    })
    .min(1, '事業の内容を入力してください'),

  whatWeDo: z
    .string({
      required_error: '事業の目的や意義を入力してください',
    })
    .min(1, '事業の目的や意義を入力してください'),

  ourTeam: z
    .string({
      required_error: 'チームや環境を入力してください',
    })
    .min(1, 'チームや環境を入力してください'),
});
