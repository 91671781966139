import { Suspense } from 'react';
import { PageLayout } from '@/components/ui-parts/layout/PageLayout';
import { ACCOUNT_DERAIL_PAGE_TITLE } from '@/constants/pageTitle';
import { AccountEditForm } from './form/AccountEditForm';
import { UpdatePasswordForm } from './form/UpdatePasswordForm';

export const AccountDetail = () => {
  return (
    <PageLayout hiddenBreadcrumb title={ACCOUNT_DERAIL_PAGE_TITLE}>
      <Suspense fallback={<div>loading...</div>}>
        <AccountEditForm />
      </Suspense>

      <UpdatePasswordForm />
    </PageLayout>
  );
};
