import { confirmPasswordReset, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth';
import { useMutation } from 'urql';
import {
  FormPasswordResetNewPasswordInput,
  FormPasswordResetOrganizationUserInput,
} from '@/features/password-reset/repository/password-reset.model';
import { graphql } from '@/gql';
import { authClient } from '@/lib/firebase';

const OnPasswordReset_Mutation = graphql(/* GraphQL */ `
  mutation OnPasswordReset_Mutation($input: PasswordResetOrganizationUserInput!) {
    passwordResetOrganizationUser(input: $input) {
      userErrors {
        __typename
        message
      }
    }
  }
`);

export const useResetPassword = () => {
  const [, onPasswordReset_Mutation] = useMutation(OnPasswordReset_Mutation);
  const onSend = async (input: FormPasswordResetOrganizationUserInput) => {
    const res = await onPasswordReset_Mutation({
      input,
    });

    if (res.error) {
      throw res.error;
    }

    return res.data;
  };

  const onUpdatePassword = async (input: FormPasswordResetNewPasswordInput, oob: string) => {
    try {
      const email = await verifyPasswordResetCode(authClient(), oob);
      await confirmPasswordReset(authClient(), oob, input.newPassword);
      await signInWithEmailAndPassword(authClient(), email, input.newPassword);
    } catch (e: unknown) {
      console.error(e);
      throw new Error((e as Error).message);
    }
  };

  return { onSend, onUpdatePassword };
};
