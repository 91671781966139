import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InternalServerError } from '@/components/error/InternalServerError';
import { NotFound } from '@/components/error/NotFound';
import { Maintenance } from '@/components/ui-parts/error/Maintenance';
import { Layout } from '@/components/ui-parts/layout';
import {
  ACCOUNT_PATH,
  ACCOUNT_SETTING_PATH,
  COMPANY_PATH,
  LOGIN_PATH,
  MAINTENANCE_PATH,
  MESSAGE_DETAIL_PATH,
  MESSAGE_PATH,
  NEW_PROJECT_PATH,
  NOT_FOUND_PATH,
  PASSWORD_RESET_PATH,
  PASSWORD_RESET_VERIFY_PATH,
  PROJECT_DETAIL_PATH,
  PROJECT_EDIT_PATH,
  PROJECT_PATH,
  ROOT_PATH,
  SERVER_ERROR_PATH,
  TALENT_DETAIL_PATH,
  TALENT_PATH,
} from '@/constants/routes';
import AccountListPage from '@/pages/account';
import AccountDetailPage from '@/pages/account/detail';
import CompanyDetailPage from '@/pages/company/detail';
import LoginPage from '@/pages/login';
import MessagePage from '@/pages/message';
import MessageDetailPage from '@/pages/message/[id]';
import PasswordResetPage from '@/pages/password-reset';
import PasswordResetVerifyPage from '@/pages/password-reset/verify';
import ProjectsPage from '@/pages/project';
import ProjectDetailPage from '@/pages/project/detail';
import ProjectEditPage from '@/pages/project/edit';
import NewProjectPage from '@/pages/project/new';
import TalentPage from '@/pages/talent';
import TalentDetailPage from '@/pages/talent/detail';
import { PrivateRoute } from './PrivateRoute';

export const Router = () => {
  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route element={<Layout />}>
              <Route index element={<TalentPage />} />
              <Route path={ROOT_PATH} element={<TalentPage />} />

              <Route path={MESSAGE_PATH} element={<MessagePage />}>
                <Route path={MESSAGE_DETAIL_PATH} element={<MessageDetailPage />} />
              </Route>
              <Route path={TALENT_PATH} element={<TalentPage />} />
              <Route path={TALENT_DETAIL_PATH} element={<TalentDetailPage />} />
              <Route path={COMPANY_PATH} element={<CompanyDetailPage />} />

              <Route path={ACCOUNT_PATH} element={<AccountListPage />} />
              <Route path={ACCOUNT_SETTING_PATH} element={<AccountDetailPage />} />
              <Route path={PROJECT_PATH} element={<ProjectsPage />} />
              <Route path={NEW_PROJECT_PATH} element={<NewProjectPage />} />
              <Route path={PROJECT_DETAIL_PATH} element={<ProjectDetailPage />} />
              <Route path={PROJECT_EDIT_PATH} element={<ProjectEditPage />} />
            </Route>
          </Route>

          <Route path={LOGIN_PATH} element={<LoginPage />} />
          <Route path={PASSWORD_RESET_PATH} element={<PasswordResetPage />} />
          <Route path={PASSWORD_RESET_VERIFY_PATH} element={<PasswordResetVerifyPage />} />

          <Route path={MAINTENANCE_PATH} element={<Maintenance />} />
          <Route path={SERVER_ERROR_PATH} element={<InternalServerError />} />
          <Route path={NOT_FOUND_PATH} element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
