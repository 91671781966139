import * as z from 'zod';
import { CreateAgreementInput } from '@/gql/graphql';

export type FormCreateAgreementInput = Omit<CreateAgreementInput, 'projectId' | 'talentId' | 'scoutId'>;

export const schema: z.ZodSchema<FormCreateAgreementInput> = z.object({
  priceHourly: z.coerce.number(),
  workingHourPerMonth: z.coerce.number(),
  startDate: z.string(),
  initContractEndDate: z.string({
    required_error: 'プロジェクトを選択してください',
  }),
});
