import { MdOutlineMail } from 'react-icons/md';
import { Badge, BadgeProps, Button, Select, Switch, Text } from '@4design/for-ui';
import { LabeledDList } from '@/components/ui-parts/dllist';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { HyperLink } from '@/components/ui-parts/Link';
import { MESSAGE_DETAIL_PATH, PROJECT_DETAIL_PATH } from '@/constants/routes';
import { Project } from '@/features/project/repository/data';
import { Talent } from '@/gql/graphql';
import { formatDate } from '@/lib/dateformat';
import { useScoutSearchForm } from '../../hooks/useScoutSearchForm';
import { AgentScoutModal } from '../list/modal/AgentScoutModal';
import { ScoutModal } from '../list/modal/ScoutModal';

type Props = {
  talent: Talent;
};

type ScoutBadgeProps = {
  intention: BadgeProps['intention'];
  label: string;
};

const scoutStatusMapping: Record<Project['scoutStatus'], ScoutBadgeProps> = {
  restricted: {
    intention: 'shade',
    label: '成約済',
  },
  archived: {
    intention: 'subtle',
    label: 'アーカイブ済',
  },
  scouting: {
    intention: 'primary',
    label: 'スカウト中',
  },
  agentScouting: {
    intention: 'primary',
    label: '代理スカウト中',
  },
};

export const ScoutDetail = (props: Props) => {
  const { methods, onSubmit } = useScoutSearchForm();
  const scouts = props.talent.scouts;
  const scoutAgents = props.talent.scoutAgents;
  const scoutLength = scouts.length;
  const scoutAgentLength = scoutAgents.length;
  const agreements = props.talent.agreements;
  const agreementsLength = agreements.length;

  return (
    <FieldContainer>
      <form className="flex flex-col gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
        <Text size="xr" weight="bold">
          スカウト
        </Text>
        <div className="flex flex-row gap-4">
          {/* <Switch {...methods.register('isConsidering')} label="検討中" /> */}
          <ScoutModal talent={props.talent} />
          <AgentScoutModal talent={props.talent} />
        </div>
        <Text size="xr" weight="bold">
          スカウトの状況
        </Text>
        <LabeledDList
          details={[
            {
              label: 'スカウトした案件',
              content: <Text size="r">{scoutLength}件</Text>,
            },
            {
              label: '代理スカウトした案件',
              content: <Text size="r">{scoutAgentLength}件</Text>,
            },
            {
              label: '成約済の案件',
              content: <Text size="r">{agreementsLength}件</Text>,
            },
          ]}
        />
        <div className="flex flex-row justify-between">
          <Text size="xr" weight="bold" className="w-1/2">
            スカウトした案件
          </Text>
          {/* <Select
            className="w-1/2"
            size="medium"
            {...methods.register('sort')}
            options={[
              {
                label: '新着順',
                inputValue: 'new',
              },
              {
                label: '古い順',
                inputValue: 'old',
              },
            ]}
          /> */}
        </div>
      </form>

      {scouts.map((scout) => {
        return (
          <FieldContainer key={scout.id}>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center gap-2">
                {/* <Badge
                  label={scoutStatusMapping[project.scoutStatus].label}
                  intention={scoutStatusMapping[project.scoutStatus].intention}
                  variant="outlined"
                /> */}
                <Text size="s" className="text-shade-medium-default">
                  {formatDate(scout.createdAt)}にスカウト
                </Text>
              </div>
              <HyperLink
                to={PROJECT_DETAIL_PATH.replace(':projectId', scout.project.id)}
                label={scout.project.name}
                underline
                size="r"
              />
              <Button as="a" href={MESSAGE_DETAIL_PATH.replace(':messageId', scout.id)} size="medium">
                <MdOutlineMail className="icon-shade-medium-default" />
                メッセージスレッドを開く
              </Button>
            </div>
          </FieldContainer>
        );
      })}
    </FieldContainer>
  );
};
