import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Text, TextArea, TextField, useSnackbar } from '@4design/for-ui';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { Required } from '@/components/ui-parts/required';
import {
  FormUpdateOrganizationBusinessInput,
  formUpdateOrganizationBusinessSchema,
} from '@/features/company/repository/organization.model';
import { useOrganization } from '@/features/company/repository/useOrganization';
import { Organization } from '@/gql/graphql';
import { zodResolver } from '@hookform/resolvers/zod';

type Props = {
  data: Organization;
};

export const Bussiness = (props: Props) => {
  const [edit, setEdit] = useState(false);
  const onClose = () => setEdit(false);

  return (
    <>
      {edit ? (
        <Edit data={props.data} onClose={onClose} />
      ) : (
        <FieldContainer>
          <div className="relative flex w-full flex-col items-start gap-4 self-stretch overflow-hidden rounded-lg bg-white">
            <div className="relative flex w-full items-center gap-2 self-stretch bg-transparent">
              <title className="relative flex items-center gap-0 bg-transparent py-0.5">
                <p className="text-base font-bold leading-7 text-[#081a1a]">事業概要</p>
              </title>

              <div className="relative flex w-full flex-1 items-center justify-end gap-4 bg-transparent">
                <Button size="medium" variant="outlined" onClick={() => setEdit(true)}>
                  編集
                </Button>
              </div>
            </div>
          </div>

          <div className="relative flex w-full flex-col items-start gap-0 self-stretch overflow-hidden rounded border border-[#e2e9e9] bg-white">
            <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
              <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                <p className="text-sm leading-6 text-[#081a1a]">事業の内容</p>
              </dt>
              <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                <p className="text-sm leading-6 text-[#081a1a]">{props.data.businessDetails}</p>
              </dd>
            </div>
            <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
              <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                <p className="text-sm leading-6 text-[#081a1a]">事業の目的や意義</p>
              </dt>
              <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                <p className="text-sm leading-6 text-[#081a1a]">{props.data.whatWeDo}</p>
              </dd>
            </div>
            <div className="relative flex w-full items-center gap-0 self-stretch overflow-hidden border-x-0 border-b border-t-0 border-[#e2e9e9] bg-transparent">
              <dt className="relative flex h-full w-40 items-start gap-2 self-stretch border-y-0 border-l-0 border-r border-[#e2e9e9] bg-[#f7f8f8] px-2 py-1">
                <p className="text-sm leading-6 text-[#081a1a]">チームや環境</p>
              </dt>

              <dd className="relative flex h-full w-full flex-1 items-start gap-2 self-stretch bg-white px-2 py-1">
                <div className="text-sm leading-6 text-[#081a1a]">{props.data.ourTeam}</div>
              </dd>
            </div>
          </div>
        </FieldContainer>
      )}
    </>
  );
};

type EditProps = Props & {
  onClose: () => void;
};

const Edit = (props: EditProps) => {
  const action = useOrganization();
  const { openSnackbar } = useSnackbar();
  const methods = useForm<FormUpdateOrganizationBusinessInput>({
    resolver: zodResolver(formUpdateOrganizationBusinessSchema),
    defaultValues: {
      businessDetails: props.data.businessDetails || '',
      whatWeDo: props.data.whatWeDo || '',
      ourTeam: props.data.ourTeam || '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormUpdateOrganizationBusinessInput) => {
    await action
      .onUpdateBusiness(data)
      .then(() => {
        openSnackbar({
          message: 'サイト情報を更新しました',
          autoHide: true,
          autoHideDuration: 3000,
        });
        props.onClose();
      })
      .catch((e) => {
        openSnackbar({
          message: 'サイト情報を失敗しました',
          autoHide: true,
          autoHideDuration: 3000,
        });
        console.error(e);
      });
  };
  return (
    <FieldContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative flex w-full flex-col items-start gap-4 self-stretch overflow-hidden rounded-lg bg-white">
          <div className="relative flex w-full items-center gap-2 self-stretch bg-transparent">
            <title className="relative flex items-center gap-0 bg-transparent py-0.5">
              <p className="text-base font-bold leading-7 text-[#081a1a]">事業概要</p>
            </title>
          </div>
          <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
            <TextArea
              label={
                <div className="flex flex-row items-center gap-1">
                  <Text className="text-shade-dark-default" size="s" weight="bold">
                    事業の内容
                  </Text>
                  <Required />
                </div>
              }
              size="medium"
              placeholder="記述してください"
              minRows={5}
              error={!!errors.businessDetails?.message}
              helperText={errors.businessDetails?.message}
              {...methods.register('businessDetails')}
            />
          </div>
          <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
            <TextArea
              label={
                <div className="flex flex-row items-center gap-1">
                  <Text className="text-shade-dark-default" size="s" weight="bold">
                    事業の目的や意義
                  </Text>
                  <Required />
                </div>
              }
              size="medium"
              placeholder="記述してください"
              minRows={5}
              error={!!errors.whatWeDo?.message}
              helperText={errors.whatWeDo?.message}
              {...methods.register('whatWeDo')}
            />
          </div>
          <div className="relative flex w-full flex-col items-start gap-1 self-stretch bg-transparent">
            <TextArea
              label={
                <div className="flex flex-row items-center gap-1">
                  <Text className="text-shade-dark-default" size="s" weight="bold">
                    チームや環境
                  </Text>
                  <Required />
                </div>
              }
              size="medium"
              placeholder="チームや環境"
              minRows={5}
              error={!!errors.ourTeam?.message}
              helperText={errors.ourTeam?.message}
              {...methods.register('ourTeam')}
            />
          </div>

          <div className="relative flex w-full items-center justify-end gap-4 self-stretch bg-transparent">
            <Button variant="outlined" onClick={props.onClose}>
              キャンセル
            </Button>

            <Button type="submit" variant="filled" intention="primary" disabled={isSubmitting}>
              保存
            </Button>
          </div>
        </div>
      </form>
    </FieldContainer>
  );
};
