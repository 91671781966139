import { PageLayout } from '@/components/ui-parts/layout/PageLayout';
import { COMPANY_PAGE_TITLE } from '@/constants/pageTitle';
import { CompanyDetail } from '@/features/company/components/detail';

const CompanyDetailPage = () => (
  <PageLayout title={COMPANY_PAGE_TITLE}>
    <CompanyDetail />
  </PageLayout>
);

export default CompanyDetailPage;
