import { Link, useLocation, useParams } from 'react-router-dom';
import { Text } from '@4design/for-ui';
import {
  COMPANY_EDIT_PAGE_TITLE,
  PROJECT_DETAIL_PAGE_TITLE,
  PROJECT_EDIT_PAGE_TITLE,
  PROJECT_LIST_PAGE_TITLE,
  PROJECT_NEW_PAGE_TITLE,
  TALENT_DETAIL_PAGE_TITLE,
} from '@/constants/pageTitle';
import {
  COMPANY_EDIT_PATH,
  NEW_PROJECT_PATH,
  PROJECT_DETAIL_PATH,
  PROJECT_EDIT_PATH,
  PROJECT_PATH,
  TALENT_DETAIL_PATH,
} from '@/constants/routes';
import { links } from '../../../layout/header';

export const HeaderBreadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const params = useParams<{
    projectId: string;
    userId: string;
  }>();

  // Pathとタイトルのマッピング
  const pathToTitleMap = {
    [PROJECT_DETAIL_PATH.replace(':projectId', params.projectId || '')]: PROJECT_DETAIL_PAGE_TITLE,
    [PROJECT_EDIT_PATH.replace(':projectId', params.projectId || '')]: PROJECT_EDIT_PAGE_TITLE,
    [PROJECT_PATH]: PROJECT_LIST_PAGE_TITLE,
    [NEW_PROJECT_PATH]: PROJECT_NEW_PAGE_TITLE,
    [COMPANY_EDIT_PATH]: COMPANY_EDIT_PAGE_TITLE,
    [TALENT_DETAIL_PATH.replace(':userId', params.userId || '')]: TALENT_DETAIL_PAGE_TITLE,
  };

  return (
    <div className="flex flex-row items-center gap-2">
      {pathnames.map((_, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const domainName = pathToTitleMap[to] || links.find((link) => link.to === to)?.label || null;
        return (
          <Text key={to} size="r" className="text-primary-dark-default">
            <Link to={to}>
              <Text size="r" className="text-primary-dark-default underline">
                {domainName}
              </Text>
            </Link>
            {' / '}
          </Text>
        );
      })}
    </div>
  );
};
