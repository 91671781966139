import { Text } from '@4design/for-ui';

type PairBadgeProps = {
  primaryLabel: string;
  secondaryLabel: string;
};

export const PairBadge = (props: PairBadgeProps) => {
  return (
    <div className="rounded-1 border-shade-light-default bg-shade-white-default flex items-center border">
      <span className="flex items-center px-2">
        <Text size="r">{props.primaryLabel}</Text>
      </span>
      <span className="bg-shade-light-default flex items-center px-2">
        <Text size="r" className="text-shade-medium-default">
          {props.secondaryLabel}
        </Text>
      </span>
    </div>
  );
};
