import { useSnackbar } from '@4design/for-ui';
import { UserError } from '@/constants/error';

export const useResultHandler = () => {
  const { openSnackbar } = useSnackbar();

  const onError = (e: unknown, snackbarMessage: string, callback?: () => void) => {
    if (e instanceof UserError) {
      openSnackbar({
        message: e.message,
        autoHide: true,
        autoHideDuration: 3000,
      });
    } else {
      openSnackbar({
        message: snackbarMessage,
        autoHide: true,
        autoHideDuration: 3000,
      });
    }
    if (callback) {
      callback();
    }
  };

  const onSuccess = (snackbarMessage: string, callback?: () => void) => {
    openSnackbar({
      message: snackbarMessage,
      autoHide: true,
      autoHideDuration: 3000,
    });
    if (callback) {
      callback();
    }
  };

  return { onError, onSuccess };
};
