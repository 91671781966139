import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { SelectOption } from '@4design/for-ui';
import { zodResolver } from '@hookform/resolvers/zod';

type ScoutSearchFormValues = {
  // 検討中
  isConsidering: boolean;
  sort: SelectOption;
};

const schema: z.ZodSchema<ScoutSearchFormValues> = z.object({
  isConsidering: z.boolean(),
  sort: z.object({
    inputValue: z.string(),
    label: z.string(),
  }),
});

const defaultValues: ScoutSearchFormValues = {
  isConsidering: false,
  sort: {
    inputValue: '',
    label: '',
  },
};

export const useScoutSearchForm = () => {
  const methods = useForm<ScoutSearchFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues,
  });

  const errors = methods.formState.errors;

  const onSubmit = (data: ScoutSearchFormValues) => {
    console.info(data);
  };

  return {
    methods,
    errors,
    onSubmit,
  };
};
