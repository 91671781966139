import { MdOutlineDelete, MdOutlineMoreVert } from 'react-icons/md';
import { Button, Menu, MenuItem, Text } from '@4design/for-ui';
import { useModal } from '@/components/hooks/modal/useModal';
import { OrganizationUser } from '@/gql/graphql';
import { DeleteModal } from '../modals';

type Props = {
  organizationUser: OrganizationUser;
  currentAccountId: OrganizationUser['id'];
};

export const AccountMenu = (props: Props) => {
  const [openDeleteModal, openDeleteModalActions] = useModal();

  return (
    <>
      <Menu
        keepMounted
        TriggerComponent={
          <Button variant="text" size="small">
            <MdOutlineMoreVert />
          </Button>
        }
      >
        <MenuItem onClick={openDeleteModalActions.open} disabled={props.currentAccountId === props.organizationUser.id}>
          <div className="flex w-full items-center gap-2">
            <MdOutlineDelete className="icon-negative-medium-default" />
            <Text>削除</Text>
          </div>
        </MenuItem>

        {/* <ReInviteModal organizationUser={props.organizationUser} /> */}
        {/* <MenuItem onClick={openDisabledInviteModalActions.open}>
          <div className="flex w-full items-center gap-2">
            <MdOutlinePersonAddDisabled className="icon-negative-medium-default" />
            <Text>招待を取り消す</Text>
          </div>
        </MenuItem> */}
      </Menu>

      {/* <DisabledInviteModal
        account={account}
        open={openDisabledInviteModal}
        onClose={openDisabledInviteModalActions.close}
      /> */}
      <DeleteModal
        organizationUser={props.organizationUser}
        open={openDeleteModal}
        onClose={openDeleteModalActions.close}
      />
    </>
  );
};
