import { useMutation } from 'urql';
import { UserError } from '@/constants/error';
import { graphql } from '@/gql';

const OnCreateScout_Mutation = graphql(/* GraphQL */ `
  mutation OnCreateScout_Mutation($input: CreateScoutInput!) {
    createScout(input: $input) {
      data {
        __typename
        id

        talent {
          __typename
          id
        }
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);

const OnCreateScoutAgent_Mutation = graphql(/* GraphQL */ `
  mutation OnCreateScoutAgent_Mutation($input: CreateScoutAgentInput!) {
    createScoutAgent(input: $input) {
      data {
        __typename
        id

        talent {
          __typename
          id
        }
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);

export const useScout = () => {
  const [, onCreateUser] = useMutation(OnCreateScout_Mutation);
  const onCreate = async (talentId: string, projectId: string) => {
    const res = await onCreateUser({
      input: {
        talentId,
        projectId,
      },
    });

    if (res.error) {
      throw res.error;
    }

    if (res.data?.createScout.userErrors && res.data?.createScout.userErrors.length > 0) {
      throw new UserError(res.data?.createScout.userErrors[0].message);
    }

    return res.data?.createScout.data;
  };

  const [, onCreateScoutAgent_Mutation] = useMutation(OnCreateScoutAgent_Mutation);
  const onCreateAgent = async (talentId: string, projectId: string) => {
    const res = await onCreateScoutAgent_Mutation({
      input: {
        talentId,
        projectId,
      },
    });

    if (res.error) {
      throw res.error;
    }

    if (res.data?.createScoutAgent.userErrors && res.data?.createScoutAgent.userErrors.length > 0) {
      throw new UserError(res.data?.createScoutAgent.userErrors[0].message);
    }

    return res.data?.createScoutAgent.data;
  };

  return { onCreate, onCreateAgent };
};
