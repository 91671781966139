import { useForm } from 'react-hook-form';
import { MdArchive, MdCheck, MdMoreVert } from 'react-icons/md';
import {
  Button,
  Menu,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  TextField,
  useSnackbar,
} from '@4design/for-ui';
import { useModal } from '@/components/hooks/modal/useModal';
import { Required } from '@/components/ui-parts/required';
import { FormCreateAgreementInput, schema } from '@/features/agreement/repository/agreement.model';
import { useAgreement } from '@/features/agreement/repository/useAgreement';
import { zodResolver } from '@hookform/resolvers/zod';

type Props = {
  projectId: string;
  talentId: string;
  scoutId: string;
};

export const AgreementFormModal = (props: Props) => {
  const [openModal, openModalActions] = useModal();
  const actions = useAgreement();

  const methods = useForm<FormCreateAgreementInput>({
    resolver: zodResolver(schema),
  });

  const {
    formState: { errors },
  } = methods;

  const { openSnackbar } = useSnackbar();

  const onSubmit = async (input: FormCreateAgreementInput) => {
    try {
      await actions.onCreate(props.projectId, props.talentId, props.scoutId, input);
      openSnackbar({
        message: '成約完了しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
      openModalActions.close();
    } catch (e) {
      console.error(e);

      openSnackbar({
        message: '成約に失敗しました',
        autoHide: true,
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
      <Button size="medium" type="button" onClick={openModalActions.open}>
        <MdCheck size={16} />
        成約を完了する
      </Button>

      <Modal open={openModal} onClose={openModalActions.close}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader>成約する</ModalHeader>
          <ModalContent>
            <div className="flex w-[528px] flex-col gap-4">
              <Text className="text-shade-dark-default" size="r">
                成約時に締結した条件を以下に入力してください。
              </Text>
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      時給
                    </Text>
                    <Required />
                  </div>
                }
                type="number"
                size="medium"
                placeholder="0"
                suffix="円"
                error={!!errors.priceHourly?.message}
                helperText={errors.priceHourly?.message}
                {...methods.register('priceHourly')}
              />
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      月の稼働予定時間
                    </Text>
                    <Required />
                  </div>
                }
                type="number"
                size="medium"
                placeholder="0"
                suffix="時間"
                error={!!errors.workingHourPerMonth?.message}
                helperText={errors.workingHourPerMonth?.message}
                {...methods.register('workingHourPerMonth')}
              />
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      稼働開始予定日
                    </Text>
                    <Required />
                  </div>
                }
                {...methods.register('startDate')}
                size="medium"
                type="date"
                placeholder="2024/01/01"
                error={!!errors.startDate?.message}
                helperText={errors.startDate?.message?.toString()}
              />
              <TextField
                label={
                  <div className="flex flex-row items-center gap-1">
                    <Text className="text-shade-dark-default" size="s" weight="bold">
                      初回契約終了日
                    </Text>
                    <Required />
                  </div>
                }
                size="medium"
                type="date"
                placeholder="2024/01/01"
                error={!!errors.initContractEndDate?.message}
                helperText={errors.initContractEndDate?.message?.toString()}
                {...methods.register('initContractEndDate')}
              />
            </div>
          </ModalContent>

          <ModalFooter>
            <div className="flex flex-row justify-end gap-4">
              <Button type="button" onClick={openModalActions.close}>
                キャンセル
              </Button>

              <Button type="submit" variant="filled" intention="primary">
                成約を完了
              </Button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};
