import { signInWithEmailAndPassword } from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { ROOT_PATH } from '@/constants/routes';
import { notMatchMessage, requiredMessage } from '@/constants/validation';
import { auth } from '@/lib/firebase';
import { zodResolver } from '@hookform/resolvers/zod';

const EMAIL = {
  name: 'email',
  label: 'メールアドレス',
} as const;

const PASSWORD = {
  name: 'password',
  label: 'パスワード',
} as const;

const REMEMBER_ME = {
  name: 'rememberMe',
  label: 'ログイン状態を保持する',
} as const;

export const FORM_VALUES = {
  [EMAIL.name]: EMAIL,
  [PASSWORD.name]: PASSWORD,
  [REMEMBER_ME.name]: REMEMBER_ME,
} as const;

const { email, password, rememberMe } = FORM_VALUES;

const validationSchema: z.ZodSchema<FormLoginInput> = z.object({
  email: z
    .string({
      required_error: requiredMessage(email.label),
    })
    .email({
      message: notMatchMessage(email.label),
    }),
  password: z.string({
    required_error: requiredMessage(password.label),
  }),
  rememberMe: z.boolean(),
});

type FormLoginInput = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export const useLogin = () => {
  const navigate = useNavigate();
  const methods = useForm<FormLoginInput>({
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = async (formData: FormLoginInput) => {
    try {
      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      navigate(ROOT_PATH);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
    }
  };

  const emailRegister = methods.register(email.name);

  const passwordRegister = methods.register(password.name);

  const rememberMeRegister = methods.register(rememberMe.name);

  const errorState = methods.formState.errors;

  return {
    methods,
    emailRegister,
    passwordRegister,
    rememberMeRegister,
    errorState,
    onSubmit,
  };
};
