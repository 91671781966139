import { TextField } from '@4design/for-ui';
import { useAccountSearchForm } from '@/features/account/hooks/useAccountSearchForm';
import { InviteModal } from '../modals';

export const AccountSearchForm = () => {
  const { methods, onSubmit } = useAccountSearchForm();

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className="flex w-full items-center justify-between">
      <div className="flex w-full flex-row items-center gap-4">
        <TextField
          {...methods.register('text')}
          placeholder="アカウントを検索"
          size="medium"
          type="search"
          className="w-60"
        />
      </div>

      <InviteModal />
    </form>
  );
};
