// src/ErrorBoundary.tsx
import React, { ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { InternalServerError } from './InternalServerError';
import { NotFound } from './NotFound';

export class HttpError extends Error {
  statusCode: number;

  constructor(statusCode: number, message?: string) {
    super(message);
    this.statusCode = statusCode;
    this.name = 'HttpError';
  }
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorCode: number | null;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      errorCode: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    console.error(error);

    if (error instanceof HttpError) {
      console.error(error);
      return { hasError: true, errorCode: error.statusCode };
    }
    return { hasError: true, errorCode: null };
  }

  resetErrorState = () => {
    this.setState({ hasError: false, errorCode: null });
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.withScope((scope) => {
      scope.setExtras({
        errorInfo,
      });
      Sentry.captureException(error);
    });
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    // If the children change, reset the error state
    if (this.props.children !== prevProps.children) {
      this.resetErrorState();
    }
  }

  render(): ReactNode {
    console.info(this.state);
    if (this.state.hasError) {
      switch (this.state.errorCode) {
        case 401:
          return <h1>Unauthorized: Please login again.</h1>;
        case 403:
          return <h1>Forbidden: You do not have permission to access.</h1>;
        case 404:
          return <NotFound />;
        case 500:
          return <InternalServerError />;
        default:
          return <InternalServerError />;
      }
    }
    return this.props.children;
  }
}

// eslint-disable-next-line import/no-default-export
export default ErrorBoundary;
