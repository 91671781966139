import * as z from 'zod';
import { SelectOption } from '@4design/for-ui';
import { CreateScoutInput, ProjectTaxTypeEnum } from '@/gql/graphql';

export type FormCreateScoutInput = Omit<CreateScoutInput, 'talentId'>;

export const schema: z.ZodSchema<FormCreateScoutInput> = z.object({
  projectId: z.string({
    required_error: 'プロジェクトを選択してください',
  }),
});

export const TaxTypeLabel = {
  [ProjectTaxTypeEnum.TaxIncluded]: '内税',
  [ProjectTaxTypeEnum.TaxExcluded]: '外税',
};

export const taxTypeOptions: SelectOption[] = Object.entries(TaxTypeLabel).map(([value, label]) => ({
  inputValue: value,
  label,
}));
