import { HTMLAttributeAnchorTarget } from 'react';
import clsx from 'clsx';
import { MdOpenInNew } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Text } from '@4design/for-ui';

type Props = {
  underline?: boolean;
  to: string;
  label: string;
  size: 's' | 'xs' | 'r' | 'xr' | 'l' | 'xl';
  icon?: boolean;
  target?: HTMLAttributeAnchorTarget;
  bold?: boolean;
};

export const HyperLink = ({ underline = false, to, label, size, icon, target, bold }: Props) => {
  return (
    <Link to={to} target={target} className="inline-flex flex-row items-center gap-1">
      <Text
        size={size}
        weight={bold ? 'bold' : 'inherit'}
        className={clsx([underline ? 'underline' : '', 'text-informative-dark-default'])}
      >
        {label}
      </Text>
      {icon && <MdOpenInNew size={16} className="text-informative-dark-default" />}
    </Link>
  );
};
