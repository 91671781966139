import { useMutation } from 'urql';
import { FormSendMessageInput } from '@/features/message/repository/message.model';
import { graphql } from '@/gql';

const OnSendScoutMessage_Mutation = graphql(/* GraphQL */ `
  mutation OnSendScoutMessage_Mutation($input: SendScoutMessageInput!) {
    sendScoutMessage(input: $input) {
      __typename
      data {
        __typename
        id
      }
    }
  }
`);

const OnReadScoutMessage_Mutation = graphql(/* GraphQL */ `
  mutation OnReadScoutMessage_Mutation($input: ReadScoutInput!) {
    readScout(input: $input) {
      __typename
      data {
        __typename
        id
      }
    }
  }
`);

export const useMessage = () => {
  const [, onSendScoutMessage] = useMutation(OnSendScoutMessage_Mutation);
  const onSend = async (scoutId: string, input: FormSendMessageInput) => {
    const res = await onSendScoutMessage({
      input: {
        ...input,
        scoutId,
      },
    });

    if (res.error) {
      throw res.error;
    }

    return;
  };

  const [, onReadScoutMessage_Mutation] = useMutation(OnReadScoutMessage_Mutation);
  const onRead = async (scoutId: string) => {
    const res = await onReadScoutMessage_Mutation({
      input: {
        scoutId,
      },
    });

    if (res.error) {
      throw res.error;
    }

    return;
  };

  return { onSend, onRead };
};
