export const ROOT_PATH = '/' as const;

export const DASHBOARD_PATH = '/dashboard' as const;

export const TEMPLATE_PATH = '/template' as const;
export const NEW_TEMPLATE_PATH = '/template/new' as const;

export const TALENT_PATH = '/talent' as const;
export const TALENT_DETAIL_PATH = '/talent/:userId' as const;

export const ENTERPRISE_PATH = '/enterprise' as const;
export const NEW_ENTERPRISE_PATH = '/enterprise/new' as const;

export const COMPANY_PATH = '/company' as const;
export const COMPANY_REMOTE_PATH = '/company/remote' as const;
export const COMPANY_EDIT_PATH = '/company' as const;

export const ACCOUNT_PATH = '/account' as const;
export const ACCOUNT_SETTING_PATH = '/account/setting' as const;

export const SCOUT_PATH = '/scout' as const;
export const NEW_SCOUT_PATH = '/scout/new' as const;

export const MESSAGE_PATH = '/message' as const;
export const MESSAGE_DETAIL_PATH = '/message/:messageId' as const;

export const PROJECT_PATH = '/project' as const;
export const NEW_PROJECT_PATH = '/project/new' as const;
export const PROJECT_DETAIL_PATH = '/project/:projectId' as const;
export const PROJECT_EDIT_PATH = '/project/:projectId/edit' as const;

export const SETTING_PATH = '/setting' as const;

export const LOGIN_PATH = '/login' as const;

export const SIGN_UP_PATH = '/signup' as const;

export const PASSWORD_RESET_PATH = '/password-reset' as const;
export const PASSWORD_RESET_VERIFY_PATH = '/password-reset/verify' as const;

export const MAINTENANCE_PATH = '/maintenance' as const;
export const SERVER_ERROR_PATH = '/server-error' as const;
export const NOT_FOUND_PATH = '*' as const;
