import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'urql';
import { Select, SelectOption, Text } from '@4design/for-ui';
import { graphql } from '@/gql';

type Props = {
  name: string;
  placeholder?: string;
};

export const SelectEngineerSkillMiddlewares_Query = graphql(/* GraphQL */ `
  query SelectEngineerSkillMiddlewares_Query {
    skills: engineerSkillMiddlewares {
      id
      name
    }
  }
`);

export const SelectEngineerSkillMiddlewares = (props: Props) => {
  const methods = useFormContext();

  const [result] = useQuery({
    query: SelectEngineerSkillMiddlewares_Query,
  });

  if (!result.data) {
    throw new Error('data is undefined');
  }

  const options: SelectOption[] = result.data.skills.map((item) => ({
    label: item.name,
    inputValue: item.id,
  }));

  return (
    <Controller
      name={props.name}
      control={methods.control}
      render={({ field: { name, value, onChange, ...rest } }) => {
        return (
          <Select
            multiple
            label={
              <div className="flex flex-row gap-1">
                <Text size="s" weight="bold">
                  ミドルウェア
                </Text>
              </div>
            }
            value={value}
            name={name}
            placeholder={props.placeholder}
            size="medium"
            options={options}
            onChange={(e, options) => {
              onChange(options);
            }}
            {...rest}
          />
        );
      }}
    />
  );
};
