import { MdChevronLeft } from 'react-icons/md';
import { Button, Text } from '@4design/for-ui';
import { FieldContainer } from '../FieldContainer';
import { PageLayout } from '../layout/PageLayout';
import { PageTitle } from '../layout/PageTitle';
import { HyperLink } from '../Link';

export const InternalServerError = () => (
  <PageLayout hiddenBreadcrumb title="不明なエラー">
    <FieldContainer>
      <PageTitle title="不明なエラーが発生" />
      <Text size="r">
        申し訳ありません。数分程度、時間をあけてから再度お試しください。
        <br />
        解決しない場合はお手数ですが
        <HyperLink label="運営までお問い合わせ" underline to="/" size="r" />
        ください。
      </Text>
      <Button size="medium">
        <MdChevronLeft />
        戻る
      </Button>
    </FieldContainer>
  </PageLayout>
);
