type Props = {
  src: string | File;
};

export const HeroImage = ({ src }: Props) => {
  return (
    <img
      src={src instanceof File ? URL.createObjectURL(src) : src}
      alt="カバー画像"
      width={1520}
      height={630}
      className="h-[630px] w-[1520px] object-cover"
    />
  );
};
