import { useMutation } from 'urql';
import { UserError } from '@/constants/error';
import { graphql } from '@/gql';

const OnCreateTalentPool_Mutation = graphql(/* GraphQL */ `
  mutation OnCreateTalentPool_Mutation($input: CreateTalentPoolInput!) {
    createTalentPool(input: $input) {
      data {
        __typename
        id
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);

const OnDeleteTalentPool_Mutation = graphql(/* GraphQL */ `
  mutation OnDeleteTalentPool_Mutation($input: DeleteTalentPoolInput!) {
    deleteTalentPool(input: $input) {
      data {
        __typename
        id
      }
      userErrors {
        __typename
        message
      }
    }
  }
`);

export const useTalentPool = () => {
  const [, onCreateTalentPoolMutation] = useMutation(OnCreateTalentPool_Mutation);
  const onCreate = async (talentId: string) => {
    const res = await onCreateTalentPoolMutation({
      input: {
        talentId,
      },
    });

    if (res.error) {
      throw res.error;
    }

    if (res.data?.createTalentPool.userErrors && res.data?.createTalentPool.userErrors.length > 0) {
      throw new UserError(res.data?.createTalentPool.userErrors[0].message);
    }

    return;
  };

  const [, onDeleteTalentPoolMutation] = useMutation(OnDeleteTalentPool_Mutation);
  const onDelete = async (talentId: string) => {
    const res = await onDeleteTalentPoolMutation({
      input: {
        talentId,
      },
    });

    if (res.error) {
      throw res.error;
    }

    if (res.data?.deleteTalentPool.userErrors && res.data?.deleteTalentPool.userErrors.length > 0) {
      throw new UserError(res.data?.deleteTalentPool.userErrors[0].message);
    }

    return;
  };

  return { onCreate, onDelete };
};
