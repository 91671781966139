import * as z from 'zod';
import { ProjectTaxTypeEnum } from '@/gql/graphql';

const optionSchema = z.object({
  label: z.string(),
  inputValue: z.string(),
});

const numberSchema = z
  .string()
  .nullable()
  .optional()
  .refine((value) => {
    if (value === '') {
      return true;
    }
    return !isNaN(Number(value));
  }, '数値を入力してください');

export const schema = z.object({
  name: z.string().min(1, '案件名を入力してください'),
  details: z.string().nullable().optional(),
  expectedDuration: numberSchema,
  // interviewCount: numberSchema,
  paymentDate: z.string().nullable().optional(),
  priceHourly: z
    .object({
      max: numberSchema,
      min: numberSchema,
    })
    .refine(
      (value) => {
        // 下限が上限より大きい場合はエラー
        if (value['min'] && value['max']) {
          return Number(value['min']) <= Number(value['max']);
        }
        return true;
      },
      {
        message: '単価(時)は下限より上限が大きい値を入力してください',
      },
    ),
  priceMonthly: z
    .object({
      max: numberSchema,
      min: numberSchema,
    })
    .refine(
      (value) => {
        // 下限が上限より大きい場合はエラー
        if (value['min'] && value['max']) {
          return Number(value['min']) <= Number(value['max']);
        }
        return true;
      },
      {
        message: '単価(月)は下限より上限が大きい値を入力してください',
      },
    ),
  priceSettlementHour: z
    .object({
      from: numberSchema,
      to: numberSchema,
    })
    .refine(
      (value) => {
        // 下限が上限より大きい場合はエラー
        if (value['from'] && value['to']) {
          return Number(value['from']) <= Number(value['to']);
        }
        return true;
      },
      {
        message: '精算幅(月)は下限より上限が大きい値を入力してください',
      },
    ),
  priceTaxType: z.nativeEnum(ProjectTaxTypeEnum).nullable().optional(),
  privateName: z.string().nullable().optional(),
  requiredSkill: z.string().nullable().optional(),
  startDate: z.string().nullable().optional(),
  targetAge: z
    .object({
      max: numberSchema,
      min: numberSchema,
    })
    .refine(
      (value) => {
        // 下限が上限より大きい場合はエラー
        if (value['min'] && value['max']) {
          return Number(value['min']) <= Number(value['max']);
        }
        return true;
      },
      {
        message: '年齢は下限より上限が大きい値を入力してください',
      },
    ),
  teamComposition: z.string().nullable().optional(),
  weeklyWorkingDay: z
    .object({
      max: numberSchema,
      min: numberSchema,
    })
    .refine(
      (value) => {
        // 下限が上限より大きい場合はエラー
        if (value['min'] && value['max']) {
          return Number(value['min']) <= Number(value['max']);
        }
        return true;
      },
      {
        message: '週の稼働日は下限より上限が大きい値を入力してください',
      },
    ),
  welcomeSkill: z.string().nullable().optional(),
  engineerPositionIds: z.array(optionSchema).optional(),
  engineerSkillLanguageIds: z.array(optionSchema).optional(),
  engineerSkillFrameworkIds: z.array(optionSchema).optional(),
  engineerSkillMiddlewareIds: z.array(optionSchema).optional(),
  engineerSkillInfraIds: z.array(optionSchema).optional(),
});

export type FormCreateProjectInput = z.infer<typeof schema>;
