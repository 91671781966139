import clsx from 'clsx';
import { signOut } from 'firebase/auth';
import {
  MdAccountCircle,
  MdBusiness,
  MdInbox,
  MdOutlineLogout,
  MdOutlineMarkunread,
  MdOutlineMenu,
  MdOutlinePeople,
  MdOutlinePerson,
} from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Chip, Drawer, Menu, MenuItem, Text } from '@4design/for-ui';
import { useModal } from '@/components/hooks/modal/useModal';
import { HeaderLink } from '@/components/ui-parts/Link';
import { RelanceCommLogo } from '@/components/ui-parts/logo';
import {
  ACCOUNT_PAGE_TITLE,
  COMPANY_EDIT_PAGE_TITLE,
  COMPANY_PAGE_TITLE,
  MESSAGE_PAGE_TITLE,
  PROJECT_DETAIL_PAGE_TITLE,
  PROJECT_EDIT_PAGE_TITLE,
  PROJECT_LIST_PAGE_TITLE,
  PROJECT_NEW_PAGE_TITLE,
  TALENT_DETAIL_PAGE_TITLE,
  TALENT_PAGE_TITLE,
} from '@/constants/pageTitle';
import {
  ACCOUNT_PATH,
  ACCOUNT_SETTING_PATH,
  COMPANY_EDIT_PATH,
  COMPANY_PATH,
  MESSAGE_PATH,
  NEW_PROJECT_PATH,
  PROJECT_DETAIL_PATH,
  PROJECT_EDIT_PATH,
  PROJECT_PATH,
  TALENT_DETAIL_PATH,
  TALENT_PATH,
} from '@/constants/routes';
import { useAccount } from '@/hooks/useAccount';
import { useActivePath } from '@/hooks/useActivePath';
import { authClient } from '@/lib/firebase';

export const links = [
  {
    label: TALENT_PAGE_TITLE,
    to: TALENT_PATH,
    IconComponent: MdOutlinePeople,
    children: [
      {
        label: TALENT_DETAIL_PAGE_TITLE,
        to: TALENT_DETAIL_PATH,
      },
    ],
  },
  {
    label: MESSAGE_PAGE_TITLE,
    to: MESSAGE_PATH,
    IconComponent: MdOutlineMarkunread,
  },
  {
    label: COMPANY_PAGE_TITLE,
    to: COMPANY_PATH,
    IconComponent: MdBusiness,
    children: [
      {
        label: COMPANY_EDIT_PAGE_TITLE,
        to: COMPANY_EDIT_PATH,
      },
    ],
  },
  {
    label: PROJECT_LIST_PAGE_TITLE,
    to: PROJECT_PATH,
    IconComponent: MdInbox,
    children: [
      {
        label: PROJECT_NEW_PAGE_TITLE,
        to: NEW_PROJECT_PATH,
      },
      {
        label: PROJECT_DETAIL_PAGE_TITLE,
        to: PROJECT_DETAIL_PATH,
      },
      {
        label: PROJECT_EDIT_PAGE_TITLE,
        to: PROJECT_EDIT_PATH,
      },
    ],
  },
  {
    label: ACCOUNT_PAGE_TITLE,
    to: ACCOUNT_PATH,
    IconComponent: MdAccountCircle,
  },
];

const headerLinks = links.filter((link) => link.to !== ACCOUNT_PATH);

export const Header = () => {
  const { isActive } = useActivePath();
  const navigate = useNavigate();
  const [isOpen, action] = useModal();
  const { account } = useAccount();

  return (
    <header className="bg-shade-white-default shadow-attractive sticky top-0 z-50 flex w-full flex-row items-center justify-between gap-6 px-4">
      <div className="flex flex-row items-center gap-6">
        <h1 className="flex flex-row items-center p-2">
          <Link to="/" className="flex flex-row gap-2 hover:opacity-60" tabIndex={0}>
            <span role="img" aria-hidden>
              <RelanceCommLogo size={24} />
            </span>
            <Text className="text-shade-medium-default">
              <Text weight="bold">Relance Comm </Text>
              Enterprise
            </Text>
          </Link>
        </h1>
        <nav className="sp:flex hidden flex-row items-start">
          {headerLinks.map((link) => {
            return (
              <HeaderLink
                key={link.to}
                to={link.to}
                isActive={isActive(link.to)}
                label={link.label}
                IconComponent={link.IconComponent}
              />
            );
          })}
        </nav>
      </div>
      <div className="sp:flex hidden flex-row items-center gap-4">
        <Text className="text-shade-medium-default">{account?.me.organization.name}</Text>
        <Menu
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          TriggerComponent={
            <Chip
              label={account?.me.name || 'ログイン中のアカウント'}
              icon={<MdOutlinePerson aria-label="ログイン中のアカウント" />}
              // onClick is passed from Menu's TriggerComponent, so do nothing at here.
              onClick={() => 0}
              aria-haspopup="true"
            />
          }
        >
          <MenuItem onClick={() => navigate(ACCOUNT_SETTING_PATH)} icon={<MdOutlinePerson />}>
            アカウント設定
          </MenuItem>
          <MenuItem onClick={() => navigate(ACCOUNT_PATH)} icon={<MdOutlinePeople />}>
            アカウント一覧
          </MenuItem>
          <MenuItem onClick={() => signOut(authClient())} intention="negative" icon={<MdOutlineLogout />}>
            ログアウト
          </MenuItem>
        </Menu>
      </div>
      <button className="sp:hidden block" onClick={action.open}>
        <MdOutlineMenu size={24} className="icon-primary-dark-default" />
      </button>
      <HeaderDrawer isOpen={isOpen} onClose={action.close} />
    </header>
  );
};

type HeaderDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const HeaderDrawer = ({ isOpen, onClose }: HeaderDrawerProps) => {
  const { isActive } = useActivePath();
  return (
    <Drawer onClose={onClose} open={isOpen} anchor="right">
      <div className="bg-shade-white-default flex h-full w-[300px] flex-col justify-between gap-2.5 px-5 py-10">
        <div className="flex flex-col items-end gap-2.5">
          {[
            ...headerLinks,
            {
              label: 'アカウント設定',
              to: ACCOUNT_SETTING_PATH,
              IconComponent: MdOutlinePerson,
            },
            {
              label: 'アカウント一覧',
              to: ACCOUNT_PATH,
              IconComponent: MdOutlinePeople,
            },
          ].map((link) => {
            return (
              <Link
                key={link.to}
                to={link.to}
                className={clsx([
                  'rounded-2 text-shade-medium-default flex w-full flex-row items-center gap-2 px-2 py-1.5',
                  isActive(link.to)
                    ? 'bg-primary-light-default text-primary-dark-default'
                    : 'icon-shade-medium-default',
                ])}
              >
                <link.IconComponent
                  className={clsx([isActive(link.to) ? 'text-primary-dark-default' : 'text-shade-medium-default'])}
                  size={20}
                />
                <Text
                  size="xr"
                  className={clsx([isActive(link.to) ? 'text-primary-dark-default' : 'text-shade-medium-default'])}
                >
                  {link.label}
                </Text>
              </Link>
            );
          })}
        </div>
      </div>
      <MenuItem onClick={() => signOut(authClient())} intention="negative" icon={<MdOutlineLogout />}>
        ログアウト
      </MenuItem>
    </Drawer>
  );
};
