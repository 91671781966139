import { useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { MessageForm } from '@/features/message/components/detail/message/MessageForm';
import { MessageHeader } from '@/features/message/components/detail/message/MessageHeader';
import { MessageList } from '@/features/message/components/detail/message/MessageList';
import { useMessage } from '@/features/message/repository/useMessage';
import { graphql } from '@/gql';
import { Scout, ScoutMessage } from '@/gql/graphql';

export const Message_Query = graphql(/* GraphQL */ `
  query Message_Query($id: String!) {
    me {
      id
      name
    }

    scout(id: $id) {
      id
      createdAt
      updatedAt

      unreadOrganizationCount

      messages {
        id
        createdAt

        message
        messageType

        organizationUser {
          id
          name
        }

        talent {
          id
          name
        }

        agreement {
          id
          priceHourly
          workingHourPerMonth
          startDate
          initContractEndDate
        }

        scout {
          id

          project {
            id
            name
          }

          talent {
            id
            name

            talentProfile {
              id
              weeklyWorkingDayMin
              weeklyWorkingDayMax
              priceHourlyMax
              priceHourlyMin
              selfPromotion
              engagementIntent
            }
          }
        }
      }

      organization {
        id
        name
      }

      project {
        id
        createdAt
        updatedAt

        name
        status

        details
        requiredSkill
        welcomeSkill
        teamComposition
        targetAgeMax
        targetAgeMin
        startDate
        expectedDuration
        weeklyWorkingDayMin
        weeklyWorkingDayMax
        interviewCount
        priceTaxType
        priceHourlyMax
        priceHourlyMin
        priceMonthlyMax
        priceMonthlyMin
        priceSettlementHourFrom
        priceSettlementHourTo
        paymentDate
      }

      talent {
        id
        name
      }
    }
  }
`);

export const Message = () => {
  const { messageId } = useParams() as {
    messageId: string;
  };
  const actions = useMessage();

  const [result] = useQuery({
    query: Message_Query,
    variables: {
      id: messageId,
    },
  });

  const { data } = result;

  if (!data) {
    throw new Promise((resolve) => resolve(null));
  }

  useLayoutEffect(() => {
    const onRead = async (v: Scout) => {
      await actions.onRead(v.id);
    };

    if (data.scout.unreadOrganizationCount > 0) {
      onRead(data.scout as Scout);
    }
  }, [data, actions]);

  const scout = data.scout;
  return (
    <div className="border-shade-light-default rounded-l-2 flex h-[calc(100vh-56px-32px)] flex-col items-start border shadow-inner">
      <MessageHeader scout={scout as Scout} />

      <MessageList scout={scout as Scout} />

      <div className="flex w-full flex-col">
        <MessageForm messageId={messageId} organizationUserId={data.me.id} />
      </div>
    </div>
  );
};
