import { useForm } from 'react-hook-form';
import z from 'zod';
import { useSearchParamsObject } from '@/hooks/useSearchParamsObject';
import { zodResolver } from '@hookform/resolvers/zod';

// 言語/フレームワーク
const LANGUAGE_FRAMEWORK = {
  label: '言語/フレームワーク',
  name: 'languageFramework',
} as const;

// ミドルウェア
const MIDDLEWARE = {
  label: 'ミドルウェア',
  name: 'middleware',
} as const;

// インフラ
const INFRA = {
  label: 'インフラ',
  name: 'infra',
} as const;

// 監視ツール
const MONITORING_TOOL = {
  label: '監視ツール',
  name: 'monitoringTool',
} as const;

// 構成管理ツール
const CONFIGURATION_MANAGEMENT_TOOL = {
  label: '構成管理ツール',
  name: 'configurationManagementTool',
} as const;

// 経験職種
const EXPERIENCE_JOB_TYPE = {
  label: '経験職種',
  name: 'experienceJobType',
} as const;

// 時給(下限)
const HOURLY_WAGE_LOWER_LIMIT = {
  label: '時給(下限)',
  name: 'hourlyWageLowerLimit',
} as const;

const HOURLY_WAGE_UPPER_LIMIT = {
  label: '時給(上限)',
  name: 'hourlyWageUpperLimit',
} as const;

// 検討中のユーザーのみ
const IS_CONSIDERING = {
  label: '検討中のユーザーのみ',
  name: 'isConsidering',
} as const;

const numberSchema = z
  .number({
    invalid_type_error: '整数の値を入力してください',
  })
  .nullable()
  .refine(
    (val) => {
      if (val === null) return true;
      return Number.isInteger(val);
    },
    {
      message: '整数の値を入力してください',
    },
  );

const optionSchema = z.object({
  label: z.string(),
  inputValue: z.string(),
});

const scheme = z.object({
  [LANGUAGE_FRAMEWORK.name]: z.array(optionSchema),
  [MIDDLEWARE.name]: z.array(optionSchema),
  [INFRA.name]: z.array(optionSchema),
  [MONITORING_TOOL.name]: z.array(optionSchema),
  [CONFIGURATION_MANAGEMENT_TOOL.name]: z.array(optionSchema),
  [EXPERIENCE_JOB_TYPE.name]: z.array(
    z.object({
      label: z.string(),
      inputValue: z.string(),
    }),
  ),
  hourlyWage: z
    .object({
      [HOURLY_WAGE_LOWER_LIMIT.name]: numberSchema,
      [HOURLY_WAGE_UPPER_LIMIT.name]: numberSchema,
    })
    .refine(
      (value) => {
        // 下限が上限より大きい場合はエラー
        if (value[HOURLY_WAGE_LOWER_LIMIT.name] && value[HOURLY_WAGE_UPPER_LIMIT.name]) {
          return Number(value[HOURLY_WAGE_LOWER_LIMIT.name]) <= Number(value[HOURLY_WAGE_UPPER_LIMIT.name]);
        }
        return true;
      },
      {
        message: '時給(下限)は時給(上限)より大きい値を入力してください',
      },
    ),
  [IS_CONSIDERING.name]: z.boolean(),
});

type UserSearchFormValues = z.infer<typeof scheme>;

const defaultValues: UserSearchFormValues = {
  [EXPERIENCE_JOB_TYPE.name]: [],
  [LANGUAGE_FRAMEWORK.name]: [],
  [MIDDLEWARE.name]: [],
  [INFRA.name]: [],
  [MONITORING_TOOL.name]: [],
  [CONFIGURATION_MANAGEMENT_TOOL.name]: [],
  hourlyWage: {
    [HOURLY_WAGE_LOWER_LIMIT.name]: null,
    [HOURLY_WAGE_UPPER_LIMIT.name]: null,
  },
  [IS_CONSIDERING.name]: false,
};

export const useUserSearchForm = () => {
  const { setSearchParamsObject } = useSearchParamsObject();
  const methods = useForm<UserSearchFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: zodResolver(scheme),
    defaultValues,
  });

  const errors = methods.formState.errors;

  console.info({
    errors,
    current: methods.getValues(),
  });

  const onSubmit = (data: UserSearchFormValues) => {
    setSearchParamsObject({
      experienceJobType: data[EXPERIENCE_JOB_TYPE.name].map((item) => item.inputValue).join(',') ?? '',
      languageFramework: data[LANGUAGE_FRAMEWORK.name].map((item) => item.inputValue).join(',') ?? '',
      middleware: data[MIDDLEWARE.name].map((item) => item.inputValue).join(',') ?? '',
      infra: data[INFRA.name].map((item) => item.inputValue).join(',') ?? '',
      monitoringTool: data[MONITORING_TOOL.name].map((item) => item.inputValue).join(',') ?? '',
      configurationManagementTool:
        data[CONFIGURATION_MANAGEMENT_TOOL.name].map((item) => item.inputValue).join(',') ?? '',
      hourlyWageLowerLimit: data.hourlyWage[HOURLY_WAGE_LOWER_LIMIT.name]?.toString() ?? '',
      hourlyWageUpperLimit: data.hourlyWage[HOURLY_WAGE_UPPER_LIMIT.name]?.toString() ?? '',
      isConsidering: data[IS_CONSIDERING.name] ? 'true' : '',
    });
  };

  const isLoading = methods.formState.isSubmitting || methods.formState.isSubmitting;

  return {
    methods,
    onSubmit,
    errors,
    isLoading,
  };
};
