import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Text, TextField, useSnackbar } from '@4design/for-ui';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { HyperLink } from '@/components/ui-parts/Link/HyperLink';
import { Required } from '@/components/ui-parts/required';
import { LOGIN_PATH, SIGN_UP_PATH } from '@/constants/routes';
import {
  FormPasswordResetOrganizationUserInput,
  schema,
} from '@/features/password-reset/repository/password-reset.model';
import { useResetPassword } from '@/features/password-reset/repository/usePasswordReset';
import { zodResolver } from '@hookform/resolvers/zod';

export const PasswordReset = () => {
  const actions = useResetPassword();
  const { openSnackbar } = useSnackbar();

  const methods = useForm<FormPasswordResetOrganizationUserInput>({
    resolver: zodResolver(schema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormPasswordResetOrganizationUserInput) => {
    try {
      await actions
        .onSend(data)
        .then(() => {
          openSnackbar({ message: 'パスワードリセットを送信しました' });
          // navigate('/login');
        })
        .catch((e) => {
          openSnackbar({ message: 'パスワードリセット送信に失敗しました' });
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="min-width[1400px] bg-shade-light-default h-screen w-screen">
      <div className="m-auto flex h-full w-[fit-content] flex-col items-center justify-center">
        <div className="w-140 border-shade-light-default flex min-h-[320px] flex-col justify-center gap-4">
          <Text size="l" weight="bold">
            パスワード再設定
          </Text>

          <HyperLink size="r" underline label="ログインはこちら" to={LOGIN_PATH} />

          <FieldContainer>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <Text>メールアドレス宛にパスワード再設定リンクが送られます。</Text>

              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <Text size="s" weight="bold">
                    メールアドレス
                  </Text>
                  <Required />
                </div>
                <TextField
                  size="medium"
                  type="email"
                  {...register('email')}
                  placeholder="example@example.com"
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                />
              </div>

              <div className="flex w-full justify-end">
                <Button loading={isSubmitting} type="submit" intention="primary" variant="filled">
                  パスワード再発行
                </Button>
              </div>
            </form>
          </FieldContainer>
          <div className="flex items-center justify-center gap-4">
            <HyperLink size="r" underline label="Relance Commについて" to={SIGN_UP_PATH} />
            <HyperLink size="r" underline label="運営会社" to={SIGN_UP_PATH} />
            <HyperLink size="r" underline label="利用規約" to={SIGN_UP_PATH} />
            <HyperLink size="r" underline label="プライバシーポリシー" to={SIGN_UP_PATH} />
          </div>
        </div>
      </div>
    </div>
  );
};
