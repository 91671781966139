import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { ja } from 'date-fns/locale';

// Ref: https://date-fns.org/v2.25.0/docs/format
export const formatDate = (date?: string, ignoreYear = false): string =>
  date ? format(parseISO(date), !ignoreYear ? 'yyyy/MM/dd' : 'MM/dd') : '';

export const formatDateTime = (date: string, ignoreYear = false): string =>
  format(parseISO(date), !ignoreYear ? 'yyyy/MM/dd HH:mm' : 'MM-dd HH:mm');

export const formatDateDistanceToNow = (date: Date): string =>
  formatDistanceToNow(date, { addSuffix: true, locale: ja });
