import { FC, PropsWithChildren, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../../layout/header';

export const Layout: FC<PropsWithChildren> = (props: PropsWithChildren) => {
  return (
    <div className="bg-shade-light-default flex h-screen w-screen flex-col overflow-x-hidden">
      <Suspense>
        <Header />
      </Suspense>

      <div className="flex flex-1 flex-col overflow-hidden">
        <Suspense>{props.children ? props.children : <Outlet />}</Suspense>
      </div>
    </div>
  );
};
