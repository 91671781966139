import { memo } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from 'urql';
import { Select, Switch, TextField } from '@4design/for-ui';
import { ScoutSortKeysOptions, useSearchMessageForm } from '@/features/message/hooks/useSearchMessageForm';
import { ProjectTable_Query } from '@/features/project/components/list/table';

export const SearchMessageForm = memo(() => {
  const { methods, onSubmit } = useSearchMessageForm();
  const [result] = useQuery({
    query: ProjectTable_Query,
    variables: {
      filter: {},
      limit: 50,
      offset: 0,
    },
  });

  const projects = result.data?.projects.nodes ?? [];

  const projectOption = projects.map((item) => {
    return {
      label: item.name,
      inputValue: item.id,
    };
  });

  const emptyOption = {
    label: '未選択',
    inputValue: '',
  };

  return (
    <form className="flex w-full flex-col gap-2 px-4 py-2" onSubmit={methods.handleSubmit(onSubmit)}>
      <div className="flex flex-row gap-2">
        <TextField size="medium" className="w-full" type="search" placeholder="検索" {...methods.register('search')} />
        <Controller
          name="project"
          control={methods.control}
          render={({ field: { onChange, name, ref: _, ...fields } }) => {
            return (
              <Select
                {...fields}
                disableFilter
                name={name}
                placeholder="案件を選択"
                options={[emptyOption, ...projectOption]}
                size="medium"
                onChange={(_, value) => {
                  onChange(value);
                }}
              />
            );
          }}
        />
      </div>

      <div className="flex flex-row flex-wrap justify-between">
        <div className="flex flex-row gap-2">
          <Switch label="未読のみ表示" {...methods.register('isUnRead')} />
          <Switch label="未返信のみ表示" {...methods.register('isUnReply')} />
        </div>
        <div className="flex flex-row items-center">
          <Controller
            name="sort"
            control={methods.control}
            render={({ field: { onChange, name, ref: _, ...fields } }) => {
              return (
                <Select
                  {...fields}
                  name={name}
                  placeholder="未選択"
                  className="w-32"
                  size="medium"
                  options={ScoutSortKeysOptions}
                  onChange={(_, value) => {
                    onChange(value);
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    </form>
  );
});
